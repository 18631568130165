import React, { useMemo, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import config from 'config';
import logoWhite from "assets/img/layout/parstaksilogo.png";
// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  Button,
  Box,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Textarea,
  InputGroup,
  InputLeftElement,
  createStandaloneToast
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdOutlineSettings,
  MdPhone,
  MdEmail,
  MdLock,
  MdStore,
  MdLocalTaxi
} from "react-icons/md";
import ReactDOMServer from 'react-dom/server';
import { CitiesLocations } from "variables/CityLocations";
import { FaUserPlus } from "react-icons/fa";
import { CountryandCities } from "../variables/CityData";
import { countries } from "variables/Country";
import UserService from "Services/UserService/UserService";
import CityAdminService from "Services/CityAdminService/CityAdminService";
import { useTranslation } from 'react-i18next';
const userService = new UserService();
const cityAdminService = new CityAdminService();
const handleClickToast = (position, status, message) => {
  const toast = createStandaloneToast();
  toast({
    description: message,
    status: status,
    duration: 3000,
    position: position,
    isClosable: true,
  });
};

function turkishToEnglish(str) {
  const turkishChars = "çğıöşüÇĞİÖŞÜ";
  const englishChars = "cgiosuCGIOSU";

  return str
    .replace(/./g, function (char) {
      const index = turkishChars.indexOf(char);
      return index > -1 ? englishChars.charAt(index) : char;
    })
    .replace(/\s/g, "");
}
export default function AddUserButton({ type, param, adress, deviceID, tripID }) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [userNameGenerated, setUserNames] = useState('');
  const [password, setPassword] = useState('');
  const [passwordReTyped, setPasswordReTyped] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('90');
  const [addressed, setAddress] = useState('');
  const [StationRegister, setStationRegister] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const [positions, setPositions] = useState([]);
  const [StationName, setStationName] = useState(null);
  var logoUrl = `<img src="${logoWhite}"/>`
  var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
  const cityName = CitiesLocations[cityIndex];
  const handleCityChange = (event) => {
    const selectedOption = CountryandCities.find(option => option.value === event.target.value);
    console.log(selectedOption.value)
    setSelectedCountry(selectedOption);
    var cityCode = CitiesLocations.filter(f => f.CityName == selectedOption.value)[0].plaka;
    setCountry(cityCode);
    setCity(null); // İl değiştiğinde ilçeyi sıfırla
  };
  const { t, i18n } = useTranslation();
  const handleRegister = async () => {
    // Giriş işlemi için API'ye istek gönder
    if(StationRegister && StationName == null){
      handleClickToast("top-right", "error", "Lütfen tüm bilgileri eksiksiz doldurunuz!");
      return;
    }
     var model = JSON.stringify(
        {
          "UserName": userNameGenerated,
          "Name": name,
          "Surname": surname,
          "Phone": phone,
          "Email": email,
          "Password": password,
          "Role": role,
          "PasswordRetyped": passwordReTyped,
          "CityCode": country,
          "District": city,
          "CountryCode": countryCode,
          "Address": addressed
        }
  
      );
      const data = await cityAdminService.AddUserToSystem(model);



    if (data.IsSuccess) {
      if (StationRegister) {
        var StationModel = JSON.stringify(
          {
            "StationName": StationName,
            "District": city,
            "Address": addressed,
            "CityCode": country,
            "Coordinates":positions.length > 0 ? [positions[0].lat, positions[0].lng] : null,
            "Phone": phone,
            "UserAccountId": data.Data.UserId
          }

        );
        const stationData = await cityAdminService.CreateStationToSystem(StationModel);
      }

      handleClickToast("top-right", "success", 'Kullanıcı kaydı başarılı')
      setEmail('');
      setUserNames('');
      setPassword('');
      setPasswordReTyped('');
      setPhone('');
      setName('');
      setCountry('');
      setAddress('');
      setSelectedCountry('');
    } else {
      data.ValidationErrors.forEach(item => {
        handleClickToast("top-right", "error", item)
      });

    }
  };
  const handleDistrictChange = (event) => {
    const selectedOption = event.target.value;
    setCity(selectedOption);
  };

  const handleClick = async () => {
    setStationRegister(false)
    onOpen();
  };
  const handleRoleSelect = async (e) => {
    setStationRegister(e == 'Station' ? true : false)
    setRole(e)
  };
  const MapClickHandler = () => {

    useMapEvents({

      click(e) {
        setPositions([]);
        const { lat, lng } = e.latlng;
        setPositions((prevPositions) => [...prevPositions, { lat, lng }]);
        var x = positions;
        var y = 0;
      },
    });
    return null;
  };
  const StationIcon = () => {

    return L.divIcon({
      html: ReactDOMServer.renderToString(
        <Flex textAlign={'center'} color={'white'} alignItems={'center'} opacity={'0.9'} >
          <Icon bg={'#0181cB'} p={'5px'} borderRadius={'50% '} fontSize={'3em'} as={MdStore} pos={'relative'} top={'-30px'} left={'-10px'} />
          <Flex bg={'#0181cB'} w={'20px'} h={'20px'} transform={'rotate(45deg)'} pos={'absolute'} left={'-2px'} bottom={'4px'}></Flex>
        </Flex>

      ),
      className: 'marker-icon marker-transition',
    });
  }
  return (
    <>

      <Button colorScheme="teal" align='center' onClick={handleClick}>
        <Icon as={FaUserPlus} h='16px' w='16px' me='8px' />
        <Text fontSize='sm' fontWeight='400'>
        {t('adduser')}
        </Text>
      </Button>
      <Modal size={StationRegister ? '6xl' : 'xl'} isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'}
      >
        <ModalOverlay bg='none'
          backdropFilter='blur(10px) ' />
        <ModalContent backgroundColor={'rgba(255,255,255,0.95)'} p={'20px 0'}>


          <ModalBody>
            <Flex columnGap={'10px'}>
              <FormControl>
                <Text fontSize={'lg'} m={'0px 5px 10px'} fontWeight={'600'}>Yeni Kullanıcı Oluştur</Text>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                >
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    width={{ base: "100%", md: "49%" }}
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Adı'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    width={{ base: "100%", md: "49%" }}
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Soyadı'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </Flex>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                >
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    width={{ base: "100%", md: "49%" }}
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Kullanıcı Adı'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={userNameGenerated}
                    onChange={(e) => setUserNames(e.target.value)}
                  />
                  <Select
                    id='country'

                    fontSize='sm'
                    width={{ base: "100%", md: "49%" }}
                    placeholder='Kullanıcı Rolü Seçiniz'
                    mb='24px'
                    size='lg'
                    variant='auth'
                    isReadOnly={true}
                    onChange={(e) => handleRoleSelect(e.target.value)}
                  >

                    <option value='TaxiOwner' >- Standart Kullanıcı</option>
                    <option value='Station' >- Durak Kullanıcısı</option>
                  </Select>
                </Flex>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                >
                  <InputGroup
                    width={{ base: "100%", md: "44%" }}
                  >
                    <InputLeftElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={MdPhone}
                      />
                    </InputLeftElement>
                    <Input
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='Telefon'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup
                    width={{ base: "100%", md: "54%" }}
                  >
                    <InputLeftElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={MdEmail}
                      />
                    </InputLeftElement>
                    <Input
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'

                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='Eposta'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>

                </Flex>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                >

                  <InputGroup
                    width={{ base: "100%", md: "49%" }}
                  >
                    <Select
                      id='country'
                      isRequired={true}
                      fontSize='sm'

                      placeholder='Şehir Seçiniz'
                      mb='24px'
                      size='lg'
                      variant='auth'

                      onChange={handleCityChange}
                    >
                      {CountryandCities.map(option => (
                        <option key={option.value} value={option.value} selected={option.value == 'İzmir' ? true : false}>{option.label}</option>
                      ))}
                    </Select>
                  </InputGroup>
                  <InputGroup
                    width={{ base: "100%", md: "49%" }}
                  >

                    <Select
                      id='city'
                      isRequired={true}
                      fontSize='sm'
                      placeholder='İlçe Seçiniz'
                      mb='24px'
                      size='lg'
                      variant='auth'

                      onChange={handleDistrictChange}
                    >
                      {selectedCountry && selectedCountry.districts.map(district => (
                        <option key={district} value={district}>{district}</option>
                      ))}
                    </Select>
                  </InputGroup>
                  <InputGroup
                    width={"100%"}

                  >
                    <Textarea
                      placeholder='Adres'
                      size='sm'
                      borderRadius={'16px'}
                      className="chakra-input css-rpu85t"
                      resize={'none'}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </InputGroup>
                </Flex>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                >
                  <InputGroup
                    width={{ base: "100%", md: "49%" }}
                  >
                    <InputLeftElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={MdLock}
                      />
                    </InputLeftElement>
                    <Input
                      isRequired={true}
                      fontSize='sm'
                      placeholder='Şifre'
                      mb='24px'
                      size='lg'
                      type={"password"}
                      variant='auth'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup
                    width={{ base: "100%", md: "49%" }}
                  >
                    <InputLeftElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={MdLock}
                      />
                    </InputLeftElement>
                    <Input
                      isRequired={true}
                      fontSize='sm'
                      placeholder='Şifre Tekrar'
                      mb='24px'
                      size='lg'
                      type={"password"}
                      variant='auth'
                      value={passwordReTyped}
                      onChange={(e) => setPasswordReTyped(e.target.value)}
                    />
                  </InputGroup>
                </Flex>



              </FormControl>
              {StationRegister && <FormControl>
                <Text fontSize={'lg'} m={'0px 5px 10px'} fontWeight={'600'}>Durak Bilgileri</Text>
                <InputGroup
                  width={{ base: "100%", md: "49%" }}
                >
                  <InputLeftElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={MdStore}
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Durak Adı'
                    mb='24px'
                    size='lg'
                    type="text"
                    variant='auth'
                    value={StationName}
                    onChange={(e) => setStationName(e.target.value)}
                  />
                </InputGroup>
                <Flex>
                  <MapContainer borderRadius='0.8rem' center={[cityName.lat, cityName.lon]} zoom={13} style={{ height: "350px", width: "100%" }}>
                    <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    <MapClickHandler />
                    {positions.map((position, index) => (
                      <Marker
                        key={index}
                        position={[position.lat, position.lng]}
                        icon={StationIcon()}
                      />
                    ))}
                  </MapContainer>
                </Flex>
              </FormControl>}
            </Flex>


          </ModalBody>
          <ModalFooter columnGap={'10px'}>
            <Button
              colorScheme="teal"
              onClick={handleRegister}
            >
              Kullanıcı Oluştur
            </Button>
            <Button colorScheme="orange" onClick={onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
