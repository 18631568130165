import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, useMap, FeatureGroup, Polyline, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import DrawerSidebar from 'components/drawersidebar/DrawerSidebar';
import TripsSidebar from 'components/drawersidebar/TripsSidebar';
import L from 'leaflet';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import {
  MdOutlinePauseCircle,
  MdNotStarted,
  MdOutlineLocalTaxi,
  MdReplay,
  MdFlag,
  MdLocationOn ,
  MdPlayCircleOutline
} from "react-icons/md";
import 'leaflet-draw';
import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Icon,
  Divider,
  Input,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner
} from "@chakra-ui/react";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import { CitiesLocations } from "variables/CityLocations";
const { BaseLayer } = LayersControl;
const MAX_RADIUS = 200;
const taxiDeviceService = new TaxiDeviceService();
function ChildComponent({ zoomLevel, centerCoordinates }) {
  const map = useMap(); // Access the map instance using useMap()
  console.log(zoomLevel, " ", centerCoordinates);
  // You can now use the map instance to perform operations
  // For example, to set the view to a specific location
  map.setView(centerCoordinates, zoomLevel);

  return null;
}
const DrawControl = ({ drawEnabled, featureGroupRef, onDrawCreated, type }) => {
  const map = useMap();

  useEffect(() => {
    const drawControl = new L.Control.Draw({
      position: 'topright',
      className: 'testcss',
      draw: {

        polygon: false,
        circle: {
          shapeOptions: {
            color: type ? 'teal' : 'red',
            weight: 4,
            maxRadius: 10,


          },
          metric: true,
        },
        rectangle: false,
        marker: false,
        polyline: false,
        circlemarker: false
      },
      edit: {
        featureGroup: featureGroupRef.current,
        edit: false,
        remove: false,
      },
    });

    if (drawEnabled) {
      map.addControl(drawControl);
      map.addLayer(featureGroupRef.current);

    } else {
      map.removeControl(drawControl);

    }

    map.on(L.Draw.Event.CREATED, onDrawCreated);

    return () => {
      map.off(L.Draw.Event.CREATED, onDrawCreated);
      if (drawEnabled) {
        map.removeControl(drawControl);
      }

    };
  }, [map, drawEnabled, onDrawCreated, featureGroupRef]);

  return null;
};

const MapComponent = () => {
  const [startDrawEnabled, setStartDrawEnabled] = useState(false);
  const [endDrawEnabled, setEndDrawEnabled] = useState(false);
  const [startCenter, setStartCenter] = useState(null);
  const [startRadius, setStartRadius] = useState(null);
  const [endCenter, setEndCenter] = useState(null);
  const [endRadius, setEndRadius] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [startDate, setStartDate] = useState(new Date);
  const [endDate, setEndDate] = useState(null);
  const [tripData, setTripData] = useState(null);
  const [onOpens, setOnOpens] = useState(false);
  const [searching, setSearching] = useState(false);
  const [tripLocations, setTripLocations] = useState(null);
  const [route, setRoute] = useState([]);
  const [centerPoint, setCenterPoint] = useState([]);
  const startFeatureGroupRef = useRef(new L.FeatureGroup());
  const endFeatureGroupRef = useRef(new L.FeatureGroup());
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const fetchDataAndSetState = async () => {
    setTripData(null);
    setOnOpens(false);
    setSearching(true)
    var model = {
      "RentedTripFareGTE": minPrice != "" && minPrice != null ? parseInt(minPrice * 1000) : null,
      "RentedTripFareLTE": maxPrice != "" && maxPrice != null ? parseInt(maxPrice * 1000) : null,
      "StartLocation": startCenter,
      "EndLocation": endCenter,
      "StartLocationRadius": startRadius,
      "EndLocationRadius": endRadius,
      "TripStartTime": startDate,
      "TripEndTime": endDate
    }
    var result = await taxiDeviceService.TestForSearchTrip(JSON.stringify(model));

    setTripData(result.Data);
    setOnOpens(true);
    setSearching(false)
  }
  const markerIcon = L.divIcon({
    html: ReactDOMServer.renderToString(
      <Box bg={'rgba(34,139,34, 0.3)'} borderRadius={'50%'} p={'10px'} color={'white'} ></Box>
    ),
    className: 'marker-icon',
  });
  const startIcon = L.divIcon({
    html: ReactDOMServer.renderToString(
      <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdNotStarted} />
    ),
    className: 'marker-icon',
  });
  const endIcon = L.divIcon({
    html: ReactDOMServer.renderToString(
      <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdFlag} />
    ),
    className: 'marker-icon',
  });
  const placeIcon = L.divIcon({
    html: ReactDOMServer.renderToString(
      <Icon fontSize={'3em'}  borderRadius={'50%'} p={'4px'} color={'red'} as={MdLocationOn } />
    ),
    className: 'marker-icon',
  });
  var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
  const city = CitiesLocations[cityIndex];

  const toggleStartDraw = () => {
    if (startDrawEnabled) {
      setStartDrawEnabled(false);
    } else {
      startFeatureGroupRef.current.clearLayers();
      setStartCenter(null);
      setStartRadius(null);
      setStartDrawEnabled(true);
    }
  };
  const GetTripLocations = async (e) => {
    if(e == null){
      setRoute([]); // Adresleri state'e güncelle
      setCenterPoint([])
      setTripLocations(null)
      return;
    }
    var result = await taxiDeviceService.GetTripsLocations(e.deviceID, e.tripID);
    var locs = [];
    if (result.Locations) {
      result.Locations.map(trip =>
        locs.push([trip.Latitude, trip.Longitude, trip.Speed])


      );
    }
    setRoute(locs); // Adresleri state'e güncelle
    setCenterPoint(locs[Math.round(locs.length / 2)])
    setTripLocations(result.Locations)

  };

  const toggleEndDraw = () => {
    if (endDrawEnabled) {

      setEndDrawEnabled(false);
    } else {
      endFeatureGroupRef.current.clearLayers();
      setEndCenter(null);
      setEndRadius(null);
      setEndDrawEnabled(true);
    }
  };

  const onDrawCreatedStart = (e) => {
    const { layerType, layer } = e;
    if (layerType === 'circle') {
      const center = layer.getLatLng();
      const radius = layer.getRadius();
      // if (radius > MAX_RADIUS) {
      //   startFeatureGroupRef.current.clearLayers();
      //   endFeatureGroupRef.current.clearLayers();
      //   alert('Maksimum yarıçap 200 metredir!');
      //   return;
      // }
      const geoObject = {
        "type": "point",
        "coordinates": [
          center.lng, center.lat
        ]
      }
      if (startDrawEnabled) {
        setStartCenter(geoObject);
        setStartRadius(Math.round(radius));

        // Add the layer to the startFeatureGroup
        startFeatureGroupRef.current.addLayer(layer);
        setStartDrawEnabled(false);
      }
      else if (endDrawEnabled) {
        setEndCenter(geoObject);
        setEndRadius(Math.round(radius));

        // Add the layer to the endFeatureGroup
        endFeatureGroupRef.current.addLayer(layer);
        setEndDrawEnabled(false);
      }

    }
  };

  const onDrawCreatedEnd = (e) => {
    const { layerType, layer } = e;
    if (layerType === 'circle') {
      const center = layer.getLatLng();
      const radius = layer.getRadius();
      setEndCenter(center);
      setEndRadius(radius);

      // Add the layer to the endFeatureGroup
      endFeatureGroupRef.current.addLayer(layer);
      setEndDrawEnabled(false);
    }
  };
  const [searchLocation, setSearchLocation] = useState(null); // Aranan konumu saklamak için state
  const [searchText, setSearchText] = useState('');
  const [viewportPoint, setViewportPoint] = useState('');
  const handleViewportChanged = (viewport) => {
    const bounds = viewport.target.getBounds();
    console.log('Görünüm sınırları:', bounds);

    // Sınırlar içinde yer adına göre arama yap
    setViewportPoint(bounds);
  };
  const searchByAddress = async () => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${searchText}&viewbox=${viewportPoint.getWest()},${viewportPoint.getSouth()},${viewportPoint.getEast()},${viewportPoint.getNorth()}`
      );

      if (response.data && response.data.length > 0) {
        const datas = response.data;
        setSearchLocation(datas);
      } else {
        console.log('Arama sonucu bulunamadı');
      }
    } catch (error) {
      console.error('Arama hatası:', error);
    }
  };
  const footerText = useRef(null);

  const [widthText, setwidthText] = useState('');
  useEffect(() => {
    if (footerText.current) {
      setwidthText(footerText.current.offsetWidth / 2) ;
  
    }
  }, []);
  return (
    <Box zIndex={0} overflow={'hidden'}>
      {/* <Flex pos={'absolute'} zIndex={2} left={'calc(50% - 200px)'} w={'400px'} bg={'white'} p={'10px 20px'} borderRadius={'0.825rem'}>
        <form onSubmit={searchByAddress} style={{width:'100%'}}>
          <Input
          w={'100%'}
            type="text"
            placeholder="Lokasyon ara (örn: Kardeşler Pastanesi)"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button colorScheme='teal' type="submit">Ara</Button>
        </form>
      </Flex> */}

      <TripsSidebar
        tripData={tripData}
        onOpens={onOpens}
        showOnMap={(e) => GetTripLocations(e)}
      />
      <DrawerSidebar
        onclickEndButton={toggleEndDraw}
        onclickStartButton={toggleStartDraw}
        startStatus={startDrawEnabled}
        endStatus={endDrawEnabled}
        startValue={startCenter}
        endValue={endCenter}
        startDate={setStartDate}
        endDate={setEndDate}
        minPrice={setMinPrice}
        maxPrice={setMaxPrice}
        btnSearch={fetchDataAndSetState}
        searching={searching}
      />
      <MapContainer
        center={[city.lat, city.lon]}
        zoom={13}
        style={{ height: '100vh', width: '100%', zIndex: 0 }}
        zoomControl={false}
        onViewportChanged={handleViewportChanged}
      >
        <LayersControl position="topright">
          <BaseLayer checked name="Google Haritalar">
            <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
          </BaseLayer>
          <BaseLayer name="Google Haritalar (Uydu)">
            <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
          </BaseLayer>
        </LayersControl>
        <FeatureGroup ref={startFeatureGroupRef} />
        <FeatureGroup ref={endFeatureGroupRef} />
        <DrawControl
          drawEnabled={startDrawEnabled}
          featureGroupRef={startFeatureGroupRef}
          onDrawCreated={(e) => onDrawCreatedStart(e)}
          type={startDrawEnabled}

        />
        <DrawControl
          drawEnabled={endDrawEnabled}
          featureGroupRef={endFeatureGroupRef}
          onDrawCreated={(e) => onDrawCreatedEnd(e)}
        />
        <FullscreenControl position="bottomright" />

        {tripLocations != null && tripLocations.length > 0 ? <>
          {tripLocations.map((location, index) => (

            <Marker key={index} position={[location.Latitude, location.Longitude]} icon={
              index == 0 ? startIcon
                : index == tripLocations.length - 1 ? endIcon
                  : markerIcon
            }>
              <Popup >
                <Flex flexDirection={'column'}>
                  <Text as={'h5'} margin={'4px 0'}><b>Cihaz Seri No: </b> {location.DeviceID}</Text>
                  <Text as={'h5'} m={'4px 0'}><b>Tarih Saat: </b> {moment(location.TimeReal).format('DD.MM.YYYY - HH:mm')}</Text>
                  <Text as={'h5'}><b>Araç Hızı: </b> {Math.round(location.Speed * 1.60934)} Km/h</Text>


                </Flex>

              </Popup>
            </Marker>
          ))}
          <ChildComponent  centerCoordinates={centerPoint} />
          <Polyline positions={route} color="blue" />
        </> : ''}
      </MapContainer>

      <Text id='footerText' 
      ref={footerText} 
      pos={'absolute'} 
      bottom={0} 
      zIndex={1} 
      right={'calc(50% - ' + widthText + 'px)'} 
      color={'rgba(0,0,0,0.5)'}>© 2024 Made by ParsArGe. All rights reserved.</Text>
    </Box>
  );
};

export default MapComponent;
