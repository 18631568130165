import {
    Flex,
    Table,
    Progress,
    Grid,
    Button,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Input,
    Select,
    Divider,
    FormControl,
    Thead,
    Tr,
    FormLabel,
    InputGroup,
    Checkbox, CheckboxGroup,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import moment from 'moment';
import 'moment/locale/tr';
import { useTranslation } from 'react-i18next';
import UserService from "Services/UserService/UserService";
import { CountryandCities } from "../variables/CityData";
import { countries } from "variables/Country";
// Custom components
import Card from "components/card/Card";
import { CitiesLocations } from "variables/CityLocations";
import Roles from "variables/Roles";
import Permissions from "variables/Permissions";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdEdit, MdFormatListBulleted, MdOutlineInfo, MdOutlineLocalTaxi } from "react-icons/md";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
export default function TaxiAssignForm({selectedUser, edit, onAddDevices, onRemoveDevices, onForce }) {
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const { t, i18n } = useTranslation();
  const [unassignedDevices, setUnassignedDevices] = useState([]);
  const [userDeviceList, setUserDeviceList] = useState([]);
  const [newUserDevices, setNewUserDeviceList] = useState([]);
  const [removeUserDevices, setRemoveUserDeviceList] = useState([]);
  const [showingdevicesTypes, setShowingDevicesType] = useState(false);
  useEffect(() => {
    GetCityUnAssignedDevices();

}, [showingdevicesTypes]);
const GetCityUnAssignedDevices = async (plate) => {
  var resultUnassign = !showingdevicesTypes ? await taxiDeviceService.GetUnAssignedDevices(selectedUser.CityCode) : await taxiDeviceService.GetCityDeviceLocation(userData.CityCode);
  var resultUserDevices = await taxiDeviceService.GetListUserDevices(selectedUser.UserId);


  setUserDeviceList(resultUserDevices)
  if (plate != null && plate != '') {
    var value = plate.toUpperCase()
    var result = !showingdevicesTypes ? resultUnassign.filter(f => f.Plate.includes(value) ): resultUnassign.Taximeters.filter(f => f.Plate.includes(value) );
    setUnassignedDevices(result);
  }
  else{
    setUnassignedDevices(!showingdevicesTypes ? resultUnassign : resultUnassign.Taximeters)
  }

};
useEffect(() => {
  const handleFormValues = () => {
  var news = [];
  var removes =[];
  var rr = showingdevicesTypes
  newUserDevices.map(o => 
    news.push(o.DeviceId)
  )
  removeUserDevices.map(o => 
    removes.push(o.DeviceId)
  )
  onForce(rr)
  onAddDevices(news);
  onRemoveDevices(removes);
  };
  handleFormValues();
}, [newUserDevices, removeUserDevices,showingdevicesTypes]);
const addDeviceToUser = (model) =>{
  setNewUserDeviceList((prevUserDeviceList) => [
    ...prevUserDeviceList,
    model,
  ]);
  setUserDeviceList((prevUserDeviceList) => [
    ...prevUserDeviceList,
    model,
  ]);
  setUnassignedDevices((prevUserDeviceList) => 
    prevUserDeviceList.filter(item => item.DeviceId !== model.DeviceId)
  );
}
const removeDeviceToUser = (model) =>{
  setUnassignedDevices((prevUserDeviceList) => [
    ...prevUserDeviceList,
    model,
  ]);
  setNewUserDeviceList((prevUserDeviceList) => 
    prevUserDeviceList.filter(item => item.DeviceId !== model.DeviceId)
  );
  setUserDeviceList((prevUserDeviceList) => 
    prevUserDeviceList.filter(item => item.DeviceId !== model.DeviceId)
  );
  if(userDeviceList.includes(model)){
    setRemoveUserDeviceList((prevUserDeviceList) => [
      ...prevUserDeviceList,
      model,
    ]);
  }
}

    return (
        <>
            {selectedUser ? 
            <Flex columnGap={'20px'} flexWrap={'wrap'}>
           
            <Card  w={{base:'100%', md:'calc(50% - 10px)'}}>
              <Text as={'b'} fontSize={'md'}>{t('canassigndevices')} ({unassignedDevices.length})</Text>
              <Divider my={'5px'} />
              <Flex columnGap={'10px'} rowGap={'10px'} flexWrap={'wrap'}>
                            <Input w={{base:'100%', md:'calc(50% - 5px)'}}  size="md" placeholder={t('searchbyplate')} onInput={(e)=> GetCityUnAssignedDevices(e.target.value)} />
                            <Button onClick={() => setShowingDevicesType(!showingdevicesTypes)} w={{base:'100%', md:'calc(50% - 5px)'}} colorScheme={showingdevicesTypes?'linkedin':'teal'}>{t(showingdevicesTypes?'seealldevices':'unassigndevices')}</Button>
             {showingdevicesTypes&& <Text fontSize={'xs'} color={'crimson'}>{t('assigndevicemodetext')}</Text>}
              </Flex>
  
              <Flex marginTop={'6px'} flexDirection={'column'} columnGap={'10px'} rowGap={'10px'} maxH={'50vh'} overflow={'auto'} p={'6px'}>
          
                {unassignedDevices.length >0 ? unassignedDevices.map(o => 
                      <Flex p={'4px 10px'} border={'2px solid lightgray'} w={'100%'} 
                      alignItems={'center'} 
                      columnGap={'10px'} 
                      flexWrap={'wrap'}
                      color={'facebook.500'} 
                      borderRadius={'0.8rem'} 
                      justifyContent={'start'}>
                        <Icon  w={'8%'} h={6} margin={'10px 2px'} as={MdOutlineLocalTaxi} />
                        <Grid w={{base:'55%',md:'60%'}}>
                               <Flex fontSize='md' fontWeight={'600'} m={'0'} letterSpacing={'1px'}><Text as={'span'} display={{base:'none', md:'block'}}>{t('plate')}: </Text> {o.Plate} </Flex> 
                               <Flex fontSize={'xs'} fontWeight={'500'} m={'0'} letterSpacing={'1px'}><Text as={'span'} display={{base:'none', md:'block'}}>{t('serialno')}: </Text> {o.DeviceId} </Flex> 
                        </Grid>
                   
                        <Button fontSize={{base:'xs', md:'md'}} onClick={() => addDeviceToUser(o)} w={'20%'} colorScheme="facebook" disabled={edit}>{t('add')}</Button>
                        </Flex>
                ) :       <Text fontSize={'md'} fontWeight={'500'} m={'0'} letterSpacing={'1px'} color={'lightgray'}> {t('nounassigndevicefound')}  </Text> }
              
              </Flex>
            </Card>
            <Card  w={{base:'100%', md:'calc(50% - 10px)'}}>
              <Text as={'b'} fontSize={'md'}>{t('assinedtaximeters')} ({userDeviceList.length})</Text>
              <Divider my={'5px'} />
              <Flex marginTop={'6px'} flexDirection={'column'} columnGap={'10px'} rowGap={'10px'} maxH={'50vh'} overflow={'auto'} p={'6px'}>
              {userDeviceList.length > 0  ? userDeviceList.map(o => 
                    <Flex p={'4px 10px'} border={'2px solid lightgray'} w={'100%'} 
                    alignItems={'center'} 
                    columnGap={'10px'} 
                    flexWrap={'wrap'}
                    color={'teal.500'} 
                    borderRadius={'0.8rem'} 
                    justifyContent={'space-around'}>
                         <Icon  w={'8%'} h={6} margin={'10px 2px'} as={MdOutlineLocalTaxi} />
                        <Grid w={{base:'55%',md:'60%'}}>
                        <Flex fontSize='md' fontWeight={'600'} m={'0'} letterSpacing={'1px'}><Text as={'span'} display={{base:'none', md:'block'}}>{t('plate')}: </Text> {o.Plate} </Flex> 
                               <Flex fontSize={'xs'} fontWeight={'500'} m={'0'} letterSpacing={'1px'}><Text as={'span'} display={{base:'none', md:'block'}}>{t('serialno')}: </Text> {o.DeviceId} </Flex> 
                        </Grid>
                          
                               <Button w={'20%'} fontSize={{base:'xs', md:'md'}} onClick={() => (removeDeviceToUser(o))} colorScheme="red" disabled={edit}>{t('remove')}</Button>
                               </Flex>
                ) :        <Text fontSize={'md'} fontWeight={'500'} m={'0'} letterSpacing={'1px'} color={'lightgray'}> {t('nouserdevicefound')}</Text>}
              </Flex>
            </Card>
          </Flex>
            : ''}
        </>)


}