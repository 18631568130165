import React, { Component } from 'react';
import axios from 'axios';
import config from 'config';
class ThirdPartyAPIService {
    constructor() {

    }

    async GetCalibrationEcho(deviceId) {
        const token = JSON.parse(localStorage.getItem('authToken')).Token;
        const response = await fetch(`${config.baseURL + config.endpoints.CalibrationEcho}?deviceId=${deviceId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        if (response.ok ) {
            return data;
        } else {
            return data;

        }
    }

}

export default ThirdPartyAPIService;
