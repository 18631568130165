import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import $ from 'jquery';
import 'select2';
import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Icon,
  Spinner
} from "@chakra-ui/react";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
function CustomSelect({ filterValue, value, onChange, placeholder }) {
  const [deviceLocation, setDeviceLocations] = useState([]);

  const selectRef = useRef(null);

  useEffect(() => {
    $(selectRef.current).select2();
    $(selectRef.current).on('change', (e) => {
      fetchDeviceLocations();
      if (deviceLocation.length > 0 && e.target.value != "") {
   
        var result = deviceLocation.filter(f => f.DeviceId == e.target.value);
        onChange([result[0].LastLocation.Latitude, result[0].LastLocation.Longitude]);
      }

    });
    return () => {
      $(selectRef.current).select2('destroy');
      $(selectRef.current).off('change');
    };
  }, []);
  useEffect(() => {
    fetchDeviceLocations(); // API'den cihaz konumlarını al
    // API'den cihaz konumlarını belirli aralıklarla al
  }, [filterValue]);
  const fetchDeviceLocations = async () => {
    var result = await taxiDeviceService.GetCityDeviceLocation("06");
    console.log(filterValue);
    if (filterValue != '') {
      await setDeviceLocations(result.Taximeters.filter(f => f.Status.Status == filterValue));

    } else {
      await setDeviceLocations(result.Taximeters);

    }

  };
  useEffect(() => {
    $(selectRef.current).val(value).trigger('change');
  }, [value]);
  return (

    <Flex width={'200px'} className='leaflet-top leaflet-left' >
      <Flex width={'200px'} className="leaflet-control leaflet-bar">
        <select ref={selectRef} placeholder={placeholder} style={{ width: '200px', fontSize: '1.2em' }} >
          {placeholder && <option value="">{placeholder}</option>}
          {deviceLocation.map(option => (
            <option value={option.DeviceId}>
              {option.Plate}
            </option>
          ))}
        </select>

      </Flex>
    </Flex>




  )
}

export default CustomSelect;
