import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import ReactDOMServer from 'react-dom/server';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { Text, useColorModeValue, SimpleGrid, Button, Flex, Icon, Box, Spinner } from "@chakra-ui/react";
import {
    MdOutlinePauseCircle ,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdReplay ,
    MdFlag,
    MdPlayCircleOutline 
} from "react-icons/md";

const { BaseLayer } = LayersControl;
function ChildComponent({ zoomLevel, centerCoordinates }) {
    const map = useMap(); // Access the map instance using useMap()
    console.log(zoomLevel, " ", centerCoordinates);
    // You can now use the map instance to perform operations
    // For example, to set the view to a specific location
    map.setView(centerCoordinates, zoomLevel);

    return null;
}

function MapModal({ zoomLevel, centerCoordinates, logoUrl, locations, timeSpan, deviceID, tripID }) {

    const [route, setRoute] = useState([]); // Rota: [[latitude, longitude, speed], ...]
    const [carPositionIndex, setCarPositionIndex] = useState(0); // Arabanın bulunduğu konumun indeksi
    const [animationEnabled, setAnimationEnabled] = useState(false); // Animasyon durumu
    const [carPosition, setCarPosition] = useState([0,0]);
    useEffect(() => {
        if(locations.Locations){
        const createTripMap = async () => {
            var locs = [];
            const locObject = locations.Locations.map(trip => 
   locs.push([trip.Latitude, trip.Longitude, trip.Speed])
            );

            setRoute(locs); // Adresleri state'e güncelle
        };

        createTripMap();
        }
        // Array içindeki her bir öğe için reverse geocoding yap

    }, [locations]);
    var middleLoc = route[Math.round(route.length / 2)];
      useEffect(() => {
        if (animationEnabled) {
          const intervalId = setInterval(() => {
            setCarPositionIndex((prevIndex) => {
              // Eğer rota sona erdiyse animasyonu durdur
              if (prevIndex === route.length - 1) {
                setAnimationEnabled(false);
                return prevIndex;
              }
              return prevIndex + 1;
            });
          },  route[carPositionIndex][2] * 3600000); // Hız bilgisine göre interval süresi
    
          // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
          return () => clearInterval(intervalId);
        }
      }, [animationEnabled, carPositionIndex, route]);
    
      useEffect(() => {
        if (animationEnabled &&carPositionIndex <= route.length - 1) {
          const startPosition = route[carPositionIndex];
          const endPosition = route[carPositionIndex + 1];
          const distanceLat = endPosition[0] - startPosition[0];
          const distanceLon = endPosition[1] - startPosition[1];
          const steps = Math.max(Math.abs(distanceLat), Math.abs(distanceLon));
    
          const stepLat = distanceLat / steps;
          const stepLon = distanceLon / steps;
    
          let stepCount = 0;
    
          const intervalId = setInterval(() => {
            setCarPosition([
              startPosition[0] + stepLat * stepCount,
              startPosition[1] + stepLon * stepCount
            ]);
            stepCount++;
            if (stepCount >= steps) {
              clearInterval(intervalId);
              setCarPositionIndex((prevIndex) => prevIndex + 1);
            }
          }, timeSpan *10 / route[carPositionIndex][2] < 1 ? 2  : route[carPositionIndex][2]);
    
          return () => clearInterval(intervalId);
        }
      }, [animationEnabled, carPositionIndex, route]);
    
      const startAnimation = () => {
        setAnimationEnabled(true);
      };
    
      const stopAnimation = () => {
        setAnimationEnabled(false);
      };
    
      const resetAnimation = () => {
        setCarPositionIndex(0); // Başlangıç konumuna sıfırla
        setAnimationEnabled(false);
        setCarPosition([locations[0][0], locations[0][1]]);
      };
    const markerIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Box bg={'rgba(34,139,34, 0.3)'} borderRadius={'50%'} p={'10px'} color={'white'} ></Box>
        ),
        className: 'marker-icon',
    });
    const startIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdNotStarted} />
        ),
        className: 'marker-icon',
    });
    const endIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdFlag} />
        ),
        className: 'marker-icon',
    });
    const carIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdOutlineLocalTaxi} />
        ),
        className: 'marker-icon marker-transition',
    });
    if(route.length > 0){
        console.log(carPosition);
          return (
        <Box w={'100%'}>
            <Flex mb={'10px'} columnGap={'10px'}>
                <Button onClick={startAnimation} colorScheme='teal'><Icon as={MdPlayCircleOutline } /> Başlat</Button>
                <Button onClick={stopAnimation} colorScheme='yellow'><Icon as={MdOutlinePauseCircle  } /> Durdur</Button>
                <Button onClick={resetAnimation} colorScheme='facebook'><Icon as={MdReplay  } /> Başa Sar</Button>
            </Flex>
            <MapContainer center={centerCoordinates} zoom={zoomLevel} style={{ height: '70vh', width: '100%', zIndex: '0', borderRadius: "10px" }}>
                <LayersControl position="topright">
                    <BaseLayer checked name="Varsayılan Harita">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Uydu Görünümü">
                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar">
                        <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar (Uydu)">
                        <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                </LayersControl>
                <ChildComponent zoomLevel={13} centerCoordinates={middleLoc} />
                {route.map((location, index) => (

                    <Marker key={index} position={location} icon={
                        location == route[0] ? startIcon
                            : location == route[route.length - 1] ? endIcon
                                : markerIcon
                    }>

                    </Marker>
                ))}
                <Polyline positions={route} color="blue" />
                <Marker icon={carIcon} position={carPosition}   className="marker-transition">
         
        </Marker>
            </MapContainer>

        </Box>


    );  
    }
    else{
        return (
            <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
        );
    }

}

export default MapModal;
