// chakra imports
import { Box, Flex, Stack, Text, Icon, HStack, useColorModeValue, Tooltip, Image } from "@chakra-ui/react";
import { LogoutIcon, AccountIcon } from "components/icons/iconsUI";
//   Custom components
import Brand from "./Brand";
import Links from "./Links";
import SidebarCard from "./SidebarCard";
import React from "react";
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { MdLogout, MdAccountCircle } from "react-icons/md";
// FUNCTIONS
import { useTranslation } from 'react-i18next';
function SidebarContent(props) {
  let location = useLocation();
  const { routes, onCloseClick, onOpenSidebar } = props;
  const { t, i18n } = useTranslation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("black", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const history = useHistory();
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const handleLogout = () => {
    // Token ve diğer bilgileri localStorage'den temizle
    localStorage.removeItem('authToken');
    localStorage.removeItem('UserData');
    // Diğer logout işlemlerini gerçekleştir (isteğe bağlı)

    // Kullanıcıyı login sayfasına yönlendir

  };
  var routeLayout = '';
  routes.map((route, index) => {
    if (((userData != null && userData.Role == route.Role) || route.Role == "All") && (userData.Permissions.includes(route.Permission) || route.Permission == "All")) {
      routeLayout = route.layout;
      return;
    }
  });
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px={onOpenSidebar ? '16px' : '0'} borderRadius='30px'>
      <Brand onOpenSidebar={onOpenSidebar} />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={onOpenSidebar ? { md: "16px", "2xl": "1px" } : '0px'}>
          <Links routes={routes} CloseClick={onCloseClick} onOpenSidebar={onOpenSidebar} />

        </Box>

      </Stack>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={onOpenSidebar ? { md: "16px", "2xl": "1px" } : '0px'}>
          <NavLink to={routeLayout + '/profile'} onClick={onCloseClick}>
            <Box >
              <HStack
                spacing={
                  onOpenSidebar ?
                    activeRoute('/profile') ? "22px" : "26px" : "0"

                }
                flexDirection={onOpenSidebar ? 'unset' : 'row-reverse'}

                py='5px'
                ps='10px'>
                <Flex w={onOpenSidebar ? '100%' : '44px'} alignItems='center' justifyContent='end' >
                  <Tooltip label={t('accountsettings')} placement='auto-start' isDisabled={onOpenSidebar}>
                    <Box

                      color={
                        activeRoute('/profile')
                          ? activeIcon
                          : textColor
                      }
                      me={onOpenSidebar ? '18px' : '14px'}>

<Image src={AccountIcon} width='26px' height='26px' />

                    </Box>
                  </Tooltip>

                  <Text
                    display={onOpenSidebar ? 'unset' : 'none'}
                    me='auto'
                    color={
                      activeRoute('/profile')
                        ? activeColor
                        : textColor
                    }
                    fontWeight={
                      activeRoute('/profile')
                        ? "bold"
                        : "normal"
                    }>
                    {t('accountsettings')}
                  </Text>
                </Flex>
                <Box

                  h='36px'
                  w='4px'
                  bg={
                    activeRoute('/profile')
                      ? brandColor
                      : "transparent"
                  }
                  borderRadius='5px'
                />
              </HStack>
            </Box>
          </NavLink>
          <NavLink to={'/auth/login'} onClick={handleLogout}>
            <Box >
              <HStack
                spacing={
                  onOpenSidebar ? "26px" : "0"

                }
                flexDirection={onOpenSidebar ? 'unset' : 'row-reverse'}

                py='5px'
                ps='10px'>
                <Flex w={onOpenSidebar ? '100%' : '44px'} alignItems='center' justifyContent='end' >
                  <Tooltip label={t('logout')} placement='auto-start' isDisabled={onOpenSidebar}>
                    <Box

                      color={textColor}
                      me={onOpenSidebar ? '18px' : '14px'}>

                      <Image src={LogoutIcon} width='26px' height='26px' />

                    </Box>
                  </Tooltip>

                  <Text
                    display={onOpenSidebar ? 'unset' : 'none'}
                    me='auto'
                    color={textColor
                    }
                    fontWeight={"normal"
                    }>
                    {t('logout')}
                  </Text>
                </Flex>

              </HStack>
            </Box>
          </NavLink>


        </Box>

      </Stack>
      <Box
        display={onOpenSidebar ? 'unset' : 'none'}
        mt='60px'
        mb='40px'
        borderRadius='30px'>

        <SidebarCard />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
