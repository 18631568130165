/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import config from 'config';
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Select,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Textarea,
  Input,
  Image,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  createStandaloneToast,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import {
  MdEmail,
  MdPhone,
  MdLock
} from "react-icons/md";
import { CountryandCities } from "views/auth/register/variables/CityData";
import { RiEyeCloseLine } from "react-icons/ri";
import { CitiesLocations } from "variables/CityLocations";
import logoBlack from "assets/img/layout/parstaksilogo.png";
function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordReTyped, setPasswordReTyped] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [userNameGenerated, setUserNames] = useState('');
  const [addressed, setAddress] = useState('');
  const [tcNumber, setTcNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const history = useHistory();
  const toast = createStandaloneToast();
  const { t, i18n } = useTranslation();
  const handleRegister = async () => {
    var cityCode = CitiesLocations.filter(f => f.CityName == country)[0].plaka;
    // Giriş işlemi için API'ye istek gönder
    var model = JSON.stringify({
      "UserName": userNameGenerated,
      "Name": name,
      "Surname": surname,
      "Phone": phone,
      "Email": email,
      "Password": password,
      "PasswordRetyped": passwordReTyped,
      "CityCode": cityCode,
      "District": city,
      "CountryCode": "90",
      "Address": addressed,
      "TC": tcNumber

    });
    console.log(model)
    const response = await fetch(`${config.baseURL}/api/user/Register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: model,
    });
    const data = await response.json();
    console.log(data);


    if (response.ok && data.IsSuccess) {

      localStorage.setItem('rememberedUsername', userNameGenerated);
      localStorage.setItem('rememberedPassword', password);

      // Yönlendirme işlemi
      history.push('/auth/login');
    } else {

      handleClickToast("top-right", "error", t(data.Code.toString()))

      // Giriş başarısız

      console.error('Kullanıcı Adı yada Şifre yanlış kontrol edip tekrar deneyiniz!');

    }
  };
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const handleClickToast = (position, status, message) => {
    toast({
      description: message,
      status: status,
      duration: 3000,
      position: position,
      isClosable: true,
    });
  };
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleCityChange = (event) => {
    const selectedOption = CountryandCities.find(option => option.value === event.target.value);
    console.log(selectedOption.value)
    setSelectedCountry(selectedOption);
    setCountry(selectedOption.value);
    setCity(null); // İl değiştiğinde ilçeyi sıfırla
  };

  const handleDistrictChange = (event) => {
    const selectedOption = event.target.value;
    setCity(selectedOption);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        alignItems='center'
        justifyContent='center'
        w='100%'
        textAlign={'center'}
        display={{ base: "flex", md: "none" }}>
        <Image src={logoBlack} w='90%' />
      </Flex>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>

          <Heading color={textColor} fontSize='36px' mb='10px'>
            {t('signup')}
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            {t('signuppagetext')}
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "920px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'

          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button> */}
          {/* <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <Flex
              display={{ base: "block", md: "flex" }}
              flexWrap={'wrap'}
              columnGap={'10px'}
            >
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                width={{ base: "100%", md: "20%" }}
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder={t('name')}
                mb='24px'
                fontWeight='500'
                size='lg'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                width={{ base: "100%", md: "25%" }}
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder={t('surname')}
                mb='24px'
                fontWeight='500'
                size='lg'
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                width={{ base: "100%", md: "25%" }}
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder={t('tcnumber')}
                mb='24px'
                fontWeight='500'
                size='lg'
                value={tcNumber}
                onChange={(e) => setTcNumber(e.target.value)}
              />
            </Flex>
            <Flex
              display={{ base: "block", md: "flex" }}
              flexWrap={'wrap'}
              columnGap={'10px'}
            >
              <InputGroup
                width={{ base: "100%", md: "20%" }}
              >
                <InputLeftElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={MdPhone}
                  />
                </InputLeftElement>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder={t('phone')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </InputGroup>

              <InputGroup
                width={{ base: "100%", md: "30%" }}
              >
                <InputLeftElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={MdEmail}
                  />
                </InputLeftElement>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'

                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder={t('email')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                width={{ base: "100%", md: "20%" }}
              >
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'

                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder={t('username')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={userNameGenerated}
                  onChange={(e) => setUserNames(e.target.value)}
                />
              </InputGroup>
            </Flex>

            <Flex
              display={{ base: "block", md: "flex" }}
              flexWrap={'wrap'}
              columnGap={'10px'}
            >

              <InputGroup
                width={{ base: "100%", md: "35%" }}
              >
                <Select
                  id='country'
                  isRequired={true}
                  fontSize='sm'

                  placeholder={t('selectcity')}
                  mb='24px'
                  size='lg'
                  variant='auth'

                  onChange={handleCityChange}
                >
                  {CountryandCities.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup
                width={{ base: "100%", md: "35%" }}
              >

                <Select
                  id='city'
                  isRequired={true}
                  fontSize='sm'
                  placeholder={t('selectdistrict')}
                  mb='24px'
                  size='lg'
                  variant='auth'

                  onChange={handleDistrictChange}
                >
                  {selectedCountry && selectedCountry.districts.map(district => (
                    <option key={district} value={district}>{district}</option>
                  ))}
                </Select>
              </InputGroup>
              <InputGroup
                width={{ base: "100%", md: "72%" }}

              >
                <Textarea
                  placeholder={t('address')}
                  size='sm'
                  mb='24px'
                  borderRadius={'16px'}
                  className="chakra-input css-rpu85t"
                  resize={'none'}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </InputGroup>
            </Flex>
            <Flex
              display={{ base: "block", md: "flex" }}
              flexWrap={'wrap'}
              columnGap={'10px'}
            >
              <InputGroup
                width={{ base: "100%", md: "35%" }}
              >
                <InputLeftElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={MdLock}
                  />
                </InputLeftElement>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder={t('password')}
                  mb='24px'
                  size='lg'
                  type={"password"}
                  variant='auth'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                width={{ base: "100%", md: "35%" }}
              >
                <InputLeftElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={MdLock}
                  />
                </InputLeftElement>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder={t('passwordretry')}
                  mb='24px'
                  size='lg'
                  type={"password"}
                  variant='auth'
                  value={passwordReTyped}
                  onChange={(e) => setPasswordReTyped(e.target.value)}
                />
              </InputGroup>
            </Flex>


            <Button
              fontSize='sm'
              variant='brand'
              width={{ base: "100%", md: "35%" }}
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleRegister}
            >
              {t('signup')}
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              {t('haveaccount')}
              <NavLink to='/auth/login'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  {t('login')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
