// AuthService.js
import config from 'config';
class AuthService {
    constructor() {
        this.tokenKey = 'authToken';
    }

    login(tokenData) {
        localStorage.setItem(this.tokenKey, JSON.stringify(tokenData));
        console.log("Deneme: " + localStorage.getItem('authToken'));
    }

    logout() {
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('UserData');
    }

    isAuthenticated() {
    if(config.UpdateMode){
        return false;
    }
        const authData = JSON.parse(localStorage.getItem(this.tokenKey));

        if (!authData) {
            return false; // Oturum bilgisi yoksa, kullanıcı oturumlu değil
        }

        const expireTokenDate = new Date(authData.TokenExpireTime);
        const expireRefreshTokenDate = new Date(authData.RefReshTokenExpireTime);
        const currentDate = new Date();
        // Token'ın geçerliliğini kontrol et

        if (expireTokenDate < currentDate && expireRefreshTokenDate > currentDate) {
            var result = this.getToken();
            localStorage.removeItem(this.tokenKey);
            localStorage.setItem(this.tokenKey, JSON.stringify(result));
            return true;
        }
        else if (expireTokenDate > currentDate) {
            // Token süresi dolmuş, kullanıcıyı otomatik olarak çıkış yap

            return true;
        }
        else {
            this.logout();
            return false;
        }

    }

    async getToken() {
        // Giriş işlemi için API'ye istek gönder
        const response = await fetch(`${config.baseURL}/api/user/refreshtoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "RefreshToken": JSON.parse(localStorage.getItem(this.tokenKey)).RefreshToken, "Token": JSON.parse(localStorage.getItem(this.tokenKey)).Token, "UserName": localStorage.getItem("rememberedUsername") }),
        });
        const data = await response.json();
        if (response.ok && data.IsSuccess) {
            return data.Data;
        } else {
            return null;

        }
    }
    async getUserData() {
        if (this.isAuthenticated()) {
            const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
            const userEmail = localStorage.getItem('rememberedUsername');
            // Giriş işlemi için API'ye istek gönder
            const response = await fetch(`${config.baseURL}/api/user/myprofile`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    // Diğer isteğe bağlı başlıklar
                },

            });
            const data = await response.json();
            if (response.ok && data.IsSuccess) {
                return data.Data;
            } else {
                return null;

            }
        }

    }
}

export default AuthService;
