import React from "react";

import { Icon,Image } from "@chakra-ui/react";
import {HomeIcon, TaxiIcon, LocationIcon, UsersIcon} from "components/icons/iconsUI";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineShareLocation,
  MdOutlineLocalTaxi,
  MdOutlineTaxiAlert,
  MdOutlineKey,
  MdCompassCalibration,
  MdReceipt,
  MdOutlineWifiProtectedSetup,
  MdOutlineQrCode2,
  MdMap 
} from "react-icons/md";
import { FaUsersGear, FaShopLock } from "react-icons/fa6";
import { RiTestTubeFill } from "react-icons/ri";
import { GiPocketWatch } from "react-icons/gi";
// Admin Imports
import UserDashboard from "views/user/home";
import NewsCenterDashboard from "views/newscenter/home";
import CityTaxisMap from "views/newscenter/citytaximap";
import CityAdminDashboard from "views/CityAdmin/Home";
import AdminDashboard from "views/admin/homepage";
import ORCodeReader from "views/admin/QRcodereader";
import LostProperty from "views/newscenter/lostproperty";
import DealerDashboard from "views/dealer/home";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import CityAdminProfile from "views/CityAdmin/Profile";
import UserProfile from "views/user/Profile";
import DataTables from "views/admin/dataTables";
import LocationReports from "views/user/locationreports";
import CityAdminLocationReports from "views/CityAdmin/LocationReport";
import TaxiReports from "views/user/taxireports";
import AdminTaxiReports from "views/admin/taxireports";
import camTest from "views/admin/CamWatchTest";
import CityAdminTaxiReports from "views/CityAdmin/TaxiReports";
import UserManagement from "views/admin/UserManagement";
import TaximeterTest from "views/admin/taximeterTest";
import DeviceProductionList from "views/admin/productiondevicelist";
import CityUserManagement from "views/CityAdmin/UserManagement";
import Register from "views/auth/register";
import Update from "views/auth/update";
import StationDashboard from "views/station/home";
import StationProfile from "views/station/Profile";
import DealerProfile from "views/dealer/Profile";
import DealerCalibration from "views/dealer/calibrations";
import DealerTariff from "views/dealer/tariff";
import StationLocationReports from "views/station/locationreports";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
const userData = JSON.parse(localStorage.getItem('UserData'));
const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    Role: 'All'
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/register",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Register,
    Role: 'All'
  },
  {
    name: "Update",
    layout: "/auth",
    path: "/update",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Update,
    Role: 'All'
  },
  //------------------------------------------------------------------------------------Admin
  {
    name: "homepage",
    layout: "/admin",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: AdminDashboard,
    categories: false,
    show: true,
    Role: 1,
    Permission: 'All'
  },
  {
    name: "taximetertest",
    layout: "/admin",
    path: "/test",
    icon: <Icon as={RiTestTubeFill} width='20px' height='20px' color='inherit' />,
    component: TaximeterTest,
    categories: false,
    show: true,
    Role: 1,
    Permission: 'All'
  },
  {
    name: "lostproperty",
    layout: "/newscenter",
    path: "/missingfurniture",
    icon: <Icon as={GiPocketWatch} width='20px' height='20px' color='inherit' />,
    component: LostProperty,
    categories: false,
    show: true,
    Role: 1,
    Permission: 0
  },
  {
    name: "deviceproductionlist",
    layout: "/admin",
    path: "/deviceproductionlist",
    icon: <Icon as={MdOutlineWifiProtectedSetup} width='20px' height='20px' color='inherit' />,
    component: DeviceProductionList,
    categories: false,
    show: true,
    Role: 1,
    Permission: 536870912
  },
  {
    name: "Cam Test",
    layout: "/admin",
    path: "/camtest",
    icon: <Icon as={GiPocketWatch} width='20px' height='20px' color='inherit' />,
    component: camTest,
    categories: false,
    show: true,
    Role: 1,
    Permission: 0
  },
  {
    name: "qrcodereader",
    layout: "/admin",
    path: "/devicematching",
    icon: <Icon as={MdOutlineQrCode2} width='20px' height='20px' color='inherit' />,
    component: ORCodeReader,
    categories: false,
    show: true,
    Role: 1,
    Permission: 0
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
    show: false,
    Role: 1
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/data-tables",
    component: DataTables,
    categories: false,
    show: false,
    Role: 1
  },
  {
    name: "dealermanagement",
    layout: "/admin",
    icon: <Icon as={FaShopLock} width='20px' height='20px' color='inherit' />,
    path: "/dealer-management",
    component: UserManagement,
    categories: false,
    show: true,
    Role: 1,
    Permission: 0
  },
  {
    name: "usermanagement",
    layout: "/admin",
    icon: <Image src={UsersIcon} width='26px' height='26px'  />,
    path: "/user-management",
    component: UserManagement,
    categories: false,
    show: true,
    Role: 1
  },
  {
    name: "myaccount",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    Role: 1
  },
  {
    name: "taxireports",
    layout: "/admin",
    icon: <Image src={TaxiIcon} width='26px' height='26px'  />,
    path: "/taxi_reports",
    component: AdminTaxiReports,
    categories: true,
    show: true,
    Role: 1
  },
  //------------------------------------------------------------------------------------CityAdmin
  {
    name: "homepage",
    layout: "/cityadmin",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: CityAdminDashboard,
    categories: false,
    show: true,
    Role: 2,
    Permission: 'All'
  },
  {
    name: "usermanagement",
    layout: "/cityadmin",
    icon: <Image src={UsersIcon} width='26px' height='26px'  />,
    path: "/user-management",
    component: CityUserManagement,
    categories: false,
    show: true,
    Role: 2,
    Permission: 8
  },
  {
    name: "taxireports",
    layout: "/cityadmin",
    icon: <Image src={TaxiIcon} width='26px' height='26px'  />,
    path: "/taxi_reports",
    component: CityAdminTaxiReports,
    categories: true,
    show: true,
    Role: 2,
    Permission: 8
  },
  {
    name: "locationreports",
    layout: "/cityadmin",
    icon: <Image src={LocationIcon} width='26px' height='26px'  />,
    path: "/location_reports",
    component: CityAdminLocationReports,
    categories: true,
    show: true,
    Role: 2,
    Permission: 8
  },
  {
    name: "myaccount",
    layout: "/cityadmin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: CityAdminProfile,
    Role: 1,
    Permission: 'All'

  },
  //------------------------------------------------------------------------------------NewsCenter
  {
    name: "homepage",
    layout: "/newscenter",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: NewsCenterDashboard,
    categories: true,
    show: true,
    Role: 128,
    Permission: 'All'
  },
  {
    name: "lostproperty",
    layout: "/newscenter",
    path: "/missingfurniture",
    icon: <Icon as={GiPocketWatch} width='20px' height='20px' color='inherit' />,
    component: LostProperty,
    categories: false,
    show: true,
    Role: 128,
    Permission: 'All'
  },
  {
    name: "citytaxis",
    layout: "/newscenter",
    path: "/citytaxis",
    icon: <Icon as={MdMap } width='20px' height='20px' color='inherit' />,
    component: CityTaxisMap,
    categories: false,
    show: true,
    Role: 128,
    Permission: 16384
  },
  //------------------------------------------------------------------------------------Dealer
  {
    name: "homepage",
    layout: "/dealer",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: DealerDashboard,
    categories: true,
    show: true,
    Role: 4,
    Permission: 'All'
  },
  {
    name: "calibration",
    layout: "/dealer",
    icon: <Icon as={MdCompassCalibration} width='20px' height='20px' color='inherit' />,
    path: "/calibration",
    component: DealerCalibration,
    categories: true,
    show: true,
    Role: 4,
    Permission: 16384
  },
  {
    name: "tariff",
    layout: "/dealer",
    icon: <Icon as={MdReceipt} width='20px' height='20px' color='inherit' />,
    path: "/tariff",
    component: DealerTariff,
    categories: true,
    show: true,
    Role: 4,
    Permission: 16384
  },
  {
    name: "myaccount",
    layout: "/dealer",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: DealerProfile,
    Role: 4,
    Permission: 'All'
  },
  //------------------------------------------------------------------------------------TaxiOwner
  {
    name: "homepage",
    layout: "/user",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: UserDashboard,
    categories: true,
    show: true,
    Role: 8,
    Permission: 'All'
  },
  {
    name: "taxireports",
    layout: "/user",
    icon: <Image src={TaxiIcon} width='26px' height='26px'  />,
    path: "/taxi_reports",
    component: TaxiReports,
    categories: true,
    show: true,
    Role: 8,
    Permission: 16
  },
  {
    name: "locationreports",
    layout: "/user",
    icon: <Image src={LocationIcon} width='26px' height='26px'  />,
    path: "/location_reports",
    component: LocationReports,
    categories: true,
    show: true,
    Role: 8,
    Permission: 16384
  },
  {
    name: "myaccount",
    layout: "/user",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: UserProfile,
    Role: 8,
    Permission: 'All'
  },
  //------------------------------------------------------------------------------------Station
  {
    name: "homepage",
    layout: "/station",
    path: "/home",
    icon: <Image src={HomeIcon} width='26px' height='26px'  />,
    component: StationDashboard,
    categories: true,
    show: true,
    Role: 32,
    Permission: 'All'
  },
  {
    name: "locationreports",
    layout: "/station",
    icon: <Image src={LocationIcon} width='26px' height='26px'  />,
    path: "/location_reports",
    component: StationLocationReports,
    categories: true,
    show: true,
    Role: 32,
    Permission: 16384
  },
  {
    name: "myaccount",
    layout: "/station",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: StationProfile,
    Role: 32,
    Permission: 'All'
  },
];





export default routes;
