// AuthService.js
import config from 'config';
class StationService {
    constructor() {
        this.tokenKey = 'authToken';
    }

    async MyStations() {
        const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
        // Giriş işlemi için API'ye istek gönder
        const response = await fetch(config.baseURL + config.endpoints.MyStations, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
        const data = await response.json();

            return data;
     
    }
    async StationsOfUser(userID) {
      const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
      // Giriş işlemi için API'ye istek gönder
      const response = await fetch(config.baseURL + config.endpoints.StationsOfUser + "?userId=" + userID, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        
        });
      const data = await response.json();

          return data;
   
  }
}

export default StationService;
