import React from "react";

// Chakra imports
import {  Image, Flex, useColorModeValue,  } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import logoWhite from "assets/img/layout/parstaksilogo.png";
export function SidebarBrand(props) {
  //   Chakra color mode
  const {onOpenSidebar } = props
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align={onOpenSidebar?'center':'end'} direction='column' >
  <Image src={logoWhite} h={onOpenSidebar?'30px':'20px'} w={onOpenSidebar?'80%':'28px'} objectFit={onOpenSidebar?'contain':'cover'} objectPosition={'left'}  mb='20px' mr={'10px'}/>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
