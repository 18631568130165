export const CitiesLocations = [
    {
      "plaka": "01",
      "CityName": "Adana",
      "lat": 37,
      "lon": 35.3213333,
      "northeast_lat": 37.072004,
      "northeast_lon": 35.461995,
      "southwest_lat": 36.935523,
      "southwest_lon": 35.174706
    },
    {
      "plaka": "02",
      "CityName": "Adıyaman",
      "lat": 37.7641667,
      "lon": 38.2761667,
      "northeast_lat": 37.825667,
      "northeast_lon": 38.335465,
      "southwest_lat": 37.717086,
      "southwest_lon": 38.188188
    },
    {
      "plaka": "03",
      "CityName": "Afyonkarahisar",
      "lat": 38.76376,
      "lon": 30.54034,
      "northeast_lat": 38.802105,
      "northeast_lon": 30.611167,
      "southwest_lat": 38.714289,
      "southwest_lon": 30.44232
    },
    {
      "plaka": "04",
      "CityName": "Ağrı",
      "lat": 39.7216667,
      "lon": 43.0566667,
      "northeast_lat": 39.748605,
      "northeast_lon": 43.085241,
      "southwest_lat": 39.688144,
      "southwest_lon": 43.001778
    },
    {
      "plaka": "05",
      "CityName": "Amasya",
      "lat": 40.65,
      "lon": 35.8333333,
      "northeast_lat": 40.672834,
      "northeast_lon": 35.856321,
      "southwest_lat": 40.636911,
      "southwest_lon": 35.789091
    },
    {
      "plaka": "06",
      "CityName": "Ankara",
      "lat": 39.92077,
      "lon": 32.85411,
      "northeast_lat": 40.100981,
      "northeast_lon": 33.024866,
      "southwest_lat": 39.722821,
      "southwest_lon": 32.499097
    },
    {
      "plaka": "07",
      "CityName": "Antalya",
      "lat": 36.88414,
      "lon": 30.70563,
      "northeast_lat": 36.975178,
      "northeast_lon": 30.840953,
      "southwest_lat": 36.785866,
      "southwest_lon": 30.516095
    },
    {
      "plaka": "08",
      "CityName": "Artvin",
      "lat": 41.1833333,
      "lon": 41.8166667,
      "northeast_lat": 41.207078,
      "northeast_lon": 41.854799,
      "southwest_lat": 41.155415,
      "southwest_lon": 41.777361
    },
    {
      "plaka": "09",
      "CityName": "Aydın",
      "lat": 37.8444,
      "lon": 27.8458,
      "northeast_lat": 37.870997,
      "northeast_lon": 27.885355,
      "southwest_lat": 37.819573,
      "southwest_lon": 27.790522
    },
    {
      "plaka": "10",
      "CityName": "Balıkesir",
      "lat": 39.648369,
      "lon": 27.88261,
      "northeast_lat": 39.693666,
      "northeast_lon": 27.95241,
      "southwest_lat": 39.612411,
      "southwest_lon": 27.838313
    },
    {
      "plaka": "11",
      "CityName": "Bilecik",
      "lat": 40.150131,
      "lon": 29.983061,
      "northeast_lat": 40.197617,
      "northeast_lon": 30.013592,
      "southwest_lat": 40.106243,
      "southwest_lon": 29.956657
    },
    {
      "plaka": "12",
      "CityName": "Bingöl",
      "lat": 38.885349,
      "lon": 40.498291,
      "northeast_lat": 38.897963,
      "northeast_lon": 40.525929,
      "southwest_lat": 38.872987,
      "southwest_lon": 40.477258
    },
    {
      "plaka": "13",
      "CityName": "Bitlis",
      "lat": 38.4,
      "lon": 42.1166667,
      "northeast_lat": 38.453638,
      "northeast_lon": 42.148879,
      "southwest_lat": 38.385312,
      "southwest_lon": 42.09384
    },
    {
      "plaka": "14",
      "CityName": "Bolu",
      "lat": 40.739479,
      "lon": 31.611561,
      "northeast_lat": 40.758004,
      "northeast_lon": 31.658418,
      "southwest_lat": 40.716802,
      "southwest_lon": 31.573324
    },
    {
      "plaka": "15",
      "CityName": "Burdur",
      "lat": 37.726909,
      "lon": 30.288876,
      "northeast_lat": 37.76375,
      "northeast_lon": 30.336662,
      "southwest_lat": 37.691433,
      "southwest_lon": 30.191757
    },
    {
      "plaka": "16",
      "CityName": "Bursa",
      "lat": 40.18257,
      "lon": 29.06687,
      "northeast_lat": 40.297194,
      "northeast_lon": 29.211237,
      "southwest_lat": 40.162997,
      "southwest_lon": 28.85963
    },
    {
      "plaka": "17",
      "CityName": "Çanakkale",
      "lat": 40.155312,
      "lon": 26.41416,
      "northeast_lat": 40.199462,
      "northeast_lon": 26.457106,
      "southwest_lat": 40.088086,
      "southwest_lon": 26.378026
    },
    {
      "plaka": "18",
      "CityName": "Çankırı",
      "lat": 40.6,
      "lon": 33.6166667,
      "northeast_lat": 40.655159,
      "northeast_lon": 33.684853,
      "southwest_lat": 40.557709,
      "southwest_lon": 33.596571
    },
    {
      "plaka": "19",
      "CityName": "Çorum",
      "lat": 40.5505556,
      "lon": 34.9555556,
      "northeast_lat": 40.609317,
      "northeast_lon": 35.006438,
      "southwest_lat": 40.495214,
      "southwest_lon": 34.887634
    },
    {
      "plaka": "20",
      "CityName": "Denizli",
      "lat": 37.77652,
      "lon": 29.08639,
      "northeast_lat": 37.847366,
      "northeast_lon": 29.188915,
      "southwest_lat": 37.716171,
      "southwest_lon": 28.981373
    },
    {
      "plaka": "21",
      "CityName": "Diyarbakır",
      "lat": 37.91441,
      "lon": 40.230629,
      "northeast_lat": 37.976174,
      "northeast_lon": 40.245803,
      "southwest_lat": 37.879624,
      "southwest_lon": 40.106159
    },
    {
      "plaka": "22",
      "CityName": "Edirne",
      "lat": 41.6666667,
      "lon": 26.5666667,
      "northeast_lat": 41.697939,
      "northeast_lon": 26.603093,
      "southwest_lat": 41.649935,
      "southwest_lon": 26.514059
    },
    {
      "plaka": "23",
      "CityName": "Elazığ",
      "lat": 38.680969,
      "lon": 39.226398,
      "northeast_lat": 38.713568,
      "northeast_lon": 39.277314,
      "southwest_lat": 38.630564,
      "southwest_lon": 39.133405
    },
    {
      "plaka": "24",
      "CityName": "Erzincan",
      "lat": 39.75,
      "lon": 39.5,
      "northeast_lat": 39.769164,
      "northeast_lon": 39.531743,
      "southwest_lat": 39.729884,
      "southwest_lon": 39.460253
    },
    {
      "plaka": "25",
      "CityName": "Erzurum",
      "lat": 39.9043189,
      "lon": 41.2678853,
      "northeast_lat": 39.954533,
      "northeast_lon": 41.303594,
      "southwest_lat": 39.866715,
      "southwest_lon": 41.177093
    },
    {
      "plaka": "26",
      "CityName": "Eskişehir",
      "lat": 39.784302,
      "lon": 30.51922,
      "northeast_lat": 39.816793,
      "northeast_lon": 30.66769,
      "southwest_lat": 39.724752,
      "southwest_lon": 30.414084
    },
    {
      "plaka": "27",
      "CityName": "Gaziantep",
      "lat": 37.06622,
      "lon": 37.38332,
      "northeast_lat": 37.113722,
      "northeast_lon": 37.466958,
      "southwest_lat": 37.005947,
      "southwest_lon": 37.303709
    },
    {
      "plaka": "28",
      "CityName": "Giresun",
      "lat": 40.912811,
      "lon": 38.38953,
      "northeast_lat": 40.927995,
      "northeast_lon": 38.450044,
      "southwest_lat": 40.877876,
      "southwest_lon": 38.309018
    },
    {
      "plaka": "29",
      "CityName": "Gümüşhane",
      "lat": 40.4602778,
      "lon": 39.4813889,
      "northeast_lat": 40.471359,
      "northeast_lon": 39.520641,
      "southwest_lat": 40.430201,
      "southwest_lon": 39.446914
    },
    {
      "plaka": "30",
      "CityName": "Hakkari",
      "lat": 37.5833333,
      "lon": 43.7333333,
      "northeast_lat": 37.601656,
      "northeast_lon": 43.762257,
      "southwest_lat": 37.551549,
      "southwest_lon": 43.69809
    },
    {
      "plaka": "31",
      "CityName": "Hatay",
      "lat": 36.4018488,
      "lon": 36.3498097,
      "northeast_lat": 37.014921,
      "northeast_lon": 36.697535,
      "southwest_lat": 35.812692,
      "southwest_lon": 35.779375
    },
    {
      "plaka": "32",
      "CityName": "Isparta",
      "lat": 37.7666667,
      "lon": 30.55,
      "northeast_lat": 37.830719,
      "northeast_lon": 30.609197,
      "southwest_lat": 37.73988,
      "southwest_lon": 30.503673
    },
    {
      "plaka": "33",
      "CityName": "Mersin",
      "lat": 36.8,
      "lon": 34.6333333,
      "northeast_lat": 36.878272,
      "northeast_lon": 34.716702,
      "southwest_lat": 36.699503,
      "southwest_lon": 34.457655
    },
    {
      "plaka": "34",
      "CityName": "İstanbul",
      "lat": 41.00527,
      "lon": 28.97696,
      "northeast_lat": 41.320786,
      "northeast_lon": 29.456456,
      "southwest_lat": 40.80275,
      "southwest_lon": 27.971307
    },
    {
      "plaka": "35",
      "CityName": "İzmir",
      "lat": 38.41885,
      "lon": 27.12872,
      "northeast_lat": 38.506773,
      "northeast_lon": 27.304415,
      "southwest_lat": 38.343157,
      "southwest_lon": 27.017192
    },
    {
      "plaka": "36",
      "CityName": "Kars",
      "lat": 40.59267,
      "lon": 43.077831,
      "northeast_lat": 40.624843,
      "northeast_lon": 43.1339,
      "southwest_lat": 40.577484,
      "southwest_lon": 43.056432
    },
    {
      "plaka": "37",
      "CityName": "Kastamonu",
      "lat": 41.38871,
      "lon": 33.78273,
      "northeast_lat": 41.436059,
      "northeast_lon": 33.814989,
      "southwest_lat": 41.350668,
      "southwest_lon": 33.752901
    },
    {
      "plaka": "38",
      "CityName": "Kayseri",
      "lat": 38.7333333,
      "lon": 35.4833333,
      "northeast_lat": 38.818797,
      "northeast_lon": 35.627675,
      "southwest_lat": 38.622002,
      "southwest_lon": 35.304324
    },
    {
      "plaka": "39",
      "CityName": "Kırklareli",
      "lat": 41.7333333,
      "lon": 27.2166667,
      "northeast_lat": 41.760054,
      "northeast_lon": 27.24222,
      "southwest_lat": 41.710615,
      "southwest_lon": 27.182843
    },
    {
      "plaka": "40",
      "CityName": "Kırşehir",
      "lat": 39.15,
      "lon": 34.1666667,
      "northeast_lat": 39.203939,
      "northeast_lon": 34.217743,
      "southwest_lat": 39.081293,
      "southwest_lon": 34.125532
    },
    {
      "plaka": "41",
      "CityName": "Kocaeli",
      "lat": 40.8532704,
      "lon": 29.8815203,
      "northeast_lat": 41.20976,
      "northeast_lon": 30.359684,
      "southwest_lat": 40.532782,
      "southwest_lon": 29.333462
    },
    {
      "plaka": "42",
      "CityName": "Konya",
      "lat": 37.8666667,
      "lon": 32.4833333,
      "northeast_lat": 38.05063,
      "northeast_lon": 32.680116,
      "southwest_lat": 37.719031,
      "southwest_lon": 32.338737
    },
    {
      "plaka": "43",
      "CityName": "Kütahya",
      "lat": 39.4166667,
      "lon": 29.9833333,
      "northeast_lat": 39.442718,
      "northeast_lon": 30.066503,
      "southwest_lat": 39.379473,
      "southwest_lon": 29.919199
    },
    {
      "plaka": "44",
      "CityName": "Malatya",
      "lat": 38.35519,
      "lon": 38.30946,
      "northeast_lat": 38.397559,
      "northeast_lon": 38.453244,
      "southwest_lat": 38.305123,
      "southwest_lon": 38.232622
    },
    {
      "plaka": "45",
      "CityName": "Manisa",
      "lat": 38.619099,
      "lon": 27.428921,
      "northeast_lat": 38.642289,
      "northeast_lon": 27.487976,
      "southwest_lat": 38.602376,
      "southwest_lon": 27.326086
    },
    {
      "plaka": "46",
      "CityName": "Kahramanmaraş",
      "lat": 37.5833333,
      "lon": 36.9333333,
      "northeast_lat": 37.605261,
      "northeast_lon": 36.997678,
      "southwest_lat": 37.530309,
      "southwest_lon": 36.829703
    },
    {
      "plaka": "47",
      "CityName": "Mardin",
      "lat": 37.3122361,
      "lon": 40.735112,
      "northeast_lat": 37.330554,
      "northeast_lon": 40.764486,
      "southwest_lat": 37.305109,
      "southwest_lon": 40.716971
    },
    {
      "plaka": "48",
      "CityName": "Muğla",
      "lat": 37.2152778,
      "lon": 28.3636111,
      "northeast_lat": 37.22581,
      "northeast_lon": 28.414498,
      "southwest_lat": 37.194651,
      "southwest_lon": 28.341279
    },
    {
      "plaka": "49",
      "CityName": "Muş",
      "lat": 38.7432926,
      "lon": 41.5064823,
      "northeast_lat": 38.783503,
      "northeast_lon": 41.540625,
      "southwest_lat": 38.718224,
      "southwest_lon": 41.472306
    },
    {
      "plaka": "50",
      "CityName": "Nevşehir",
      "lat": 38.62442,
      "lon": 34.723969,
      "northeast_lat": 38.684107,
      "northeast_lon": 34.764759,
      "southwest_lat": 38.589047,
      "southwest_lon": 34.669752
    },
    {
      "plaka": "51",
      "CityName": "Niğde",
      "lat": 37.9666667,
      "lon": 34.6833333,
      "northeast_lat": 37.979913,
      "northeast_lon": 34.691585,
      "southwest_lat": 37.956992,
      "southwest_lon": 34.661906
    },
    {
      "plaka": "52",
      "CityName": "Ordu",
      "lat": 40.9833333,
      "lon": 37.8833333,
      "northeast_lat": 41.02034,
      "northeast_lon": 37.978976,
      "southwest_lat": 40.931631,
      "southwest_lon": 37.825992
    },
    {
      "plaka": "53",
      "CityName": "Rize",
      "lat": 41.02005,
      "lon": 40.523449,
      "northeast_lat": 41.046492,
      "northeast_lon": 40.586802,
      "southwest_lat": 41.010782,
      "southwest_lon": 40.478578
    },
    {
      "plaka": "54",
      "CityName": "Sakarya",
      "lat": 40.7568793,
      "lon": 30.378138,
      "northeast_lat": 40.825518,
      "northeast_lon": 30.439832,
      "southwest_lat": 40.686199,
      "southwest_lon": 30.322138
    },
    {
      "plaka": "55",
      "CityName": "Samsun",
      "lat": 41.292782,
      "lon": 36.33128,
      "northeast_lat": 41.344134,
      "northeast_lon": 36.381205,
      "southwest_lat": 41.235002,
      "southwest_lon": 36.249519
    },
    {
      "plaka": "56",
      "CityName": "Siirt",
      "lat": 37.94429,
      "lon": 41.93288,
      "northeast_lat": 37.95594,
      "northeast_lon": 41.963658,
      "southwest_lat": 37.909671,
      "southwest_lon": 41.872547
    },
    {
      "plaka": "57",
      "CityName": "Sinop",
      "lat": 42.0264222,
      "lon": 35.1550745,
      "northeast_lat": 42.032135,
      "northeast_lon": 35.194947,
      "southwest_lat": 42.012675,
      "southwest_lon": 35.115957
    },
    {
      "plaka": "58",
      "CityName": "Sivas",
      "lat": 39.747662,
      "lon": 37.017879,
      "northeast_lat": 39.783669,
      "northeast_lon": 37.061673,
      "southwest_lat": 39.699024,
      "southwest_lon": 36.919858
    },
    {
      "plaka": "59",
      "CityName": "Tekirdağ",
      "lat": 40.9833333,
      "lon": 27.5166667,
      "northeast_lat": 40.996723,
      "northeast_lon": 27.600252,
      "southwest_lat": 40.937704,
      "southwest_lon": 27.468993
    },
    {
      "plaka": "60",
      "CityName": "Tokat",
      "lat": 40.3166667,
      "lon": 36.55,
      "northeast_lat": 40.358932,
      "northeast_lon": 36.593219,
      "southwest_lat": 40.267868,
      "southwest_lon": 36.469662
    },
    {
      "plaka": "61",
      "CityName": "Trabzon",
      "lat": 41,
      "lon": 39.7333333,
      "northeast_lat": 41.01237,
      "northeast_lon": 39.82051,
      "southwest_lat": 40.955736,
      "southwest_lon": 39.652407
    },
    {
      "plaka": "62",
      "CityName": "Tunceli",
      "lat": 39.1079868,
      "lon": 39.5401672,
      "northeast_lat": 39.113477,
      "northeast_lon": 39.55185,
      "southwest_lat": 39.094534,
      "southwest_lon": 39.530015
    },
    {
      "plaka": "63",
      "CityName": "Şanlıurfa",
      "lat": 37.15,
      "lon": 38.8,
      "northeast_lat": 37.189639,
      "northeast_lon": 38.840876,
      "southwest_lat": 37.098274,
      "southwest_lon": 38.767868
    },
    {
      "plaka": "64",
      "CityName": "Uşak",
      "lat": 38.682301,
      "lon": 29.40819,
      "northeast_lat": 38.71588,
      "northeast_lon": 29.469838,
      "southwest_lat": 38.630679,
      "southwest_lon": 29.355936
    },
    {
      "plaka": "65",
      "CityName": "Van",
      "lat": 38.4941667,
      "lon": 43.38,
      "northeast_lat": 38.580314,
      "northeast_lon": 43.455356,
      "southwest_lat": 38.42188,
      "southwest_lon": 43.269524
    },
    {
      "plaka": "66",
      "CityName": "Yozgat",
      "lat": 39.82,
      "lon": 34.8044444,
      "northeast_lat": 39.838423,
      "northeast_lon": 34.858236,
      "southwest_lat": 39.798968,
      "southwest_lon": 34.771094
    },
    {
      "plaka": "67",
      "CityName": "Zonguldak",
      "lat": 41.456409,
      "lon": 31.798731,
      "northeast_lat": 41.480784,
      "northeast_lon": 31.874425,
      "southwest_lat": 41.425296,
      "southwest_lon": 31.757052
    },
    {
      "plaka": "68",
      "CityName": "Aksaray",
      "lat": 38.36869,
      "lon": 34.03698,
      "northeast_lat": 38.446724,
      "northeast_lon": 34.088698,
      "southwest_lat": 38.297012,
      "southwest_lon": 33.932515
    },
    {
      "plaka": "69",
      "CityName": "Bayburt",
      "lat": 40.255169,
      "lon": 40.22488,
      "northeast_lat": 40.272636,
      "northeast_lon": 40.238261,
      "southwest_lat": 40.242881,
      "southwest_lon": 40.208762
    },
    {
      "plaka": "70",
      "CityName": "Karaman",
      "lat": 37.17593,
      "lon": 33.228748,
      "northeast_lat": 37.229373,
      "northeast_lon": 33.334244,
      "southwest_lat": 37.148257,
      "southwest_lon": 33.166003
    },
    {
      "plaka": "71",
      "CityName": "Kırıkkale",
      "lat": 39.846821,
      "lon": 33.515251,
      "northeast_lat": 39.872843,
      "northeast_lon": 33.597968,
      "southwest_lat": 39.813802,
      "southwest_lon": 33.468289
    },
    {
      "plaka": "72",
      "CityName": "Batman",
      "lat": 37.881168,
      "lon": 41.13509,
      "northeast_lat": 37.947274,
      "northeast_lon": 41.17603,
      "southwest_lat": 37.85657,
      "southwest_lon": 41.080591
    },
    {
      "plaka": "73",
      "CityName": "Şırnak",
      "lat": 37.5163889,
      "lon": 42.4611111,
      "northeast_lat": 37.533572,
      "northeast_lon": 42.471127,
      "southwest_lat": 37.500093,
      "southwest_lon": 42.444631
    },
    {
      "plaka": "74",
      "CityName": "Bartın",
      "lat": 41.6344444,
      "lon": 32.3375,
      "northeast_lat": 41.656542,
      "northeast_lon": 32.37105,
      "southwest_lat": 41.613513,
      "southwest_lon": 32.297457
    },
    {
      "plaka": "75",
      "CityName": "Ardahan",
      "lat": 41.110481,
      "lon": 42.702171,
      "northeast_lat": 41.121467,
      "northeast_lon": 42.717129,
      "southwest_lat": 41.09891,
      "southwest_lon": 42.686418
    },
    {
      "plaka": "76",
      "CityName": "Iğdır",
      "lat": 39.9166667,
      "lon": 44.0333333,
      "northeast_lat": 39.950163,
      "northeast_lon": 44.092825,
      "southwest_lat": 39.884109,
      "southwest_lon": 43.980729
    },
    {
      "plaka": "77",
      "CityName": "Yalova",
      "lat": 40.65,
      "lon": 29.2666667,
      "northeast_lat": 40.66806,
      "northeast_lon": 29.316536,
      "southwest_lat": 40.634138,
      "southwest_lon": 29.210233
    },
    {
      "plaka": "78",
      "CityName": "Karabük",
      "lat": 41.2,
      "lon": 32.6333333,
      "northeast_lat": 41.238129,
      "northeast_lon": 32.675518,
      "southwest_lat": 41.163014,
      "southwest_lon": 32.592582
    },
    {
      "plaka": "79",
      "CityName": "Kilis",
      "lat": 36.718399,
      "lon": 37.12122,
      "northeast_lat": 36.747819,
      "northeast_lon": 37.159541,
      "southwest_lat": 36.702673,
      "southwest_lon": 37.085183
    },
    {
      "plaka": "80",
      "CityName": "Osmaniye",
      "lat": 37.06805,
      "lon": 36.261589,
      "northeast_lat": 37.104061,
      "northeast_lon": 36.287941,
      "southwest_lat": 37.04694,
      "southwest_lon": 36.208034
    },
    {
      "plaka": "81",
      "CityName": "Düzce",
      "lat": 40.843849,
      "lon": 31.15654,
      "northeast_lat": 40.891762,
      "northeast_lon": 31.195807,
      "southwest_lat": 40.824717,
      "southwest_lon": 31.106662
    }
  ]
  
  
  
  
  