import React, { useMemo, useState } from "react";
import config from 'config';
// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  Button,
  Box,
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Textarea,
  InputGroup,
  InputLeftElement,
  createStandaloneToast
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdOutlineSettings,
  MdPhone,
  MdEmail,
  MdLock,

} from "react-icons/md";
import { CitiesLocations } from "variables/CityLocations";
import { FaUserPlus } from "react-icons/fa";
import { CountryandCities } from "../variables/CityData";
import { countries } from "variables/Country";
import UserService from "Services/UserService/UserService";
const userService = new UserService();
const handleClickToast = (position, status, message) => {
  const toast = createStandaloneToast();
  toast({
    description: message,
    status: status,
    duration: 3000,
    position: position,
    isClosable: true,
  });
};
function turkishToEnglish(str) {
  const turkishChars = "çğıöşüÇĞİÖŞÜ";
  const englishChars = "cgiosuCGIOSU";
  
  return str
    .replace(/./g, function(char) {
      const index = turkishChars.indexOf(char);
      return index > -1 ? englishChars.charAt(index) : char;
    })
    .replace(/\s/g, "");
}
export default function AddUserButton({ type, param, adress, deviceID, tripID }) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [userNameGenerated, setUserNames] = useState('');
  const [password, setPassword] = useState('');
  const [passwordReTyped, setPasswordReTyped] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('90');
  const [addressed, setAddress] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCityChange = (event) => {
    const selectedOption = CountryandCities.find(option => option.value === event.target.value);
    console.log(selectedOption.value)
    setSelectedCountry(selectedOption);
    setCountry(selectedOption.value);
    setCity(null); // İl değiştiğinde ilçeyi sıfırla
  };
  const handleRegister = async () => {
    // Giriş işlemi için API'ye istek gönder
    var model = JSON.stringify(
      {
        "UserName": userNameGenerated,
        "Name": name,
        "Surname": surname,
        "Phone": phone,
        "Email": email,
        "Password": password,
        "PasswordRetyped": passwordReTyped,
        "CityCode": country,
        "CountryCode": countryCode,
        "Address": addressed
      }

    );
    console.log(model)

    const data = await userService.AddUserToSystem(model);
    console.log(data);


    if (data.IsSuccess) {
      handleClickToast("top-right", "success", 'Kullanıcı kaydı başarılı')
      setEmail('');
      setUserNames('');
      setPassword('');
      setPasswordReTyped('');
      setPhone('');
      setName('');
      setCountry('');
      setAddress('');
      setSelectedCountry('');
    } else {
      data.ValidationErrors.forEach(item => {
        handleClickToast("top-right", "error", item)
      });
      // Giriş başarısız

      console.error('Kullanıcı Adı yada Şifre yanlış kontrol edip tekrar deneyiniz!');

    }
  };
  const handleDistrictChange = (event) => {
    const selectedOption = event.target.value;
    setCity(selectedOption);
  };

  const handleClick = async () => {
    onOpen();
  };
  return (
    <>

      <Button colorScheme="teal" align='center' onClick={handleClick}>
        <Icon as={FaUserPlus} h='16px' w='16px' me='8px' />
        <Text fontSize='sm' fontWeight='400'>
          Kullanıcı Ekle
        </Text>
      </Button>
      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='none'
          backdropFilter='blur(10px) ' />
        <ModalContent backgroundColor={'rgba(255,255,255,0.8)'} p={'20px 0'}>


          <ModalBody>
            <Text fontSize={'xl'} m={'0px 5px 10px'} fontWeight={'600'}>Yeni Kullanıcı Oluştur</Text>
            <FormControl>
              <Flex
                display={{ base: "block", md: "flex" }}
                flexWrap={'wrap'}
                columnGap={'10px'}
              >
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  width={{ base: "100%", md: "49%" }}
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Adı'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  width={{ base: "100%", md: "49%" }}
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Soyadı'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </Flex>
              <Flex
                display={{ base: "block", md: "flex" }}
                flexWrap={'wrap'}
                columnGap={'10px'}
              >
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  width={{ base: "100%", md: "49%" }}
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Kullanıcı Adı'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={userNameGenerated}
                  onChange={(e) => setUserNames(e.target.value)}
                />
        <Select
                    id='country'
                 
                    fontSize='sm'

                    placeholder='Ülke Seçiniz'
                    mb='24px'
                    size='lg'
                    variant='auth'
                    isReadOnly = {true}

                  >
                    {countries.map(options => (
                      <option key={options.code} value={options.code} selected= {options.code == '90' ? true : false}>{options.name}</option>
                    ))}
                  </Select>
              </Flex>
              <Flex
                display={{ base: "block", md: "flex" }}
                flexWrap={'wrap'}
                columnGap={'10px'}
              >
                <InputGroup
                  width={{ base: "100%", md: "44%" }}
                >
                  <InputLeftElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={MdPhone}
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Telefon'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </InputGroup>

                <InputGroup
                  width={{ base: "100%", md: "54%" }}
                >
                  <InputLeftElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={MdEmail}
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'

                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Eposta'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>

              </Flex>
              <Flex
                display={{ base: "block", md: "flex" }}
                flexWrap={'wrap'}
                columnGap={'10px'}
              >
                <InputGroup
                  width={{ base: "100%", md: "32%" }}
                >
                  <Select
                    id='country'
                 
                    fontSize='sm'

                    placeholder='Ülke Seçiniz'
                    mb='24px'
                    size='lg'
                    variant='auth'
                    isReadOnly = {true}

                  >
                    {countries.map(options => (
                      <option key={options.code} value={options.code} selected= {options.code == '90' ? true : false}>{options.name}</option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup
                  width={{ base: "100%", md: "32%" }}
                >
                  <Select
                    id='country'
                    isRequired={true}
                    fontSize='sm'

                    placeholder='Şehir Seçiniz'
                    mb='24px'
                    size='lg'
                    variant='auth'

                    onChange={handleCityChange}
                  >
                    {CountryandCities.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup
                  width={{ base: "100%", md: "32%" }}
                >

                  <Select
                    id='city'
                    isRequired={true}
                    fontSize='sm'
                    placeholder='İlçe Seçiniz'
                    mb='24px'
                    size='lg'
                    variant='auth'

                    onChange={handleDistrictChange}
                  >
                    {selectedCountry && selectedCountry.districts.map(district => (
                      <option key={district} value={district}>{district}</option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup
                  width={"100%"}

                >
                  <Textarea
                    placeholder='Adres'
                    size='sm'
                    borderRadius={'16px'}
                    className="chakra-input css-rpu85t"
                    resize={'none'}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </InputGroup>
              </Flex>
              <Flex
                display={{ base: "block", md: "flex" }}
                flexWrap={'wrap'}
                columnGap={'10px'}
              >
                <InputGroup
                  width={{ base: "100%", md: "49%" }}
                >
                  <InputLeftElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={MdLock}
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Şifre'
                    mb='24px'
                    size='lg'
                    type={"password"}
                    variant='auth'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <InputGroup
                  width={{ base: "100%", md: "49%" }}
                >
                  <InputLeftElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={MdLock}
                    />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Şifre Tekrar'
                    mb='24px'
                    size='lg'
                    type={"password"}
                    variant='auth'
                    value={passwordReTyped}
                    onChange={(e) => setPasswordReTyped(e.target.value)}
                  />
                </InputGroup>
              </Flex>



            </FormControl>

          </ModalBody>
          <ModalFooter columnGap={'10px'}>
            <Button
              colorScheme="teal"
              onClick={handleRegister}
            >
              Kullanıcı Oluştur
            </Button>
            <Button colorScheme="orange" onClick={onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
