/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import config from 'config';
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Image,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  createStandaloneToast,
} from "@chakra-ui/react";
// Custom components
import AuthService from "Services/AuthService/AuthService";
import DefaultAuth from "layouts/auth/Default";
import { useTranslation } from 'react-i18next';
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import logoBlack from "assets/img/layout/parstaksilogo.png";
function SignIn() {

  const authService = new AuthService();
  const [userIdentifier, setUserIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const history = useHistory();
  const toast = createStandaloneToast();

  const isEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value) => {
  // Telefon numarası formatını kontrol eden regex
  const phoneRegex = /^\+?(\d.*){3,}$/;
  // Sadece rakam içerip içermediğini kontrol eden regex
  const digitsOnlyRegex = /^[\d\s+\-()]+$/;
  
  // Her iki koşulu da kontrol ediyoruz
  return phoneRegex.test(value) && digitsOnlyRegex.test(value);
  };
  useEffect(() => {
    if (config.UpdateMode) {
      history.push('/auth/update');
    }
    if (authService.isAuthenticated() && userData != null) {
      if (userData.Role == 1) {
        history.push('/admin/home');
      }
      else if (userData.Role == 8) {
        history.push('/user/home');
      }
      else if (userData.Role == 2) {
        history.push('/cityadmin/home');
      }
      else if (userData.Role == 4) {
        history.push('/dealer/home');
      }
      else if (userData.Role == 32) {
        history.push('/station/home');
      }
      else if (userData.Role == 128) {
        history.push('/newscenter/home');
      }
    }
    // else{
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('expireTime');
    //   localStorage.removeItem('UserData');
    // }
    const rememberedUsername = localStorage.getItem('rememberedUsername');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    const remembered = localStorage.getItem('rememberMe');
    if (remembered == 'true') {
      if (rememberedUsername) setUserIdentifier(rememberedUsername);
      if (rememberedPassword) setPassword(rememberedPassword);
      setRememberMe(JSON.parse(remembered)); // localStorage'dan alınan veri string olduğu için JSON.parse kullanarak boolean değere dönüştürüyoruz
    }
    console.log(remembered);

  }, []);

  const handleLogin = async () => {
    let inputType = '';
    if (isEmail(userIdentifier)) {
      inputType = 'Email';
    } else if (isPhoneNumber(userIdentifier)) {
      inputType = 'Phone'
    } else {
      inputType = 'Username'
    }
    // Giriş işlemi için API'ye istek gönder
    try {
      const response = await fetch(`${config.baseURL}/api/user/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "UserIdentifier": userIdentifier, "password": password, "AuthType": inputType }),
      });
      const data = await response.json();
      console.log(data)
      if (response.ok && data.IsSuccess) {
        authService.login(data.Data);
        localStorage.setItem('rememberedUsername', userIdentifier);
        localStorage.setItem('rememberedPassword', password);
        localStorage.setItem('rememberMe', rememberMe);
        handleClickToast("top-right", "success", t('successlogin'))
        // Yönlendirme işlemi

        if (data.Data.Role == 1) {
          history.push('/admin/home');
        }
        else if (data.Data.Role == 8) {
          history.push('/user/home');
        }
        else if (data.Data.Role == 2) {
          history.push('/cityadmin/home');
        }
        else if (data.Data.Role == 4) {
          history.push('/dealer/home');
        }
        else if (data.Data.Role == 32) {
          history.push('/station/home');
        }
        else if (data.Data.Role == 128) {
          history.push('/newscenter/home');
        }
      }
      else {
        // Giriş başarısız
        handleClickToast("top-right", "error", t('usernameorpasserror'))
      }
    }
    catch {
      handleClickToast("top-right", "error", t('errormessage1'))
    }

  };
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const { t, i18n } = useTranslation();
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClickToast = (position, status, message) => {
    toast({
      description: message,
      status: status,
      duration: 3000,
      position: position,
      isClosable: true,
    });
  };
  return (

    <DefaultAuth illustrationBackground={illustration} image={illustration}>

      <Flex
        alignItems='center'
        justifyContent='center'
        w='100%'
        textAlign={'center'}
        display={{ base: "flex", md: "none" }}>
        <Image src={logoBlack} w='90%' />
      </Flex>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            {t('login')}
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            {t('EnterYourInformationtoLogin')}
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'

          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button> */}
          {/* <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              {t('username')}<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder={t('usernameplaceholder')}
              mb='24px'
              fontWeight='500'
              size='lg'
              value={userIdentifier}
              onChange={(e) => setUserIdentifier(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              {t('password')}<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder=''
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                  isChecked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}

                  fontSize='sm'>
                  {t('rememberme')}
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='150px'
                  textAlign={'right'}
                  fontWeight='500'>
                  {t('iforgotmypassword')}
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleLogin}
            >
              {t('login')}
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              {t('donthaveaccount')}
              <NavLink to='./register'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  {t('createnewaccount')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
