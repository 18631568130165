import {
    Avatar,
    Button,
    Flex,
    Icon,
    Box,
    SimpleGrid,
    Container,
    Text,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Divider
} from '@chakra-ui/react';
import {
    MdTimeline,
    MdMoreTime,
    MdDateRange,
    MdOutlineLocalTaxi,
    MdOutlineRoute,
    MdAddRoad ,
    MdTimer,
    MdCarRental ,
    MdOutlineAttachMoney ,
    MdLocationOn
} from "react-icons/md";
import moment from 'moment';
import 'moment/locale/tr'; 
import { LuClock4, LuClock12, LuClock9 } from "react-icons/lu";
import { BiSolidCoinStack, BiCoinStack, BiDetail } from "react-icons/bi";
import { FaCoins  } from "react-icons/fa6";
import { FaRoad } from "react-icons/fa6";
import React, { useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export function TotalTariffReports(props) {
    moment.locale('tr');
    const { rentCount, totalDistance, totalTime, totalPrice } = props;
    const duration = moment.duration(totalTime, 'seconds');

    // Eşik değeri
    const thresholdSeconds = 59;
  
    // Zaman aralığını belirli bir eşik değere göre biçimlendir
    const formattedDuration = duration.asSeconds() < thresholdSeconds ? `${duration.asSeconds()} saniye` : duration.humanize();
  
    return (
                        <Flex
                            w={{ sm: '100%', md: 'auto' }}
                            alignItems={{ base: 'flex-start', md: 'Center' }}
                            bg={'teal'}
                            p={{ sm: "15px", md: "15px 4rem 15px 4rem" }}
                            borderRadius="30px"
                            color={'white'}
                            my={'0.5rem'}
                            columnGap={'10px'}
                            flexDirection= 'row'
                            flexWrap={{ base: 'wrap', md: 'nowrap' }}
                            justifyContent={'space-between'}
                        >
                            <Flex  w={{ base: '100%', md: 'unset' }} height={{base:"50px", md:'unset'}} alignItems={'center'} columnGap={{ base: '5px', md: '10px' }}>
                                <Icon as={MdCarRental }width={{ base: '1.5rem', md: '2rem' }} height={{ base: '1.5rem', md: '2rem' }} />
                                <SimpleGrid columns={{ base: 2, md: 1 }} spacing={0} justifyContent={'space-between'}  w={'100%'} alignItems={'center'}>
                                    <Text as={'p'} m={'0'}>Kiralama Sayısı</Text>
                                    <Text fontSize={{ base: '1.2rem', md: '1.5rem' }} as={'b'} m={'0'}>{rentCount}</Text>
                                </SimpleGrid>

                            </Flex>
                            <Divider orientation='horizontal' width={'50%'} my={'2px'} display={{base:"block", md:'none'}}/>
                            <Flex  w={{ base: '100%', md: 'unset' }}  height={{base:"50px", md:'unset'}} alignItems={'center'} columnGap={{ base: '5px', md: '10px' }}>
                                <Icon as={MdAddRoad  } width={{ base: '1.5rem', md: '2rem' }} height={{ base: '1.5rem', md: '2rem' }}/>
                                <SimpleGrid columns={{ base: 2, md: 1 }} spacing={0} justifyContent={'space-between'}  w={'100%'} alignItems={'center'}>
                                    <Text as={'p'} m={'0'}>Toplam Kiralık Mesafe</Text>
                                    <Text fontSize={{ base: '1.2rem', md: '1.5rem' }} as={'b'} m={'0'}>{totalDistance / 1000} KM</Text>
                                </SimpleGrid>

                            </Flex>
                            <Divider orientation='horizontal' width={'50%'} my={'2px'} display={{base:"block", md:'none'}}/>
                            <Flex  w={{ base: '100%', md: 'unset' }}  height={{base:"50px", md:'unset'}} alignItems={'center'} columnGap={{ base: '5px', md: '10px' }}>
                                <Icon as={MdTimer  } width={{ base: '1.5rem', md: '2rem' }} height={{ base: '1.5rem', md: '2rem' }} />
                                <SimpleGrid columns={{ base: 2, md: 1 }} spacing={0} justifyContent={'space-between'}  w={'100%'} alignItems={'center'}>
                                    <Text as={'p'} m={'0'}>Toplam Kiralık Süre</Text>
                                    <Text fontSize={{ base: '1.2rem', md: '1.5rem' }} as={'b'} m={'0'}>{formattedDuration}</Text>
                                </SimpleGrid>

                            </Flex>
                            <Divider orientation='horizontal' width={'50%'} my={'2px'} display={{base:"block", md:'none'}}/>
                            <Flex alignItems={'center'}  height={{base:"50px", md:'unset'}} columnGap={{ base: '5px', md: '10px' }} w={{ base: '100%', md: 'unset' }}>
                                
                                <Icon as={FaCoins    } width={{ base: '1.5rem', md: '2rem' }} height={{ base: '1.5rem', md: '2rem' }} />
                                <SimpleGrid  columns={{ base: 2, md: 1 }} spacing={0} justifyContent={'space-between'}  w={'100%'} alignItems={'center'}>
                                    <Text as={'p'} m={'0'}>Toplam Ücret</Text>
                                    <Text fontSize={{ base: '1.2rem', md: '1.5rem' }} as={'b'} m={'0'}>{totalPrice.toFixed(2) } ₺</Text>
                                </SimpleGrid>

                            </Flex>
                        </Flex>
     
    );
}