import React, { useState,useEffect  } from 'react';
import QrScan from 'react-qr-reader'
import TTXVideoPlayer  from 'assets/Player/swfobject-all'
import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Icon,
    Spinner
} from "@chakra-ui/react";
export default function Test(props) {
    const params = {
        isVodMode: true,
        isVodAudio: false,
        singleHttpsPort: 443,
        lang: 'en'
      };
    return (
        <Box w={'100%'} marginTop={{ base: "80px", xl: "60px" }}>
              {/* <TTXVideoPlayer 
        domId="videoPlayer"
        width="640"
        height="480"
        params={params}
        playerType="auto"
      /> */}
        </Box>
    );
};