import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Divider,
  Input,
  Button,
  createStandaloneToast,
  FormLabel,
  Grid,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from 'react';
import { FilterBar } from "./components/FilterBar";
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { useTranslation } from 'react-i18next';
import MapComponent from "./components/MapComponent";
import {
  MdTimeline,
  MdDateRange,
  MdOutlineLocalTaxi,
  MdOutlineTaxiAlert,
  MdAttachMoney,
  MdStop,
  MdManageSearch
} from "react-icons/md";
import { TariffReportItem } from "./components/TariffReportItem";
import { TotalTariffReports } from "./components/TotalTariffReports";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import moment from 'moment';
import EngineSimulator from "components/enginesimulator/EngineSimulator";
import Card from "components/card/Card.js";
import { CitiesLocations } from "variables/CityLocations";
const taxiDeviceService = new TaxiDeviceService();
export default function LocationReports() {
  const [deviceID, setDeviceID] = useState('');
  const [locations, setLocations] = useState(null);
  const [gpsStatus, setGPSStatus] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [buttonstts, setButtonStatus] = useState(false);
  const [tripData, setTaxiTrips] = useState(null);
  const [requestTime, setTime] = useState(null);
  const [plate, setCarPlate] = useState('');
  const toast = createStandaloneToast();
  var logoUrl = `<img src="${logoWhite}"/>`
  moment.locale('tr');
  const userData = JSON.parse(localStorage.getItem('UserData'));
  var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
  const city = CitiesLocations[cityIndex];
  const { t, i18n } = useTranslation();
  const [position, setMapPosition] = useState(city);
  const fetchDataAndSetState = async () => {
    const now = new Date();
let timeepoch =Math.round(now.getTime() / 1000) ;

    var result = await taxiDeviceService.GetTaximeterInfoForTest(plate);
    const gps = moment(result.Data.LastLocation != null ? result.Data.LastLocation.LastReceivedTime: 0).unix();
    const connection = moment(result.Data.LastConnectionTime).unix();
    const status = moment(result.Data.Status != null ? result.Data.Status.Time : 0).unix();
    if (timeepoch - gps < 3600) {
      setGPSStatus(true)
    }
    else {
      setGPSStatus(false)
    }
    if (timeepoch - connection < 3600) {
      setConnectionStatus(true)
    }
    else {
      if (timeepoch - gps < 3600) {
        setConnectionStatus(true)
      }
      else if (timeepoch - status < 3600) {
        setConnectionStatus(true)
      }
      else {
        setConnectionStatus(false)
      }

    }
    var request = Date.now()
    setTime(request);
    setLocations(result.Data.LastLocation)
    setTaxiTrips(result.Data);
    console.log(tripData)


  }
  const handleClickToast = (position, status, message) => {
    toast({
      description: message,
      status: status,
      duration: 3000,
      position: position,
      isClosable: true,
    });
  };
  // var trips = tripData.map(o => o.Trips);
  // var totalDistance = 0;
  // var totalTime = 0;
  // var totalPrice = 0;
  // trips.forEach(item => {
  //   totalDistance += item[0].RentedTripDistance;
  //   totalPrice += item[0].RentedTripFare + item[0].RentedTripExtras;
  //   totalTime += item[0].EndOfRentedTrip - item[0].StartOfRentedTrip;
  // });

  const intervalRef = useRef(null);

  const startTimer = () => {
    setButtonStatus(true)
    if (plate != '') {
      if (intervalRef.current === null) {
        intervalRef.current = setInterval(() => {
          fetchDataAndSetState();
        }, 5000);
      }

    } else {

      handleClickToast("top", "warning", 'Lütfen Tüm Değerleri Doldurunuz!')
    }

  };

  const stopTimer = () => {
    setButtonStatus(false)
    setTime(null);
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Flex
        w={'100%'}
        columnGap={'1rem'}
        pos={'relative'}
        rowGap={'1rem'}
        flexWrap={'wrap'}>
        <Flex w={{ base: '100%', md: 'calc(40% - 0.5rem)' }} flexWrap={'wrap'} rowGap={'1rem'} >
          <Card order={{ base: 1, md: 'unset' }}>

            <Flex flexWrap={'wrap'} columnGap={'1rem'} rowGap={'1rem'} w={'100%'}>
              <NumberInput w={'100%'}>
                <NumberInputField  size="lg" placeholder={t('serialno')} disabled={buttonstts}  onInput={(e) => setCarPlate(e.target.value)} />
              </NumberInput>
              <Button disabled={buttonstts} isLoading={buttonstts} onClick={() => startTimer()} leftIcon={<Icon as={MdManageSearch} width='1.5rem' height='1.5rem' />} colorScheme='teal' variant='solid' w={'calc(50% - 0.5rem)'}>
                {t('testbtn')}
              </Button>
              <Button disabled={!buttonstts} onClick={() => stopTimer()} leftIcon={<Icon as={MdStop} width='1.5rem' height='1.5rem' />} colorScheme='red' variant='solid' w={'calc(50% - 0.5rem)'}>
              {t('stop')}
              </Button>
              {requestTime != null ? <Text as={'b'} w={'100%'}>  {t('lastrequesttime')}: {moment(requestTime).format('DD.MM.YYYY HH:mm:ss')}</Text> : ''}
            </Flex>
          </Card>
          <Card order={{ base: 3, md: 'unset' }}>
            <FormLabel>   {t('ignitioninfo')}</FormLabel>
            <Divider />


            <Grid templateColumns={'4fr 1fr'} gap={4} alignItems={'center'}>
              <SimpleGrid margin={'10px 0'}>

                <Text><b>{t('ignitionstatus')}:</b> {tripData != null && tripData.LastIgnitionRecords.length > 0 ? tripData.LastIgnitionRecords[0].CurrentIgnition ? t('ignitionon') : t('ignitionoff')  : '----'}</Text>
                <Text><b>{t('lastignitontime')}:</b> {tripData != null && tripData.LastIgnitionRecords.length > 0 ? moment.unix(tripData.LastIgnitionRecords[0].TimeRealUTC).format('DD.MM.YYYY HH:mm') : '----'}</Text>
              </SimpleGrid>
              <Flex h={'40px'}
                w={'40px'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'50%'}
                boxShadow={'0 1px 4px rgba(0, 0, 0, 0.5)'}
                color={'white'}
                fontSize={'0.8rem'}
                bg={tripData != null && tripData.LastIgnitionRecords.length > 0 ? tripData.LastIgnitionRecords[0].CurrentIgnition ? 'forestgreen' : 'crimson' : 'lightgray'}>
                {tripData != null && tripData.LastIgnitionRecords.length > 0 ? tripData.LastIgnitionRecords[0].CurrentIgnition ? 'On' : 'Off' : '-'}

              </Flex>
            </Grid>



            <Text as={'b'}>{t('ignitionhistory')}({t('last')} 10)</Text>
            <SimpleGrid h={{ base: '20vh', md: '40vh' }} border={'1px solid lightgray'} borderRadius={'0.825rem'} overflowY={'auto'} gap={4} p={'10px'}>
              {tripData != null ? tripData.LastIgnitionRecords.map(item => {
                return <Flex
                  justifyContent={'space-between'}
                  border={'1px solid lightgray'}
                  alignItems={'center'}
                  padding={'10px'}
                  height={'40px'}
                  borderRadius={'0.825rem'}>
                  <Flex h={'20px'}
                    w={'20px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    borderRadius={'50%'}
                    boxShadow={'0 1px 4px rgba(0, 0, 0, 0.5)'}
                    color={'white'}
                    fontSize={'0.8rem'}
                    bg={item.CurrentIgnition ? 'forestgreen' : 'crimson'}>

                  </Flex>
                  <Text> {t(item.CurrentIgnition ? 'ignitionon' : 'ignitionoff')}</Text>
                  <Text>{moment.unix(item.TimeRealUTC).format('DD.MM.YYYY HH:mm')}</Text>

                </Flex>
              }

              ) : t('datanotfound')}

            </SimpleGrid>
          </Card>
        </Flex>
        <Flex w={{ base: '100%', md: 'calc(60% - 0.5rem)' }} flexWrap={'wrap'} rowGap={'1rem'}>
          <Card order={{ base: 4, md: 'unset' }}>
            <FormLabel>{t('instantlocation')}</FormLabel>
            <MapComponent zoomLevel={13} centerCoordinates={position} logoUrl={logoUrl} location={locations}></MapComponent>
          </Card>
          <Card order={{ base: 2, md: 'unset' }}>
            <FormLabel>{t('speedandconnectioninfo')}</FormLabel>

            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4} justifyContent={{ base: 'center', md: 'unset' }}>
              <SimpleGrid gap={1}>
                {tripData != null ?
                  <Flex w={'100%'} flexWrap={'wrap'} columnGap={'1rem'}>
                    <Flex
                      boxShadow={'0 1px 4px rgba(0, 0, 0, 0.5)'}
                      padding={'0.5rem 1rem'}
                      borderRadius={'0.825rem'}
                      color={'white'}
                      bg={gpsStatus ? 'forestgreen' : 'crimson'}>
                      {t(gpsStatus ? 'gpsOn' : 'gpsOff')}</Flex>
                    <Flex
                      boxShadow={'0 1px 4px rgba(0, 0, 0, 0.5)'}
                      padding={'0.5rem 1rem'}
                      borderRadius={'0.825rem'}
                      color={'white'}
                      bg={connectionStatus ? 'forestgreen' : 'crimson'}>
                      {t(connectionStatus ? 'connectionOn' : 'connectionOff')}
                    </Flex>
                  </Flex> : ''}
                <Text><b>{t('taximeterstatus')}:</b>  {tripData != null && tripData.Status !=null ? t(tripData.Status.Status) : '----'}</Text>
                <Text><b>{t('version')}:</b>  {tripData != null ? t(tripData.Version) : '----'}</Text>
                <Text><b>{t('imeino')}:</b>  {tripData != null && tripData.IMEI ? tripData.IMEI.IMEI : '----'}</Text>
                <Text><b>{t('phonenumber')}:</b>  {tripData != null && tripData.GSMNumber != null ? tripData.GSMNumber.Number : '----'}</Text>
                {/* <Text><b>Anlık Adres:</b> {'Kocaeli'}</Text> */}
                <Text><b>{t('laslocationtime')}:</b> {tripData != null && tripData.LastLocation != null ? moment(tripData.LastLocation.LastReceivedTime).format('DD.MM.YYYY HH:mm') : '----'}</Text>
                <Text><b>{t('laststatustime')}:</b> {tripData != null && tripData.Status !=null? moment(tripData.Status.Time).format('DD.MM.YYYY HH:mm') : '----'}</Text>
                <Text><b>{t('lastconnectiontime')}:</b> {tripData != null ? moment(tripData.LastConnectionTime).format('DD.MM.YYYY HH:mm') : '----'}</Text>
              </SimpleGrid>


              <EngineSimulator speeds={locations} />


            </Grid>



          </Card>

        </Flex>

      </Flex>


    </Box>

  );
}