import {
    Flex,
    Table,
    Progress,
    Grid,
    Button,
    Icon,
    Tbody,
    Td,
    Text,
    Th,
    Input,
    Select,
    Divider,
    FormControl,
    Thead,
    Tr,
    FormLabel,
    InputGroup,
    Checkbox, CheckboxGroup,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,

} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import moment from 'moment';
import 'moment/locale/tr';
import UserService from "Services/UserService/UserService";
import { CountryandCities } from "../variables/CityData";
import { countries } from "variables/Country";
// Custom components
import Card from "components/card/Card";
import { CitiesLocations } from "variables/CityLocations";
import Roles from "variables/Roles";
import Permissions from "variables/Permissions";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdEdit, MdFormatListBulleted, MdOutlineInfo, MdOutlineLocalTaxi } from "react-icons/md";
import CityAdminService from "Services/CityAdminService/CityAdminService";
import { useTranslation } from 'react-i18next';

const cityAdminService = new CityAdminService();

const userService = new UserService();

export default function UserEditForm({ selectedUser, edit, edittedUserModel }) {
    var str = CitiesLocations[parseInt(selectedUser.CityCode) - 1].CityName.toLowerCase();
    const userCity = str.charAt(0).toUpperCase() + str.slice(1);
    const selected = CountryandCities.find(option => option.value === userCity);
    const [rolePermissionist, setRolePermissionList] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);
    const [email, setEmail] = useState('');
    const [userNameGenerated, setUserNames] = useState('');
    const [password, setPassword] = useState('');
    const [passwordReTyped, setPasswordReTyped] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('90');
    const [addressed, setAddress] = useState('');
    const [changepass, setChangePassword] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const { t, i18n } = useTranslation();
    var userPermissions = [];
    const handleCityChange = (event) => {
        const selectedOption = CountryandCities.find(option => option.value.toLowerCase() === event.toLowerCase());
        console.log(selectedOption.value)
        setSelectedCountry(selectedOption);
        setCountry(selectedOption.value);
        setCity(null); // İl değiştiğinde ilçeyi sıfırla
    };
    const handleDistrictChange = (event) => {
        const selectedOption = event.target.value;
        setCity(selectedOption);
    };

    useEffect(() => {
        if (!edit) {
            handleCityChange(userCity);
            var perm = [];
            permissionsList.forEach(e => {
                perm.push(Permissions[e]);
            }
            )
            var model = JSON.stringify(
                {
                    "UserId": selectedUser.UserId,
                    "UserName": userNameGenerated,
                    "Name": name,
                    "Surname": surname,
                    "Phone": phone,
                    "Email": email,
                    "Password": password,
                    "Role": Roles[role],
                    "Permissions": perm,
                    "PasswordRetyped": passwordReTyped,
                    "ChangePassword": changepass,
                    "CityCode": country,
                    "District": city != null ? city : selectedUser.District,
                    "CountryCode": countryCode,
                    "Address": addressed
                }

            );
            edittedUserModel(model);
        }

    }, [userNameGenerated, name, surname, phone, email, role, permissionsList, edit, password, passwordReTyped,addressed]);
    useEffect(() => {
        const fetchTaxiOwnerPermissions = async () => {
            setEmail(selectedUser.Email);
            setUserNames(selectedUser.UserName);
            setPassword('');
            setPasswordReTyped('');
            setPhone(selectedUser.Phone);
            setName(selectedUser.Name);
            setSurname(selectedUser.Surname)
            setCountry(CitiesLocations.filter(f => f.plaka == selectedUser.CityCode)[0].CityName);
            setAddress(selectedUser.Address);
            setCountryCode(countries.filter(f => f.code == selectedUser.Country)[0].name);
            setRole(selectedUser.Role)
            setCity(selectedUser.District)
            var result = await userService.TaxiOwnerPermissions();
            setRolePermissionList(result.Data)
            userPermissions = result.Data;
            setPermissionsList(selectedUser.Permissions)
        };
        fetchTaxiOwnerPermissions()
    }, []);
    console.log(userPermissions)
    const handlePermChange = (e) => {
        var value = parseInt(e);
        if (permissionsList.includes(value)) {
            setPermissionsList((permissons) =>
                permissons.filter(item => item !== value)
            );
        }
        else {
            setPermissionsList((permissons) => [
                ...permissons,
                value,
            ]);
        }


    }
    return (
        <>
            {selectedUser ? <Flex >
                <Card >
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                        <Text as={'b'} fontSize={'md'}>{t('userdetails')}</Text>
                        <Text>{t('dateofregistration')}: {moment(selectedUser.CreatedAt).format('DD.MM.YYYY HH:mm')}</Text>
                    </Flex>

                    <Divider my={'5px'} />
                    <FormControl>
                        <Flex
                            width={"100%"}
                            columnGap={'10px'}
                            rowGap={'16px'}
                            justifyContent={'center'}
                            flexWrap={'wrap'}
                        >
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('name')}:
                                </FormLabel>
                                <Input
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('name')}
                                    onChange={(e) => setName(e.target.value)}
                                    fontWeight='500'
                                    size='md'
                                    variant='auth'
                                    value={name}
                                    disabled={edit}
                                />
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'

                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('surname')}:
                                </FormLabel>
                                <Input
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('surname')}
                                    variant='auth'
                                    onChange={(e) => setSurname(e.target.value)}
                                    fontWeight='500'
                                    size='md'
                                    value={surname}
                                    disabled={edit}
                                />
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('username')}:
                                </FormLabel>
                                <Input
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('username')}
                                    onChange={(e) => setUserNames(e.target.value)}
                                    variant='auth'
                                    fontWeight='500'
                                    size='md'
                                    value={userNameGenerated}
                                    disabled={edit}
                                />
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('email')}:
                                </FormLabel>
                                <Input
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('email')}
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant='auth'
                                    fontWeight='500'
                                    size='md'
                                    value={email}
                                    disabled={edit}
                                />
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('phone')}:
                                </FormLabel>
                                <Input
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('phone')}
                                    onChange={(e) => setPhone(e.target.value)}
                                    variant='auth'
                                    fontWeight='500'
                                    size='md'
                                    value={phone}
                                    disabled={edit}
                                />
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('role')}:
                                </FormLabel>
                                <Select
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    type='text'
                                    placeholder={t('role')}
                                    onChange={(e) => setRole(e.target.value)}
                                    variant='auth'
                                    fontWeight='500'
                                    size='md'
                                    value={role}
                                    disabled={edit}
                                >
                                    {Object.keys(Roles).map(key => (
                                        <option value={key}>{t(Roles[key])}</option>
                                    ))}
                                </Select>
                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "calc(90% + 20px)" }} >
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('address')}:
                                </FormLabel>
                                <Input
                                    id='addressesss'
                                    w={{ base: "100%" }}
                                    fontSize='sm'
                                    disabled={edit}
                                    placeholder={t('address')}
                                    onChange={(e) => setAddress(e.target.value)}
                                    size='md'
                                    variant='auth'
                                    type="text"
                                    value={addressed}

                                />

                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('country')}:
                                </FormLabel>
                                <Input
                                    id='country'
                                    fontSize='sm'
                                    placeholder={t('country')}
                                    disabled={true}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    size='md'
                                    value={countryCode}
                                    variant='auth'

                                />

                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('city')}:
                                </FormLabel>
                                <Input
                                    id='city'

                                    fontSize='sm'
                                    disabled={true}
                                    placeholder={t('city')}
                                    onChange={(e) => setCountry(e.target.value)}
                                    size='md'
                                    variant='auth'

                                    value={country}

                                />

                            </Flex>
                            <Flex flexDirection={'column'} width={{ base: "100%", md: "30%" }}>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    mb='8px'>
                                    {t('district')}: <b> {city}</b>
                                </FormLabel>
                                <Select
                                    id='city'
                                    isRequired={true}
                                    fontSize='sm'
                                    placeholder={t('selectdistrict')}
                                    mb='24px'
                                    size='md'
                                    variant='auth'
                                    disabled={edit}
                                    value={selectedUser.District}
                                    onChange={handleDistrictChange}
                                >
                                    {selectedCountry && selectedCountry.districts.map(district => (
                                        <option key={district} value={district} >{district}</option>
                                    ))}
                                </Select>

                            </Flex>
                  
                            <Flex flexWrap={'wrap'} columnGap={'20px'} width={{ base: "100%", md: "calc(90% + 20px)" }} rowGap={'5px'}>
                                <Button w={{ base: "100%", md: "30%" }} disabled={edit} colorScheme="facebook" onClick={() => setChangePassword(!changepass)}>{t('changepassword')}</Button>
                                {changepass && <Flex flexWrap={'wrap'} w={{ base: "100%", md: "60%" }} columnGap={'10px'} rowGap={'5px'}>
                                    <Input
                                        id='pass'
                                        w={{ base: "100%", md: "calc(50% - 5px)" }}
                                        fontSize='sm'
                                        disabled={edit}
                                        placeholder={t('password')}
                                        onChange={(e) => setPassword(e.target.value)}
                                        size='md'
                                        variant='auth'
                                        type="password"
                                        value={password}

                                    />
                                    <Input
                                        id='passretry'
                                        w={{ base: "100%", md: "calc(50% - 5px)" }}
                                        fontSize='sm'
                                        disabled={edit}
                                        placeholder={t('passwordretry')}
                                        onChange={(e) => setPasswordReTyped(e.target.value)}
                                        size='md'
                                        variant='auth'
                                        type="password"
                                        value={passwordReTyped}

                                    /></Flex>}
                            </Flex>
                            <Flex flexDirection={'column'} width={"calc(90% + 20px)"}>
                                <Accordion allowToggle>
                                    <AccordionItem borderTop={'0'}>
                                        <AccordionButton padding={'0'}>
                                            <FormLabel
                                                display='flex'
                                                w={'100%'}
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                mb='8px'>
                                                {t('userpermissions')}:
                                            </FormLabel>
                                            <AccordionIcon />
                                        </AccordionButton>

                                        <AccordionPanel pb={{ base: 0, md: 4 }}>
                                            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={2} width={'100%'} >
                                                {Object.keys(Permissions).map(key => {
                                                    if (rolePermissionist.includes(parseInt(key))) {
                                                        return <Checkbox onChange={() => handlePermChange(key)} disabled={edit} size='sm' defaultChecked={selectedUser.Permissions.includes(parseInt(key)) ? true : false}>{t(Permissions[key])}</Checkbox>
                                                    }
                                                })}
                                            </Grid>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Flex>
                        </Flex>

                    </FormControl>
                </Card>
            </Flex> : ''}
        </>)


}