import React, { useState,useEffect  } from 'react';
import QrScan from 'react-qr-reader'
import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Icon,
    Spinner
} from "@chakra-ui/react";
export default function Test(props) {
    const [qrscan, setQrscan] = useState('No result');
    const [errors, setErrors] = useState('');
    const [capabilitiess, setCapabilities] = useState('');
    const [settingss, setSettings] = useState('');
    const handleScan = data => {
        if (data) {
            setQrscan(data)
        }
    }
    const handleError = err => {
    console.error(err)
    }
    let set;
    useEffect(() => {
        const getMediaStream = async () => {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    
            const track = stream.getVideoTracks()[0];
            const capabilities = await track.getCapabilities();
            const settings = await track.getSettings();
    set = settings;
            setCapabilities('Kamera yetenekleri:', capabilities  );
            setSettings('Mevcut ayarlar:', settings);
    
            // Örneğin, autofocus özelliğini true yaparak otomatik odaklamayı etkinleştirebilirsiniz
            //  track.applyConstraints({ advanced: [{ autoFocus: true }] });
          } catch (error) {
            setErrors('Kamera erişimi hatası:', error);
          }
        };
    
        getMediaStream();
      }, []);
    return (
        <Box w={'100%'} marginTop={{ base: "80px", xl: "60px" }}>


           
                <QrScan
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ height: 'auto', width: 320 }}
                />
      
        
            <Text>{qrscan}</Text>
            <Text>{errors}</Text>
            <Text>{capabilitiess}</Text>
            <Text>{settingss}</Text>
            <Text>{set}</Text>
        </Box>
    );
};