import {
  Avatar,
  Button,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Text,
  Input,
  Grid
} from '@chakra-ui/react';
import {
  MdTimeline,
  MdDateRange,
  MdOutlineLocalTaxi,
  MdOutlineTaxiAlert,
  MdAttachMoney,
  MdManageSearch
} from "react-icons/md";

import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import { SelectPicker, Stack } from 'rsuite';
import 'react-datepicker/dist/react-datepicker.css';
const taxiDeviceService = new TaxiDeviceService();
export function FilterBar({ onFilterChange, buttonStatus }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [plate, setCarPlate] = useState('');
  const [loading, setButtonLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const [taxiPlates, setTaxiPlates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      var result = await taxiDeviceService.GetCityDeviceLocation(userData.CityCode);
      var c = result.Taximeters.map(item => ({ label: item.Plate, value: item.DeviceId }));
      setTaxiPlates(c);
    }

    fetchDataAndSetState();
  }, []);
  useEffect(() => {
    setButtonLoading(buttonStatus)
  }, [buttonStatus]);
  console.log(taxiPlates)
  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ background: "#fff", color: "#000", borderRadius: " 0.375rem" }}>
        <CalendarContainer style={{ borderRadius: " 0.375rem" }}>
          <div style={{ background: "#f0f0f0", padding: "8px", textAlign: "center", borderRadius: " 0.375rem 0.375rem 0 0" }}>
            Görüntülemek istediğiniz tarih aralığını seçiniz.
          </div>
          <div style={{ position: "relative", borderRadius: " 0 0 0.375rem 0.375rem " }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const handleFormValues = () => {
    // Değerleri ana bileşene iletmek için prop olarak geçirilen fonksiyonu çağırın
    setButtonLoading(true)
    onFilterChange({ 'DeviceId': plate });
  };
  return (

    <Flex
      rowGap={'1rem'}
      columnGap={'1.2rem'}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="flex-end "
      flexDirection="row"
      bg={'white'}
      flexWrap={{ base: 'wrap', md: 'wrap' }}
      p="15px 30px"
      borderRadius="30px"
    >
      <FormControl w={'100%'}>
        <FormLabel htmlFor='country'>Cihaz Seri Numarası*</FormLabel>
        <Flex
          w={'100%'}
          alignItems={'center'}
          columnGap={'5px'}

        >
          <Icon as={MdOutlineLocalTaxi} width='2.5rem' height='2.5rem' color='white' background={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'} p={'8px'} borderRadius={'50%'} />
          <Input style={{ width: '100%', fontSize: '1rem' }} size="lg" placeholder="Cihaz Seri Numarası" onInput={(e) => setCarPlate(e.target.value)} />
        </Flex>

      </FormControl>

      <Flex flexWrap={'wrap'} columnGap={'1rem'} w={'100%'}>
        <Button disabled={loading}  onClick={handleFormValues} leftIcon={<Icon as={MdManageSearch} width='1.5rem' height='1.5rem' />} colorScheme='teal' variant='solid'w={'calc(50% - 0.5rem)'}>
          Test
        </Button>
        <Button isLoading={loading} loadingText='Veriler Getiriliyor' onClick={handleFormValues} leftIcon={<Icon as={MdManageSearch} width='1.5rem' height='1.5rem' />} colorScheme='red' variant='solid' w={'calc(50% - 0.5rem)'}>
          Durdur
        </Button>
      </Flex>

    </Flex>


  );
}