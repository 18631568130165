import React, { Component } from 'react';
import axios from 'axios';
import config from 'config';

class CityAdminService {
  constructor() {

  }
  async AddUserToSystem(model) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    // Giriş işlemi için API'ye istek gönder
    const response = await fetch(config.baseURL + config.endpoints.AddUserToSystemForCityAdmin, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: model ,
      });
    const data = await response.json();

        return data;
 
}
async EditUser(model) {
  const token = JSON.parse(localStorage.getItem('authToken')).Token;
  // Giriş işlemi için API'ye istek gönder
  const response = await fetch(config.baseURL + config.endpoints.EditUserForCityAdmin, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: model ,
    });
  const data = await response.json();

      return data;

}
async CreateStationToSystem(model) {
  const token = JSON.parse(localStorage.getItem('authToken')).Token;
  // Giriş işlemi için API'ye istek gönder
  const response = await fetch(config.baseURL + config.endpoints.CreateStationForCityAdmin, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: model ,
    });
  const data = await response.json();

      return data;

}
  async AssignDevicesToUser(DeviceIds, UserId, force) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.AssignTaximetersToUser}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceIDs": DeviceIds, "UserId": UserId, "Force": force }),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data;
    } else {
      return data;

    }
  }
  async UnAssignDevicesToUser(DeviceIds, UserId) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.RemoveTaximeterAssignmentsFromUser}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceIDs": DeviceIds, "UserId": UserId, "Force": false }),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data;
    } else {
      return data;

    }
  }
  async AssignDevicesToStation(DeviceIds, UserId) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.AssignTaximetersToStation}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceIDs": DeviceIds, "StationId": UserId}),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data;
    } else {
      return data;

    }
  }
  async UnAssignDevicesToStation(DeviceIds, UserId) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.RemoveTaximeterAssignmentsFromStation}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceIDs": DeviceIds, "StationId": UserId}),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data;
    } else {
      return data;

    }
  }

}

export default CityAdminService;
