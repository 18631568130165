// Chakra imports
import config from 'config';
import { Avatar, Box, Flex, Text, useColorModeValue, Spinner } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";

export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const [userData, setUserData] = useState(null);
  const userEmail = localStorage.getItem('rememberedUsername')
  const apiUrl = `${config.baseURL}/api/user/profile?email=${encodeURIComponent(userEmail)}`;
  const token = JSON.parse( localStorage.getItem('authToken')).Token;

  useEffect(() => {
    fetch(apiUrl, {
      method: 'POST', // GET, POST, PUT, DELETE, vb.
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Diğer isteğe bağlı başlıklar
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Global değişkene atama yapın

        setUserData(data.Data);
      })
      .catch(error => {
        console.error('Fetch error:', error.message);
      });
  }, []);
  if (!userData) {
    return (
      <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Flex>
      </Card>
    )
  }
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        name={userData.Name}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
      {userData.Name} {userData.Surname}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
      {userData.Email}
      </Text>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Araç
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Aylık Kazanç
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
