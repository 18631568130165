import {
    Avatar,
    Button,
    Flex,
    Icon,
    Box,
    SimpleGrid,
    Container,
    Text,
    Spinner,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import {
    MdTimeline,
    MdMoreTime,
    MdDateRange,
    MdOutlineLocalTaxi,
    MdOutlineRoute,
    MdMap,
    MdTimer,
    MdSyncLock,
    MdOutlineLocationOn,
    MdLocationOn
} from "react-icons/md";
import moment from 'moment';
import 'moment/locale/tr';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { LuClock4, LuClock12, LuClock9 } from "react-icons/lu";
import { BiSolidCoinStack, BiCoinStack, BiDetail } from "react-icons/bi";
import { FaRoad } from "react-icons/fa6";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import ModalMap from './MapModal';
import 'react-datepicker/dist/react-datepicker.css';
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
function GetAddressData(lat, lon) {
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Adres bilgisini almak için uygun olan property'yi seçin

            const address = data.error ? '--------------------' : data.display_name;
            console.log(address)
            return address;
        })
        .catch(error => {
            console.error('There was a problem with the reverse geocoding operation:', error);
            return '-----';
            throw error;
        });


}
function CustomButton({ param, adress, deviceID,tripID }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    var logoUrl = `<img src="${logoWhite}"/>`
    const [position, setMapPosition] = useState([40.762200, 29.940733]);
    const [locations, setLocations] = useState([]);
    const [params, setParam] = useState({});
    const [addressName, setAddressName] = useState({});
    const { t, i18n } = useTranslation();

    const handleClick = async () => {
        setParam(param);
        setAddressName(adress);
        onOpen();
        var result = await taxiDeviceService.GetTripsLocations(deviceID, tripID);
        setLocations(result) //Lokasyon Verileri Alınıyor.
        console.log(locations);
      };
    return (
        <>
            <Button
isLoading={adress != null ? false : true}
                colorScheme='teal'
                variant='solid'
                height={'60px'}
                w={{ base: '100%', md: '8rem' }}
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                textAlign={'center'}
                onClick={handleClick}
            >
                <Icon as={MdMap} width='1.5rem' height='1.5rem' />
            {t('showonmap')}
            </Button>
            <Modal size={'full'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='none'
                    backdropFilter='blur(10px) ' />
                <ModalContent backgroundColor={'rgba(0,0,0,0.2)'}>
                <ModalFooter>
                        <Button onClick={onClose}>
                            {t("close")}
                        </Button>
                    </ModalFooter>

                    <ModalBody>
                        <Flex width={'100%'} columnGap={'10px'} rowGap={'10px'} mt={'2rem'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                            <Flex width={{ base: "100%", xl: "70%" }} borderRadius="10px">
                                <ModalMap zoomLevel={13} centerCoordinates={position} logoUrl={logoUrl} locations={locations} timeSpan={params.RentedEndTimeUTC-params.RentedStartTimeUTC}></ModalMap>
                            </Flex>
                            <Flex
                                width={{ base: "100%", xl: "30%" }}
                                justifyContent={'start'}
                                bg={'white'}
                                p={{ sm: "15px", md: "15px 20px" }}
                                borderRadius="10px"
                                color={'teal'}
                                columnGap={'10px'}
                                flexDirection={'column'}

                            >
                                <Text as={'b'} fontSize={'1.2em'} >{params.RentedStartTime} {t('datedrentalmapview')}</Text>
                                <Flex justifyContent={'space-between'} my={'10px'}>
                                    <Text ><b>{t('starttime')} : </b> <span style={{color:"gray"}}>{moment.unix(params.RentedStartTimeUTC).format('HH : mm')}</span></Text>
                                    <Text ><b>{t('endtime')} : </b> <span style={{color:"gray"}}>{moment.unix(params.RentedEndTimeUTC).format('HH : mm')}</span></Text>
                                    <Text ><b>Süre : </b> <span style={{color:"gray"}}>{params.RentedTime}</span> </Text>
                                </Flex>

                                <Text ><b>  {t("startaddress")} : </b><span style={{color:"gray"}}>{addressName.RentedStartLocation }</span> </Text>
                                <Text ><b>  {t("endaddress")} : </b><span style={{color:"gray"}}>{addressName.RentedEndLocation }</span> </Text>
                            </Flex>

                        </Flex>


                    </ModalBody>
   
                </ModalContent>
            </Modal>
        </>
    );
}
export function TariffReportItem(props) {
    moment.locale('tr');
    const fetchDataAndSetStateTime = (totalTime) => {
        const duration = moment.duration(totalTime, 'seconds');
        // Eşik değeri
        const thresholdSeconds = 59;
        // Zaman aralığını belirli bir eşik değere göre biçimlendir
        const formattedDuration = duration.asSeconds() < thresholdSeconds ? `${duration.asSeconds()} saniye` : duration.humanize();
        return formattedDuration;
    }
    const { t, i18n } = useTranslation();
    const { trips } = props;
    const [tripsInfo, setTripsInfo] = useState([]);
    const [addresses, setAddresses] = useState(null);
    const [showAdress, setShowAdress] = useState(false);
    const GetAddresses = async (index) => {
        setAddresses(null)
        const RentedStartLocation = await GetAddressData(trips[index].StartLocationLatitude, trips[index].StartLocationLongitude);
        const RentedEndLocation = await GetAddressData(trips[index].EndLocationLatitude, trips[index].EndLocationLongitude);
        const adres = {
            'RentedStartLocation': RentedStartLocation,
            'RentedEndLocation': RentedEndLocation,
        }
        setAddresses(adres);
        setShowAdress(true)
        console.log(addresses)
    }
    useEffect(() => {
        // Array içindeki her bir öğe için reverse geocoding yap
        const updateAddresses = async () => {
            const addressObjects = trips.map((trip, index) => (
                {
                    'RentedDate': moment.unix(trip.StartOfRentedTripUTC).format('DD.MM.YYYY'),
                    'RentedFare':(trip.RentedTripFare / 1000).toFixed(2),
                    'RentedExtras': (trip.RentedTripExtras / 1000).toFixed(2),
                    'Index': index,
                    'TripID': trip.TripID,
                    'DeviceID': trip.DeviceID,
                    'RentedStartTimeUTC': trip.StartOfRentedTripUTC,
                    'RentedEndTimeUTC': trip.EndOfRentedTripUTC,
                    'RentedStartTime': moment.unix(trip.StartOfRentedTripUTC).format('DD.MM.YYYY HH:mm.ss'),
                    'RentedEndTime': moment.unix(trip.EndOfRentedTripUTC).format('DD.MM.YYYY HH:mm.ss'),
                    'RentedTime': fetchDataAndSetStateTime(trip.EndOfRentedTripUTC - trip.StartOfRentedTripUTC),
                    'RentedRoadLength': trip.RentedTripDistanceInMeters / 1000,
                }
            ));

            setTripsInfo(addressObjects); // Adresleri state'e güncelle
        };

        updateAddresses();
    }, []);


    if (tripsInfo.length > 0) {

        return (


            <Accordion allowToggle>
                {tripsInfo.map(trip => (
                    <>
                        <Tag display={{ base: 'none', md: 'block' }} size={'md'} key={'md'} mt={'10px'} variant='subtle' w={'10rem'} background={'white'} p={'10px'} borderRadius="10px  10px 0px 0px">
                            <TagLeftIcon boxSize='18px' as={LuClock4} />
                            <TagLabel> {trip.RentedDate}</TagLabel>
                        </Tag>

                        <AccordionItem

                            w={{ sm: '100%', md: 'auto' }}
                            mt={{ base: '10px', xl: '0' }}
                            bg={'white'}
                            p={{ sm: "15px", md: "15px 30px 15px 15px" }}
                            borderRadius="0px  30px 30px 30px"
                        >

                            {({ isExpanded }) => (
                                <>
                                    <Flex
                                        w={{ sm: '100%', md: 'auto' }}
                                        alignItems="center "

                                        columnGap={'10px'}
                                        flexDirection="row"
                                        flexWrap={{ base: 'wrap', md: 'nowrap' }}
                                        justifyContent={'space-between'}
                                    >
                                        <Flex w={{ sm: '100%', md: 'auto' }} alignItems="center " justifyContent={'space-between'}>
                                            <Icon as={MdOutlineRoute} width='2.5rem' height='2.5rem' color='white' background={'linear-gradient(135deg, #319795 0%, forestgreen 90%)'} p={'8px'} borderRadius={'50%'} />
                                            <Tag display={{ base: 'block', md: 'none' }} size={'md'} key={'md'} variant='subtle' w={'10rem'} background={'white'} p={'10px'} borderRadius="10px  10px 0px 0px">
                                                <TagLeftIcon boxSize='18px' as={LuClock4} />
                                                <TagLabel>{trip.RentedDate}</TagLabel>
                                            </Tag>
                                        </Flex>
                                        <Flex alignItems={'center'} columnGap={'10px'}>
                                            <Icon as={BiSolidCoinStack} width='1.5rem' height='1.5rem' />
                                            <SimpleGrid columns={1} spacing={0}>
                                                <Text as={'p'} m={'0'}>{t('tripprice')}: </Text>
                                                <Text fontSize={'1.5em'} as={'b'} m={'0'}>{trip.RentedFare} ₺</Text>
                                            </SimpleGrid>

                                        </Flex>
                                        <Flex alignItems={'center'} columnGap={'10px'}>
                                            <Icon as={BiCoinStack} width='1.5rem' height='1.5rem' />
                                            <SimpleGrid columns={1} spacing={0}>
                                                <Text as={'p'} m={'0'}>{t('extraprice')}: </Text>
                                                <Text fontSize={'1.5em'} as={'b'} m={'0'}>{trip.RentedExtras} ₺</Text>
                                            </SimpleGrid>

                                        </Flex>
                                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, md: 1 }} m={{ base: '10px 0', md: 'unset' }}>
                                            <SimpleGrid>
                                                <Flex alignItems={'center'} columnGap={'8px'}>
                                                    <Icon as={LuClock12} width='1.2rem' height='1.2rem' />
                                                    <Text as={'b'} m={'0'}>{t('starttime')}</Text>
                                                </Flex>
                                                <Text as={'p'} m={'0 26px'}>{trip.RentedStartTime}</Text>
                                            </SimpleGrid>
                                            <SimpleGrid>
                                                <Flex alignItems={'center'} columnGap={'8px'}>
                                                    <Icon as={LuClock9} width='1.2rem' height='1.2rem' />
                                                    <Text as={'b'} m={'0'}>{t('endtime')}</Text>
                                                </Flex>
                                                <Text as={'p'} m={'0 26px'}>{trip.RentedEndTime}</Text>
                                            </SimpleGrid>
                                        </SimpleGrid>
                                        <AccordionButton w={{ base: '100%', md: '8rem' }} p={'0'}>

                                            {isExpanded ? (
                                                <Button

                                                    colorScheme='linkedin'
                                                    variant='solid'
                                                    height={'60px'}
                                                    w={{ base: '100%', md: '8rem' }}
                                                    mb={{ base: '4px', md: '0' }}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    flexDirection={'column'}
                                                    alignItems={'center'}
                                                    textAlign={'center'}
                                                >
                                                    <Icon as={BiDetail} width='1.5rem' height='1.5rem' />
                                                    {t('hidedetail')}
                                                </Button>
                                            ) : (
                                                <Button

                                                    colorScheme='linkedin'
                                                    variant='solid'
                                                    height={'60px'}
                                                    w={{ base: '100%', md: '8rem' }}
                                                    mb={{ base: '4px', md: '0' }}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    flexDirection={'column'}
                                                    alignItems={'center'}
                                                    textAlign={'center'}
                                                    onClick={() => GetAddresses(trip.Index)}
                                                >
                                                    <Icon as={BiDetail} width='1.5rem' height='1.5rem' />
                                                    {t('showdetail')}
                                                </Button>
                                            )}
                                        </AccordionButton>

                                    </Flex>


                                    <AccordionPanel pb={4} pt={4}>
                                        <Flex
                                            justifyContent={'space-between'}
                                            flexDirection={{ base: "column", md: "row" }}
                                            alignItems={'center'}
                                        >
                                            <SimpleGrid columns={1} spacing={{ base: 1, md: 1 }} m={{ base: '10px 0', md: 'unset' }}>
                                                <SimpleGrid>
                                                    <Flex alignItems={'center'} columnGap={'8px'}>
                                                        <Icon as={MdOutlineLocationOn} width='1.2rem' height='1.2rem' />
                                                        <Text as={'b'} m={'0'}>{t('startaddress')}</Text>
                                                    </Flex>
                                                    <Text fontSize={'0.8em'} maxW={'30rem'} as={'p'} m={'0 26px'}>{addresses != null ? addresses.RentedStartLocation : <Text> <Spinner
                                                        thickness='2px'
                                                        speed='0.65s'
                                                        emptyColor='gray.200'
                                                        color='blue.500'
                                                        size='sm'
                                                    /> {t('loading')}</Text>}</Text>
                                                </SimpleGrid>
                                                <SimpleGrid>
                                                    <Flex alignItems={'center'} columnGap={'8px'}>
                                                        <Icon as={MdLocationOn} width='1.2rem' height='1.2rem' />
                                                        <Text as={'b'} m={'0'}>{t('endaddress')}</Text>
                                                    </Flex>
                                                    <Text fontSize={'0.8em'} maxW={'30rem'} as={'p'} m={'0 26px'}>{addresses != null ? addresses.RentedEndLocation : <Text> <Spinner
                                                        thickness='2px'
                                                        speed='0.65s'
                                                        emptyColor='gray.200'
                                                        color='blue.500'
                                                        size='sm'
                                                    /> {t('loading')}</Text>}</Text>
                                                </SimpleGrid>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2} spacing={{ base: 1, md: 10 }} m={{ base: '10px 0', md: 'unset' }}>
                                                <SimpleGrid spacing={1}>
                                                    <Flex alignItems={'center'} columnGap={'8px'}>
                                                        <Icon as={MdTimer} width='1.2rem' height='1.2rem' />
                                                        <Text as={'b'} m={'0'}>{t('triptime')}</Text>
                                                    </Flex>
                                                    <Text as={'p'} m={'0 26px'}>{trip.RentedTime}</Text>
                                                </SimpleGrid>
                                                <SimpleGrid>
                                                    <Flex alignItems={'center'} columnGap={'8px'}>
                                                        <Icon as={FaRoad} width='1.2rem' height='1.2rem' />
                                                        <Text as={'b'} m={'0'}>{t('tripdistance')}</Text>
                                                    </Flex>
                                                    <Text as={'p'} m={'0 26px'}>{trip.RentedRoadLength} KM</Text>
                                                </SimpleGrid>

                                            </SimpleGrid>
                                            < CustomButton param={trip} adress= {addresses} deviceID={trip.DeviceID}tripID={trip.TripID}/>

                                        </Flex>

                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </>
                ))}
            </Accordion>


        );
    }
    else {
        return (
            <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
        );
    }
}