export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "tablename",
    accessor: "Name",
  },
  {
    Header: "tablesurname",
    accessor: "Surname",
  },
  {
    Header: "tablestatus",
    accessor: "UserStatus",
  },
  {
    Header: "tablephone",
    accessor: "Phone",
  },
  {
    Header: "username",
    accessor: "UserName",
  },
  {
    Header: "tablerole",
    accessor: "Role",
  },
  {
    Header: "tablemanage",
    accessor: "UserId",
  },
];
