// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  Progress,
  useColorModeValue,
  CircularProgress,
  CircularProgressLabel
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { GrRadialSelected } from "react-icons/gr";
export default function Project(props) {
  const { title, selected, color, info, icon, data, onClick, minimize, ...rest } = props;
  // Chakra Color Mode
  console.log(icon);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card height={minimize ? '100px' : 'auto'}
      bg={selected ? 'teal' : 'lightgray'}  {...rest}
      p='0'
      border={'2px solid lightgray'}
      borderTopWidth={'8px'}
      borderColor={selected ? 'teal' : 'lightgray'} >
      {selected ? <Icon as={GrRadialSelected} width={3} height={3} color={'white'} pos={'absolute'} top={'0'} left={'6px'} /> : ''}

      <Flex align='center' width={'100%'}
        columnGap={'5px'}
        bg={bg}
        p={{ sm: '7px', md: '14px' }}
        borderRadius={'1.2rem'}
        borderTopLeftRadius={'4rem'}
        direction={{ base: "row", md: "row" }}
        onClick={onClick} alignItems={'center'}
        flexWrap={'wrap'} >
        <Icon display={minimize ? 'none' : 'block'} as={icon} width={{ sm: 'calc(10%)', md: '10%' }} height={{ sm: '20px', md: '30px' }} color={color} margin={{ sm: '2px 14px', md: '5px 10px' }} />

        <Flex flexDirection={'column'} w={{ sm: 'calc(60% - 5px)', md: minimize ? '60%' : 'calc(60% - 5px)' }}>
          <Text
            w={'100%'}
            color={textColorPrimary}
            fontWeight='600'
            fontSize={minimize ? 'xs' : { base: 'sm', md: 'md' }}
          >
            {title}
          </Text>

          <Text
          display={{ base: 'grid', md: 'block' }}
          gridTemplateColumns={'1fr 1fr'}
          columnGap={'10px'}
            cursor={'pointer'}
            color={textColorPrimary}
            fontWeight='400'
            fontSize='md'
          >
            {info}
            <Text as={'span'} display={{ base: 'block', md: 'none' }}>
              {data != null ? ` (${data}%)` : ''}
            </Text>
          </Text>
        </Flex>


        <Flex display={{ base: 'none', md: 'flex' }} w={{ sm: 'calc(20% - 5px)', md: minimize ? 'calc(40% - 5px)' : 'calc(20% - 5px)' }} justifyContent={'space-between'} flexWrap={'wrap'}>

          <CircularProgress size='55px' right={'2%'} display={data != null ? { base: 'none', md: 'block' } : 'none'} value={data} color={color}>
            <CircularProgressLabel>{data} %</CircularProgressLabel>
          </CircularProgress>
        </Flex>

      </Flex>
    </Card>
  );
}
