import React, { useState, useEffect } from 'react';
import {
    Input,
    InputGroup,
    InputLeftElement,
    Box,
    List,
    ListItem,
    Flex,
    Icon,
    InputRightElement,
    Button,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from "@chakra-ui/react";
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdLockOutline,
    MdLockOpen,
    MdSearch,
    MdPlayCircleOutline
} from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { PiDotOutlineFill, PiDotsNineThin } from "react-icons/pi";
import { TbZoomCheck, TbZoomCancel } from "react-icons/tb";
import { BsThreeDots } from "react-icons/bs";
const SearchBar = (props) => {
    const { onLock, onFilterChange, data, onMarkerCluster, onAutoZoom, onShowPlates, ...rest } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [lock, setLock] = useState(false);
    const [markerCluster, setMarkerCluster] = useState(false);
    const [autoZoom, setAutoZoom] = useState(true);
    const [showPlates, setShowPlates] = useState(true);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setMarkerCluster(JSON.parse(localStorage.getItem('markerClusterValue')));
    }, [])
    const locationLock = () => {
        setLock(!lock);
        onLock(!lock);
    }
    const autoMapZoom = () => {
        setAutoZoom(!autoZoom);
        onAutoZoom(!autoZoom);
    }
    const MarkerCluster = () => {
        setMarkerCluster(!markerCluster);
        onMarkerCluster(!markerCluster);
    }
    const GeneralSettings = (op) => {

        switch (op) {
            case 'plate':
                setShowPlates(!showPlates);
                onShowPlates(!showPlates);
                break;
        }
    }
    const handleChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        // Arama sonuçlarını filtrele
        const filteredResults = data.filter(item =>
            item.Plate.toLowerCase().includes(value.toLowerCase())
        );
        if (filteredResults.length == 1) {
            if (filteredResults[0].Plate == value) {
                setSearchTerm('');
            }

        }
        setSearchResults(filteredResults);
    };
    const handletoSelect = (deviceID, plate, location) => {
        setSearchTerm(plate);
        setSearchResults([]);
        localStorage.setItem('selectedLocationDevice', deviceID);
        onFilterChange({ 'location': location });
    };
    return (
        <Box pos={'relative'} p={'15px 10px'}>
            <Flex alignItems={'start'} columnGap={'5px'}>
                <InputGroup pos={'relative'} flexDirection={'column'} rowGap={'5px'}>
                    <InputRightElement>        <Icon color={'black'} w={6} h={6} as={MdSearch} /></InputRightElement>
                    <Input
                        placeholder={t('search') + "..."}
                        value={searchTerm}
                        borderColor='black'
                        onChange={handleChange}
                    />
                    {searchTerm && (
                        <Flex w={'100%'} overflowY={'hidden'} maxH={'300px'}>
                            <List w={'100%'} spacing={1} bg={'white'} fontSize={'1.4em'}>
                                {searchResults.map((result, index) => (
                                    <ListItem borderRadius={'0.375rem'} p={'5px'} width={'100%'} key={index} onClick={() => handletoSelect(result.DeviceId, result.Plate, [result.LastLocation.Latitude, result.LastLocation.Longitude])} cursor={'pointer'} _hover={{ backgroundColor: "teal.200", color: 'white' }}>
                                        {result.Plate}
                                    </ListItem>
                                ))}
                            </List>
                        </Flex>
                    )}
                </InputGroup>
                <Tooltip display={{ base: 'none', md: 'unset' }} label={t('tooltipfortaxilockbutton')}>
                    <Button
                        display={{ base: 'none', md: 'flex' }}
                        color={lock ? 'white' : 'black'}
                        border={!lock && '1px solid black'}
                        colorScheme={lock ? 'teal' : 'black'}
                        onClick={locationLock}><Icon w={6} h={6} as={lock ? MdLockOutline : MdLockOpen} /></Button>
                </Tooltip>
                <Tooltip display={{ base: 'none', md: 'unset' }} label={t('tooltipforclusterbutton')}>
                    <Button
                        display={{ base: 'none', md: 'flex' }}
                        color={markerCluster ? 'white' : 'black'}
                        border={!markerCluster && '1px solid black'}
                        colorScheme={markerCluster ? 'twitter' : 'black'}
                        onClick={MarkerCluster}><Icon w={6} h={6} as={markerCluster ? PiDotOutlineFill : PiDotsNineThin} /></Button>
                </Tooltip>
                <Tooltip display={{ base: 'none', md: 'unset' }} label={t('tooltipforautozoom')}>
                    <Button
                        display={{ base: 'none', md: 'flex' }}
                        color={autoZoom ? 'white' : 'black'}
                        border={!autoZoom && '1px solid black'}
                        colorScheme={autoZoom ? 'linkedin' : 'black'}
                        onClick={autoMapZoom}><Icon w={6} h={6} as={autoZoom ? TbZoomCheck : TbZoomCancel} /></Button>
                </Tooltip>
                <Menu>
                    <MenuButton as={Button} color={'black'} border={'1px solid black'} colorScheme={'black'} textAlign={'center'} >
                        <Flex w={'100%'} h={'100%'} justifyContent={'center'}>
                            <Icon textAlign={'center'} w={6} h={6} as={BsThreeDots} />
                        </Flex>

                    </MenuButton>
                    <MenuList>
                        <MenuItem
                            alignItems={'center'}
                            display={{ base: 'flex', md: 'none' }}
                            color={lock ? 'white' : 'black'}
                            border={!lock && '1px solid black'}
                            bg={lock ? '#0181cB' : 'gray'}
                            onClick={locationLock}><Icon w={6} h={6} as={lock ? MdLockOutline : MdLockOpen} /> {t('tooltipfortaxilockbutton')} </MenuItem>
                        <MenuItem
                            alignItems={'center'}
                            display={{ base: 'flex', md: 'none' }}
                            color={markerCluster ? 'white' : 'black'}
                            border={!markerCluster && '1px solid black'}
                            bg={markerCluster ? '#0181cB' : 'gray'}
                            onClick={MarkerCluster}><Icon w={6} h={6} as={markerCluster ? PiDotOutlineFill : PiDotsNineThin} /> {t('tooltipforclusterbutton')} </MenuItem>
                        <MenuItem
                            alignItems={'center'}
                            display={{ base: 'flex', md: 'none' }}
                            color={autoZoom ? 'white' : 'black'}
                            border={!autoZoom && '1px solid black'}
                            bg={autoZoom ? '#0181cB' : 'gray'}
                            onClick={autoMapZoom}><Icon w={6} h={6} as={autoZoom ? TbZoomCheck : TbZoomCancel} /> {t('tooltipforautozoom')} </MenuItem>
                        <MenuItem onClick={() => GeneralSettings('plate')} justifyContent='space-between'>{t('showhideplates')} {showPlates && <Flex w='10px' h='10px' bg='teal' mx='10px' borderRadius='50%'></Flex>}</MenuItem>

                    </MenuList>
                </Menu>

            </Flex>

        </Box>
    );
};

export default SearchBar;
