// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,
	List,
	ListItem,
	ListIcon,
	OrderedList,
	UnorderedList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LanguageBar from 'components/LanguageBar/LanguageBar'
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/CustomSidebar/Sidebar';
import { FaTurkishLiraSign } from "react-icons/fa6";
import PropTypes from 'prop-types';
import { TbWorldCog } from "react-icons/tb";
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Assets
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import navImage from 'assets/img/layout/Navbar.png';
import WeatherStatus from 'components/WeatherBar/WeatherStatusBar';
import { MdNotificationsNone, MdInfoOutline, MdPerson, MdLocalTaxi, MdOutlineGTranslate } from 'react-icons/md';
import { CitiesLocations } from "variables/CityLocations";
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
const taxiDeviceService = new TaxiDeviceService();
export default function HeaderLinks(props) {
	const history = useHistory();
	const userData = JSON.parse(localStorage.getItem('UserData'));
	var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
	const city = CitiesLocations[cityIndex];
	const handleLogout = () => {
		// Token ve diğer bilgileri localStorage'den temizle
		localStorage.removeItem('token');
		localStorage.removeItem('expireTime');
		localStorage.removeItem('UserData');
		// Diğer logout işlemlerini gerçekleştir (isteğe bağlı)

		// Kullanıcıyı login sayfasına yönlendir
		history.push('/auth/login');
	};
	const { secondary, data } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	const { t, i18n } = useTranslation();
	const changeLanguage = async lang => {
	 await	i18n.changeLanguage(lang)
	}
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			justifyContent={'space-between'}
			borderRadius="30px"
			boxShadow={shadow}>
			<Flex  width={'40px'} padding={'2%'}>
				<SidebarResponsive routes={routes} />
			</Flex>

			{/* <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" /> */}
	

			{/* <Flex
				bg={ethBg}
				borderRadius="30px"
				ms="auto"
				p="6px"
				align="center"
		
				me="6px">
				<WeatherStatus
					plate={data.City}
				>

				</WeatherStatus>
			</Flex> */}
			<Flex alignItems={'center'} columnGap={'10px'}>
				{/* <Menu>
				<MenuButton p="0px" pos={'relative'}>
					<Text
					as={'small'}
					pos={'absolute'}
					bg={'crimson'}
					color={'white'} 
					height={'14px'}
					w={'14px'}
					right={'3px'}
					p={'4px'}
					fontSize={'10px'}
					display={'flex'}
					justifyContent={'center'} 
					borderRadius={'50%'}
					alignItems={'center'}>
						2
						</Text>
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
						Bildirimler
						</Text>
						<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent type='Warning' info={`${deviceLocations.length} Cihazdan Veri Alınamıyor`} aName="Alicia" detail={'En yakın zamanda bu araçlar ulaşmanız önerilir!'}/>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon Design System Free" aName="Josh Henry" detail={'A new update for your downloaded item is available!'}/>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu> */}
				{/* 
			<Menu>
				<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={MdInfoOutline}
						color={navbarIcon}
						w='18px'
						h='18px'
						me='10px'
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='20px'
					me={{ base: "30px", md: "unset" }}
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					minW={{ base: "unset" }}
					maxW={{ base: "360px", md: "unset" }}>
					<Image src={navImage} borderRadius='16px' mb='28px' />
					<Flex flexDirection='column'>
						<Link
							w='100%'
							href='https://horizon-ui.com/pro?ref=horizon-chakra-free'>
							<Button w='100%' h='44px' mb='10px' variant='brand'>
								Buy Horizon UI PRO
							</Button>
						</Link>
						<Link
							w='100%'
							href='https://horizon-ui.com/documentation/docs/introduction?ref=horizon-chakra-free'>
							<Button
								w='100%'
								h='44px'
								mb='10px'
								border='1px solid'
								bg='transparent'
								borderColor={borderButton}>
								See Documentation
							</Button>
						</Link>
						<Link
							w='100%'
							href='https://github.com/horizon-ui/horizon-ui-chakra'>
							<Button
								w='100%'
								h='44px'
								variant='no-hover'
								color={textColor}
								bg='transparent'>
								Try Horizon Free
							</Button>
						</Link>
					</Flex>
				</MenuList>
			</Menu> */}



				<Menu>
					<MenuButton p="0px">
						<Avatar
							_hover={{ cursor: 'pointer' }}
							color="white"
							name={data.Name + " " + data.Surname}
							bg="#11047A"
							size="sm"
							w="40px"
							h="40px"
						/>
					</MenuButton>
					<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
						<Flex w="100%" mb="0px">
							<Text
								ps="20px"
								pt="16px"
								pb="10px"
								w="100%"
								display={'flex'}
								alignItems={'center'}
								textTransform={'capitalize'}
								borderBottom="1px solid"
								borderColor={borderColor}
								fontSize="sm"
								fontWeight="700"
								color={textColor}>
								<Icon as={MdPerson} fontSize="md" />
								&nbsp;{data.Name + " " + data.Surname}
							</Text>
						</Flex>
						<Flex flexDirection="column" p="10px">
							<MenuItem as={'a'} href='./profile' _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
								<Text fontSize="sm">{t('accountsettings')}</Text>
							</MenuItem>
							<MenuItem
								_hover={{ bg: 'none' }}
								_focus={{ bg: 'none' }}
								color="red.400"
								onClick={handleLogout}
								cursor={'pointer'}
								borderRadius="8px"
								px="14px">
								<Text fontSize="sm">{t('logout')}</Text>
							</MenuItem>
						</Flex>
					</MenuList>
				</Menu>
				<LanguageBar />
			</Flex>


		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
