// AuthService.js
import config from 'config';
class UserService {
    constructor() {
        this.tokenKey = 'authToken';
    }

    async AddUserToSystem(model) {
        const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
        // Giriş işlemi için API'ye istek gönder
        const response = await fetch(config.baseURL + config.endpoints.AddUserToSystem, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: model ,
          });
        const data = await response.json();

            return data;
     
    }
    async ListAllUsers(pageNum, searchText, pageSize , sort) {
      const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
      // Giriş işlemi için API'ye istek gönder
      const response = await fetch(config.baseURL + config.endpoints.ListAllUsers, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'PageNumber': pageNum, 'SearchText': searchText, 'PageSize': pageSize, 'SortDefinition': sort})
        });
      const data = await response.json();

          return data;
   
  }
  async ListCityUsers(pageNum, searchText, pageSize , sort) {
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
    // Giriş işlemi için API'ye istek gönder
    const response = await fetch(config.baseURL + config.endpoints.ListCityUsers, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'PageNumber': pageNum, 'PageSize': pageSize, "CityCode": userData.CityCode, 'SearchText': searchText,  'SortDefinition': sort})
      });
    const data = await response.json();

        return data;
 
}
async TaxiOwnerPermissions() {
  const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
  // Giriş işlemi için API'ye istek gönder
  const response = await fetch(config.baseURL + config.endpoints.TaxiOwnerPermissions, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  const data = await response.json();

      return data;

}
async ChangePassword(model) {
  const token = JSON.parse(localStorage.getItem(this.tokenKey)).Token;
  // Giriş işlemi için API'ye istek gönder
  const response = await fetch(config.baseURL + config.endpoints.ChangePassword, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: model
    });
  const data = await response.json();

      return data;

}
}

export default UserService;
