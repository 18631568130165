const Roles = {
    1: 'ParsAdmin',
    2: 'CityAdmin',
    4: 'Dealer',
    8: 'TaxiOwner',
    16: 'Driver',
    32: 'Station',
    64: 'TaxiOwnerSubUser',
    128 : 'NewsCenter'
    // İstediğiniz kadar rolü ekleyebilirsiniz...
  };
  
  export default Roles;