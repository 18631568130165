export const countries = [
      {
        "name": "USA",
        "code": "1"
      },
      {
        "name": "Canada",
        "code": "1"
      },
      {
        "name": "UK",
        "code": "44"
      },
      {
        "name": "Germany",
        "code": "49"
      },
      {
        "name": "France",
        "code": "33"
      },
      {
        "name": "Spain",
        "code": "34"
      },
      {
        "name": "Italy",
        "code": "39"
      },
      {
        "name": "Turkey",
        "code": "90"
      },
      {
        "name": "Japan",
        "code": "81"
      },
      {
        "name": "Australia",
        "code": "61"
      },
      {
        "name": "Brazil",
        "code": "55"
      },
      {
        "name": "China",
        "code": "86"
      },
      {
        "name": "India",
        "code": "91"
      },
      {
        "name": "Russia",
        "code": "7"
      },
      {
        "name": "Mexico",
        "code": "52"
      },
      {
        "name": "South Korea",
        "code": "82"
      },
      {
        "name": "Indonesia",
        "code": "62"
      },
      {
        "name": "South Africa",
        "code": "27"
      },
      {
        "name": "Nigeria",
        "code": "234"
      },
      {
        "name": "Argentina",
        "code": "54"
      },
      {
        "name": "Egypt",
        "code": "20"
      },
      {
        "name": "Pakistan",
        "code": "92"
      },
      {
        "name": "Bangladesh",
        "code": "880"
      },
      {
        "name": "Philippines",
        "code": "63"
      },
      {
        "name": "Vietnam",
        "code": "84"
      },
      {
        "name": "Thailand",
        "code": "66"
      },
      {
        "name": "Colombia",
        "code": "57"
      },
      {
        "name": "Saudi Arabia",
        "code": "966"
      },
      {
        "name": "Malaysia",
        "code": "60"
      },
      {
        "name": "Iran",
        "code": "98"
      },
      {
        "name": "Iraq",
        "code": "964"
      },
      {
        "name": "Peru",
        "code": "51"
      },
      {
        "name": "Ukraine",
        "code": "380"
      },
      {
        "name": "Poland",
        "code": "48"
      },
      {
        "name": "Canada",
        "code": "1"
      },
      {
        "name": "Morocco",
        "code": "212"
      },
      {
        "name": "Venezuela",
        "code": "58"
      },
      {
        "name": "Kenya",
        "code": "254"
      },
      {
        "name": "Chile",
        "code": "56"
      },
      {
        "name": "Netherlands",
        "code": "31"
      },
      {
        "name": "Czech Republic",
        "code": "420"
      },
      {
        "name": "Sweden",
        "code": "46"
      },
      {
        "name": "Belgium",
        "code": "32"
      },
      {
        "name": "Greece",
        "code": "30"
      },
      {
        "name": "Portugal",
        "code": "351"
      },
      {
        "name": "Switzerland",
        "code": "41"
      },
      {
        "name": "Israel",
        "code": "972"
      },
      {
        "name": "Austria",
        "code": "43"
      },
      {
        "name": "Norway",
        "code": "47"
      },
      {
        "name": "Denmark",
        "code": "45"
      },
      {
        "name": "Finland",
        "code": "358"
      },
      {
        "name": "Ireland",
        "code": "353"
      },
      {
        "name": "New Zealand",
        "code": "64"
      },
      {
        "name": "Hungary",
        "code": "36"
      },
      {
        "name": "Romania",
        "code": "40"
      },
      {
        "name": "Kazakhstan",
        "code": "7"
      },
      {
        "name": "Qatar",
        "code": "974"
      },
      {
        "name": "Algeria",
        "code": "213"
      },
      {
        "name": "Oman",
        "code": "968"
      },
      {
        "name": "Kuwait",
        "code": "965"
      },
      {
        "name": "Cuba",
        "code": "53"
      },
      {
        "name": "Ecuador",
        "code": "593"
      },
      {
        "name": "Sudan",
        "code": "249"
      },
      {
        "name": "Uzbekistan",
        "code": "998"
      },
      {
        "name": "Sri Lanka",
        "code": "94"
      },
      {
        "name": "Belarus",
        "code": "375"
      },
      {
        "name": "Syria",
        "code": "963"
      },
      {
        "name": "Senegal",
        "code": "221"
      },
      {
        "name": "Tunisia",
        "code": "216"
      },
      {
        "name": "Côte d'Ivoire",
        "code": "225"
      },
      {
        "name": "Cameroon",
        "code": "237"
      },
      {
        "name": "Ghana",
        "code": "233"
      },
      {
        "name": "Madagascar",
        "code": "261"
      },
      {
        "name": "Angola",
        "code": "244"
      },
      {
        "name": "Jordan",
        "code": "962"
      },
      {
        "name": "Lebanon",
        "code": "961"
      },
      {
        "name": "Libya",
        "code": "218"
      },
      {
        "name": "Yemen",
        "code": "967"
      },
      {
        "name": "Ethiopia",
        "code": "251"
      },
      {
        "name": "Uganda",
        "code": "256"
      },
      {
        "name": "Mozambique",
        "code": "258"
      },
      {
        "name": "Honduras",
        "code": "504"
      },
      {
        "name": "Nicaragua",
        "code": "505"
      },
      {
        "name": "Guatemala",
        "code": "502"
      },
      {
        "name": "Paraguay",
        "code": "595"
      },
      {
        "name": "Bolivia",
        "code": "591"
      },
      {
        "name": "Nepal",
        "code": "977"
      },
      {
        "name": "Bahrain",
        "code": "973"
      },
      {
        "name": "Trinidad and Tobago",
        "code": "1"
      },
      {
        "name": "Croatia",
        "code": "385"
      },
      {
        "name": "Slovakia",
        "code": "421"
      },
      {
        "name": "Slovenia",
        "code": "386"
      },
      {
        "name": "Costa Rica",
        "code": "506"
      },
      {
        "name": "Panama",
        "code": "507"
      },
      {
        "name": "Uruguay",
        "code": "598"
      },
      {
        "name": "El Salvador",
        "code": "503"
      },
      {
        "name": "Lithuania",
        "code": "370"
      },
      {
        "name": "Latvia",
        "code": "371"
      },
      {
        "name": "Estonia",
        "code": "372"
      },
      {
        "name": "Bulgaria",
        "code": "359"
      },
      {
        "name": "Sri Lanka",
        "code": "94"
      },
      {
        "name": "Bangladesh",
        "code": "880"
      },
      {
        "name": "Singapore",
        "code": "65"
      },
      {
        "name": "Hong Kong",
        "code": "852"
      },
      {
        "name": "Macau",
        "code": "853"
      },
      {
        "name": "Taiwan",
        "code": "886"
      },
      {
        "name": "Mongolia",
        "code": "976"
      },
      {
        "name": "Fiji",
        "code": "679"
      },
      {
        "name": "Papua New Guinea",
        "code": "675"
      },
      {
        "name": "Solomon Islands",
        "code": "677"
      },
      {
        "name": "Vanuatu",
        "code": "678"
      },
      {
        "name": "Fiji",
        "code": "679"
      },
      {
        "name": "Kiribati",
        "code": "686"
      },
      {
        "name": "Tuvalu",
        "code": "688"
      },
      {
        "name": "Samoa",
        "code": "685"
      },
      {
        "name": "Tonga",
        "code": "676"
      },
      {
        "name": "Cook Islands",
        "code": "682"
      },
      {
        "name": "Niue",
        "code": "683"
      },
      {
        "name": "American Samoa",
        "code": "684"
      },
      {
        "name": "Marshall Islands",
        "code": "692"
      },
      {
        "name": "Palau",
        "code": "680"
      },
      {
        "name": "Nauru",
        "code": "674"
      },
      {
        "name": "Micronesia",
        "code": "691"
      },
      {
        "name": "East Timor",
        "code": "670"
      },
      {
        "name": "New Caledonia",
        "code": "687"
      },
      {
        "name": "French Polynesia",
        "code": "689"
      },
      {
        "name": "Wallis and Futuna",
        "code": "681"
      },
      {
        "name": "Norfolk Island",
        "code": "672"
      },
      {
        "name": "Christmas Island",
        "code": "61"
      },
      {
        "name": "Cocos (Keeling) Islands",
        "code": "61"
      },
      {
        "name": "Pitcairn Islands",
        "code": "64"
      },
      {
        "name": "Falkland Islands",
        "code": "500"
      },
      {
        "name": "Bermuda",
        "code": "1"
      },
      {
        "name": "Greenland",
        "code": "299"
      },
      {
        "name": "Svalbard and Jan Mayen",
        "code": "47"
      },
      {
        "name": "Faroe Islands",
        "code": "298"
      },
      {
        "name": "Aruba",
        "code": "297"
      },
      {
        "name": "Curaçao",
        "code": "599"
      },
      {
        "name": "Saint Martin",
        "code": "590"
      },
      {
        "name": "Sint Maarten",
        "code": "1"
      },
      {
        "name": "Saint Barthélemy",
        "code": "590"
      },
      {
        "name": "Saint Pierre and Miquelon",
        "code": "508"
      },
      {
        "name": "Anguilla",
        "code": "1"
      },
      {
        "name": "Montserrat",
        "code": "1"
      },
      {
        "name": "Turks and Caicos Islands",
        "code": "1"
      },
      {
        "name": "British Virgin Islands",
        "code": "1"
      },
      {
        "name": "Cayman Islands",
        "code": "1"
      },
      {
        "name": "Belize",
        "code": "501"
      },
      {
        "name": "Guam",
        "code": "1"
      },
      {
        "name": "Northern Mariana Islands",
        "code": "1"
      },
      {
        "name": "Puerto Rico",
        "code": "1"
      },
      {
        "name": "US Virgin Islands",
        "code": "1"
      },
      {
        "name": "American Samoa",
        "code": "1"
      },
      {
        "name": "Wake Island",
        "code": "1"
      },
      {
        "name": "Midway Atoll",
        "code": "1"
      },
      {
        "name": "Baker Island",
        "code": "1"
      },
      {
        "name": "Howland Island",
        "code": "1"
      },
      {
        "name": "Jarvis Island",
        "code": "1"
      },
      {
        "name": "Johnston Atoll",
        "code": "1"
      },
      {
        "name": "Kingman Reef",
        "code": "1"
      },
      {
        "name": "Palmyra Atoll",
        "code": "1"
      },
      {
        "name": "French Southern and Antarctic Lands",
        "code": "262"
      },
      {
        "name": "Réunion",
        "code": "262"
      },
      {
        "name": "Mayotte",
        "code": "262"
      },
      {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "code": "290"
      },
      {
        "name": "Ascension Island",
        "code": "247"
      },
      {
        "name": "Tristan da Cunha",
        "code": "290"
      },
      {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "500"
      },
      {
        "name": "Antarctica",
        "code": "672"
      },
      {
        "name": "Bouvet Island",
        "code": "47"
      },
      {
        "name": "Heard Island and McDonald Islands",
        "code": "672"
      }
    ]
  
  