// Chakra imports
import { Heading, Image, Box, Flex, Badge, Icon, Text, Divider } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { useLocation } from "react-router-dom";
// Assets
import { useTranslation } from 'react-i18next';
import LanguageBar from 'components/LanguageBar/LanguageBar'
import { FaChevronLeft } from "react-icons/fa";
import logoText from "assets/img/layout/parstaksilogoText.png";
import logoIcon from "assets/img/layout/icon_w.png";
import Background from "assets/img/auth/auth_banner.png";
function AuthIllustration(props) {
  const { t, i18n } = useTranslation();
  const { children, illustrationBackground } = props;
  const [url, setUrlData] = React.useState('');
  // Chakra color mode
  let location = useLocation();
  var deger = { lg: "45vw", "2xl": "40vw" };
  var dValue = 'block';
  React.useEffect(() => {
    setUrlData(location.pathname);
    console.log("URL değişti:", location.pathname);
  }, [location]);

  if (url == "/auth/register") {
    deger = { lg: "15vw", "2xl": "15vw" };
    var dValue = 'none';
  }
  if (url == "/auth/login") {
    deger = { lg: "45vw", "2xl": "40vw" };
  }
  return (
    <Flex position='relative' h='max-content'>
      <Heading as={'h4'}></Heading>
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w='100%'
        maxW={{ md: "66%", lg: "1313px" }}
        mx='auto'
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent='start'
        direction='column'>
        {/* <NavLink
          to='/admin'
          style={() => ({
            width: "fit-content",
            marginTop: "40px",
          })}>
          <Flex
            align='center'
            ps={{ base: "25px", lg: "0px" }}
            pt={{ lg: "0px", xl: "0px" }}
            w='fit-content'>
            <Icon
              as={FaChevronLeft}
              me='12px'
              h='13px'
              w='8px'
              color='secondaryGray.600'
            />
            <Text ms='0px' fontSize='sm' color='secondaryGray.600'>
              Back to Simmmple
            </Text>
          </Flex>
        </NavLink> */}

        {children}
        <Box
          display={{ base: "none", md: "block" }}
          h='100%'
          transition={'width .5s ease'}
          minH='100vh'
          w={deger}
          position='absolute'
          right='0px'>
          <Flex pos={'absolute'} zIndex={'2'} p={'20px'} w={'100%'} justifyContent={'end'}>
            <LanguageBar color='white' withText={true}/>
          </Flex>

          <Flex
            bg={`url('${Background}')           
            `}
            boxShadow={'0 4px 30px rgba(0, 0, 0, 0.1)'}
            justify='center'
            align='center'
            backdropBlur={'5px'}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}>

            <Flex
              justify='center'
              align='center'
              // bg={`rgba(255,255,255,1)`}
              // w='25rem'
              // h='25rem'
              flexDirection={'column'}
              borderRadius={'50%'}>
              <Image src={logoIcon} w='25%' />
              <Image src={logoText} w='50%' />
              <Flex display={dValue} flexDirection={'column'} mt={'40px'} border={'1px solid white'} p={'20px 30px'} borderRadius={'30px'} color={'white'}>
                <Heading as={'h4'} size="md">
             {t('welcomeloginscreen')}
                </Heading>
                <Divider m={'10px 0'} w={'20%'}></Divider>
                <Text>
                {t('welcomescreentext')}
                </Text>
              </Flex>
            </Flex>
          </Flex>

        </Box>
        <Footer />
      </Flex>

    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
