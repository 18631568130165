import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import ReactDOMServer from 'react-dom/server';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { Text, useColorModeValue, SimpleGrid, Button, Flex, Icon, Box, Spinner, transition } from "@chakra-ui/react";
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdReplay,
    MdFlag,
    MdPlayCircleOutline
} from "react-icons/md";
import moment from 'moment';
import 'moment/locale/tr';
import { FullscreenControl } from 'react-leaflet-fullscreen';
const { BaseLayer } = LayersControl;
function ChildComponent({ zoomLevel, centerCoordinates }) {
    const map = useMap(); // Access the map instance using useMap()
    console.log(zoomLevel, " ", centerCoordinates);
    // You can now use the map instance to perform operations
    // For example, to set the view to a specific location
    map.setView(centerCoordinates);

    return null;
}

function MapComponent({ zoomLevel, centerCoordinates, logoUrl, location, timeSpan }) {
    const [transitionValue, setTransitionValue] = useState('all 1s ease;');
    const [mapZoom, setMapZoom] = useState(13); 
    const [route, setRoute] = useState([]);
    const [routes, setRoutes] = useState([]); // Rota: [[latitude, longitude, speed], ...]
    const [carPositionIndex, setCarPositionIndex] = useState(0); // Arabanın bulunduğu konumun indeksi
    const [animationEnabled, setAnimationEnabled] = useState(false); // Animasyon durumu
    const [carPosition, setCarPosition] = useState([0, 0]);

    const markerIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Box bg={'rgba(34,139,34, 0.3)'} borderRadius={'50%'} p={'10px'} color={'white'} ></Box>
        ),
        className: 'marker-icon',
    });
    const startIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdNotStarted} />
        ),
        className: 'marker-icon',
    });
    const endIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdFlag} />
        ),
        className: 'marker-icon',
    });
    const myStyle = {
        transition: 'all 1s ease'
      };
    const carIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdOutlineLocalTaxi} />
        ),
        className: 'marker-icon marker-transition',
     myStyle
    });
    const handleZoomChange = (e) => {
        setMapZoom(e.target._zoom); // Harita zoom seviyesini güncelle
    };
    const getColorForRoute = (route) => {
        for (let i = 0; i < route.length; i+1) {
        const thirdIndexValue = route[i][2]; // 3. index'teki değeri al
        // Renk için koşullar belirle, örneğin:
        if (thirdIndexValue) {
          return 'forestgreen';
        }  else {
          return 'orange';
        }
    }
      };
    return (
        <Box w={'100%'}>
            <MapContainer center={centerCoordinates} zoom={mapZoom} style={{ height: '30vh', width: '100%', zIndex: '0', borderRadius: "10px" }} zoomControl={false} onZoomend={handleZoomChange}>
                <LayersControl position="topright">
                    <BaseLayer checked name="Varsayılan Harita">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Uydu Görünümü">
                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar">
                        <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar (Uydu)">
                        <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                </LayersControl>
          {location != null ?
          <>
          <ChildComponent centerCoordinates={[location.Latitude, location.Longitude]} />
           <Marker position={[location.Latitude, location.Longitude]} icon={carIcon}>
              <Popup >
                <Flex flexDirection={'column'}>
                  <Text as={'h5'} margin={'4px 0'}><b>Cihaz Seri No: </b> {location.DeviceID}</Text>
                  <Text as={'h5'} m={'4px 0'}><b>Tarih Saat: </b> {moment(location.TimeReal).format('DD.MM.YYYY - HH:mm')}</Text>
                  <Text as={'h5'}><b>Araç Hızı: </b> {Math.round(location.Speed * 1.60934)} Km/h</Text>


                </Flex>

              </Popup>
                    </Marker>
          </>
: ''
          }
                   
   
        <FullscreenControl position="bottomleft" /> 
            </MapContainer>

        </Box>


    );

}

export default MapComponent;
