// Chakra imports
import {
    Text,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    Flex
} from "@chakra-ui/react";
// Assets
import { useTranslation } from 'react-i18next';
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import Project from "./StatusCard";
import {
    MdBarChart,
    MdPerson,
    MdHome,
    MdLock,
    MdLocalTaxi,
    MdOutlineShoppingCart,
} from "react-icons/md";
import { PiWarningCircleFill } from "react-icons/pi";
export default function Projects({ onFilterChange, data, mini, brokens }) {
    // Chakra Color Mode
    const { t, i18n } = useTranslation();
    const [deviceStatus, setDeviceStatus] = useState('');
    const [filterParams, setFilterParam] = useState({
        'rented': false,
        'close': false,
        'avaliable': false,
        'allDevices': true
    });
    const analys = data.TotalTaximeterCount;
    const empty = Math.round(data.FreeModeCount / data.TotalTaximeterCount * 100);
    const full = Math.round(data.RentedModeCount / data.TotalTaximeterCount * 100);
    const close = Math.round(data.ClosedModeCount / data.TotalTaximeterCount * 100);
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    function CustomButton() {
        const { isOpen, onOpen, onClose } = useDisclosure();

        const handleClick = async () => {
            onOpen();
        };

        return (

            <>
                <Icon onClick={handleClick} pos={'absolute'} className="zoom-div" color={'red'} w={8} h={8} as={PiWarningCircleFill} />


                <Modal scrollBehavior={'inside'} size={'4xl'} isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay bg='none'
                        backdropFilter='blur(10px) ' />
                    <ModalContent border={'6px solid rgba(0, 128, 128, 0.2)'}>


                        <ModalBody >
                            {
                                brokens.map((item, index) => {
                                    return <Flex columnGap={'10px'} alignItems={'center'} borderBottom={'1px solid black'}>
                                        <Text>{index + 1}</Text>
                                        <Text>{item.Plate}</Text>
                                        <Text>{item.DeviceId}</Text>
                                    </Flex>
                                })
                            }


                        </ModalBody>

                    </ModalContent>
                </Modal>
            </>
        );
    }
    const handleFormValues = (value, item) => {
        // Değerleri ana bileşene iletmek için prop olarak geçirilen fonksiyonu çağırın
        onFilterChange({ 'DeviceStatus': value });
        switch (value) {
            case 'Rented':
                setFilterParam({
                    'rented': true,
                    'close': false,
                    'avaliable': false,
                    'allDevices': false
                })
                break;
            case 'Free':
                setFilterParam({
                    'rented': false,
                    'close': false,
                    'avaliable': true,
                    'allDevices': false
                })
                break;
            case 'Closed':
                setFilterParam({
                    'rented': false,
                    'close': true,
                    'avaliable': false,
                    'allDevices': false
                })
                break;
            case '':
                setFilterParam({
                    'rented': false,
                    'close': false,
                    'avaliable': false,
                    'allDevices': true
                })
                break;
        }
    };
    return (
        <>
            <SimpleGrid w={'100%'} display={mini ? { base: 'none', md: 'grid' } : 'grid'} m={mini ? '8px ' : 'auto auto 2%'} spacing={{ sm: 2, md: 2, xl: 4 }} templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }} >
                <Project
                    boxShadow={cardShadow}
                    icon={MdLocalTaxi}
                    color='#0181cB'
                    info={data.TotalTaximeterCount}
                    title={t('TotalTaximeterCount')}
                    onClick={(e) => handleFormValues('', e)}
                    minimize={mini}
                    selected={filterParams.allDevices}
                />
                <Project
                    boxShadow={cardShadow}
                    icon={MdLocalTaxi}
                    color='forestgreen'
                    info={data.RentedModeCount}
                    data={full}
                    title={t('RentedModeCount')}
                    onClick={(e) => handleFormValues('Rented', e)}
                    minimize={mini}
                    selected={filterParams.rented}
                />
                <Project
                    boxShadow={cardShadow}
                    icon={MdLocalTaxi}
                    color='#ffc107'
                    info={data.FreeModeCount}
                    data={empty}
                    title={t('FreeModeCount')}
                    onClick={(e) => handleFormValues('Free', e)}
                    minimize={mini}
                    selected={filterParams.avaliable}
                />
                <Project
                    boxShadow={cardShadow}
                    icon={MdLocalTaxi}
                    color="crimson"
                    info={data.ClosedModeCount}
                    data={close}
                    title={t('ClosedModeCount')}
                    onClick={(e) => handleFormValues('Closed', e)}
                    minimize={mini}
                    selected={filterParams.close}
                />
            </SimpleGrid>
            {brokens.length > 0 ? <CustomButton /> : ''}
        </>

    );
}
