import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    createStandaloneToast
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/parstaksilogo.png";
import React, { useState, useEffect } from 'react';
import { FilterBar } from "./components/FilterBar";
import MapComponent from "./components/MapComponent";
// import MapContent from "./components/MapContent";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import moment from 'moment';
import { format } from 'date-fns';
import {  CitiesLocations} from "variables/CityLocations";
const taxiDeviceService = new TaxiDeviceService();
export default function LocationReports() {
    const userData = JSON.parse( localStorage.getItem('UserData'));
    var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) -1)
    const city = CitiesLocations[cityIndex];
    var logoUrl = `<img src="${logoWhite}"/>`
    const [filterValue, setFilterValue] = useState({});
    const [showData, setShowTrips] = useState(false);
    const [noData, setNoData] = useState(false);
    const [tripData, setTaxiTrips] = useState({});
    const toast = createStandaloneToast();
    const [position, setMapPosition] = useState(city);
    const [locations, setLocations] = useState({});
    moment.locale('tr');
  
    const fetchDataAndSetState = async (deviceID, dateRange) => {
      setShowTrips(false);
      setNoData(false)
      var result = await taxiDeviceService.GetLocationHistory(deviceID,dateRange[0], dateRange[1]);
  
  
  
      setLocations(result);
      console.log(tripData)
      if (result != null) {
        setShowTrips(true);
      }
      else{
        setNoData(true)
      
      }
  
    }
    const handleClickToast = (position, status, message) => {
      toast({
        description: message,
        status: status,
        duration: 3000,
        position: position,
        isClosable: true,
      });
    };
    // var trips = tripData.map(o => o.Trips);
    // var totalDistance = 0;
    // var totalTime = 0;
    // var totalPrice = 0;
    // trips.forEach(item => {
    //   totalDistance += item[0].RentedTripDistance;
    //   totalPrice += item[0].RentedTripFare + item[0].RentedTripExtras;
    //   totalTime += item[0].EndOfRentedTrip - item[0].StartOfRentedTrip;
    // });
    const setFilterValueForRequest = async (value) => {
      setShowTrips(false);
      console.log(value)
      if (value.DeviceId != '' && value.DateRange[0] != null && value.DateRange[1] != null) {
        setFilterValue(value);
       await fetchDataAndSetState(value.DeviceId, value.DateRange);
      }
      else {
  
        handleClickToast("top", "warning", 'Lütfen Tüm Değerleri Doldurunuz!')
      }
  
    };
    return (
        <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
                  <FilterBar buttonStatus={showData || noData} onFilterChange={setFilterValueForRequest} ></FilterBar>

                  <MapComponent zoomLevel={13} centerCoordinates={position} logoUrl={logoUrl} locations={locations}></MapComponent>
        </Box>

    );
}