const Permissions = {
    0:'none',
    1: 'DisableUser',
    2: 'AddUser',
    4: 'DeleteUser',
    8: 'ShowUsers',
    16: 'ShowOwnTrips',
    32: 'ShowTaxiStatus',
    64: 'SubscribeTaxiInfoNotification',
    128: 'NotifyTaxiStatus',
    256: 'NotifyTaxiLocation',
    512: 'NotifyTrip',
    1024: 'AssignTaximeterToCity',
    2048: 'AssignTaximeterToUser',
    4096: 'ShowTripLocations',
    8192: 'ShowTaximetersOfCity',
    16384: 'ShowTaxiLocations',
    32768: 'EditUser',
    65536: 'ShowUsersOfCity',
    131072: 'ShowTaximetersOfUser',
    262144: 'ShowTaxiFares',
    524288: 'ShowUserTrips',
    1048576: 'ShowTaxiDailyInfo',
    2097152: 'EditCityUser',
    4194304: 'ShowUsersProfile',
    8388608: 'CreateStation',
    16777216: 'AssignTaximeterToStation',
    33554432: 'ShowStationsOfCity',
    536870912:'ShowProductionRecords'
    // İstediğiniz kadar rolü ekleyebilirsiniz...
};

export default Permissions;