// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Badge,
  Text,
  Divider ,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { useTranslation } from 'react-i18next';
import Menu from "components/menu/MainMenu";
import StationService from "Services/StationService/StationService";
import React from "react";
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import ReactDOMServer from 'react-dom/server';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import L, { MarkerCluster } from 'leaflet';
import {
  MdOutlinePauseCircle,
  MdNotStarted,
  MdOutlineLocalTaxi,
  MdReplay,
  MdFlag,
  MdPlayCircleOutline,
  MdChevronLeft,
  MdChevronRight,
  MdStore
} from "react-icons/md";
// Assets
function secondsToDHMS(seconds) {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
{
      days:days,
      hours: hours,
      minutes :minutes,
      seconds: remainingSeconds}
  );
}
const stationService = new StationService();
const { BaseLayer } = LayersControl;
export default function Banner(props) {
  const { used, total, description, packages, title,icon, types } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  var logoUrl = `<img src="${logoWhite}"/>`
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const badgeBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { t, i18n } = useTranslation();
  const [stationInfo, setStationInfo] = React.useState(null);
  var usedTime = secondsToDHMS(used);
  var totalTime = secondsToDHMS(total);
  const fetchstationlocation = async () => {
    var mystation = await stationService.MyStations();
    setStationInfo(mystation.Data);
};
React.useEffect(() => { 
  fetchstationlocation();

}, []);
const StationIcon = () => {

  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <Flex textAlign={'center'} color={'white'} alignItems={'center'} opacity={'0.9'} >
        <Icon bg={'#0181cB'} p={'8px'} borderRadius={'50% '} fontSize={'5em'} as={MdStore} pos={'relative'} top={'-30px'} left={'-10px'} />

      </Flex>

    ),
    className: 'marker-icon marker-transition',
  });
}
      return (
<Card mb={{ base: "0px", lg: "20px" }} align='left'>
<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mb='10px' textTransform={'capitalize'}>
Durak Bilgileri
      </Text>
{stationInfo != null ?  <>
<Flex h={'100%'} columnGap={'20px'}>
<Flex flexDirection={'column'} w={'30%'}>
<Divider />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='md' mt='10px' textTransform={'capitalize'}>
  {t('stationname')+ ": "}
      </Text>
      <Text color={textColorPrimary} fontWeight='500' fontSize='md' m={'0'} textTransform={'capitalize'}>
  {stationInfo[0].StationName}
      </Text>
      <Text color={textColorPrimary} fontWeight='bold' fontSize='md' mt='10px' textTransform={'capitalize'}>
  {t('stationaddress')+ ": "}
      </Text>
      <Text color={textColorPrimary} fontWeight='500' fontSize='md' m={'0'} >
  {stationInfo[0].Address}
      </Text>
</Flex>
<MapContainer  center={[stationInfo[0].Location.coordinates[0], stationInfo[0].Location.coordinates[1]]} zoom={15} style={{ height: '100%', width: '80%', zIndex: '0' }} zoomControl={false} >

<LayersControl position="topright">
    <BaseLayer checked name="Varsayılan Harita">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={logoUrl} />
    </BaseLayer>
    <BaseLayer name="Uydu Görünümü">
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution={logoUrl} />
    </BaseLayer>
    <BaseLayer checked name="Google Haritalar">
        <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
    </BaseLayer>
    <BaseLayer name="Google Haritalar (Uydu)">
        <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
    </BaseLayer>
</LayersControl>
{stationInfo.map((location) => (
        location != null && location.Location != null ?
            <Marker key={location.UserAccountId} position={[location.Location.coordinates[0], location.Location.coordinates[1]]} icon={StationIcon()}
            >
            </Marker>
            : ""
    ))}




</MapContainer>
</Flex>
</>  : '' } 

    </Card>
  );

}
