import React, { useEffect, useState, useRef } from 'react';
import { Player } from '@lordicon/react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import CityAdminLayout from 'layouts/cityadmin';
import DealerLayout from 'layouts/dealer';
import UserLayout from 'layouts/user';
import StationLayout from 'layouts/station';
import NewsCenterLayout from 'layouts/newscenter';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider, Flex, Spinner, Image, Progress } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import AuthService from "Services/AuthService/AuthService";
import logoWhite from "assets/img/layout/parstaksilogo.png";
import i18n from 'i18n';
const authService = new AuthService();
const ICON = require('./lock.json');
const lang = localStorage.setItem('language', 'tr');
// Örnek bir giriş yapmamış kullanıcıya yönlendirme işlevi
const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
	const [userData, setUserData] = useState(null);
	const playerRef = useRef(null);


	useEffect(() => {
		const fetchData = async () => {
			const data = await authService.getUserData();
			setUserData(data);
		};
		fetchData();
	}, []);

	if (!authService.isAuthenticated()) {
		return (
			<Route {...rest} render={(props) =>

				<Redirect to="/auth/login" />

			} />
		)
	}
	else if (!userData) {
		return <Flex width={'100vw'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
			<Flex flexDirection={'column'} alignItems={'center'}>
				<Image src={logoWhite} w={'14rem'} />
				<Progress size='xs' isIndeterminate colorScheme='twitter' mt={'4px'} w={'12rem'} />
			</Flex>

		</Flex>;
	}

	localStorage.setItem('UserData', JSON.stringify(userData));
	const { Role } = userData;

	return (
		<Route {...rest} render={(props) =>

			allowedRoles.includes(Role) ? (
				<Component {...props} />
			) : (
				<Redirect to="/" />
			)

		} />
	)


};

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<Router>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<PrivateRoute path={`/admin`} component={AdminLayout} allowedRoles={[1]} />
						<PrivateRoute path={`/newscenter`} component={NewsCenterLayout} allowedRoles={[128]} />
						<PrivateRoute path={`/cityadmin`} component={CityAdminLayout} allowedRoles={[2]} />
						<PrivateRoute path={`/dealer`} component={DealerLayout} allowedRoles={[4]} />
						<PrivateRoute path={`/user`} component={UserLayout} allowedRoles={[8]} />
						<PrivateRoute path={`/station`} component={StationLayout} allowedRoles={[32]} />
						<Redirect from='' to='./auth/login' />

					</Switch>
				</Router>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
