// Chakra imports
import {
  Box, Text, Link, Flex, Modal, ModalOverlay, ModalContent, ModalFooter, Button, ModalBody, Input,
  Select,
  Divider,
  FormControl,
  Thead,
  Tr,
  FormLabel,
  InputGroup, useColorModeValue, createStandaloneToast, useDisclosure
} from "@chakra-ui/react";
import UserService from "Services/UserService/UserService";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { useTranslation } from 'react-i18next';
const userService = new UserService();
const handleClickToast = (position, status, message) => {
  const toast = createStandaloneToast();
  toast({
    description: message,
    status: status,
    duration: 3000,
    position: position,
    isClosable: true,
  });
};
export default function Information(props) {
  const { title, value, action, type, ...rest } = props;
  const { t, i18n } = useTranslation();
  // Chakra Color Mode
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newpasswordReTyped, setNewPasswordReTyped] = React.useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  var innerModal;
  switch (type) {
    case 'password':
      innerModal = <InputGroup flexWrap={'wrap'} borderRadius={'4px'}>
        <Text as={'b'} fontSize={'lg'} my={'10px'}>{t('changepassword')}</Text>
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          width={{ base: "100%" }}
          ms={{ base: "0px", md: "0px" }}
          type='password'
          placeholder={t('currentpassword')}
          mb='24px'
          fontWeight='500'
          size='lg'
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          width={{ base: "100%" }}
          ms={{ base: "0px", md: "0px" }}
          type='password'
          placeholder={t('newpassword')}
          mb='24px'
          fontWeight='500'
          size='lg'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          width={{ base: "100%" }}
          ms={{ base: "0px", md: "0px" }}
          type='password'
          placeholder={t('newpasswordretyped')}
          mb='24px'
          fontWeight='500'
          size='lg'
          value={newpasswordReTyped}
          onChange={(e) => setNewPasswordReTyped(e.target.value)}
        />
      </InputGroup>
      break;

    case 'phone':
      innerModal = <InputGroup>
        <Input
          isRequired={true}
          variant='auth'
          fontSize='sm'
          width={{ base: "100%", md: "49%" }}
          ms={{ base: "0px", md: "0px" }}
          type='text'
          placeholder={t('phone')}
          mb='24px'
          fontWeight='500'
          size='lg'
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

      </InputGroup>
      break;
  }
  const updateAccountInfo = async () => {
    switch (type) {
      case 'password':
        var model = JSON.stringify({
          "CurrentPassword": currentPassword,
          "NewPassword": newPassword,
          "NewPasswordRetyped": newpasswordReTyped
        })
        var result = await userService.ChangePassword(model);
        if (result.Code == 100) {
          handleClickToast("top-right", "success", t(result.Code))
          onClose()
        }
        else {
          if (result.Code == 3) {
            result.ValidationErrors.forEach(o => {
              handleClickToast("top-right", "error", o)
            })

          }
          handleClickToast("top-right", "error", t(result.Code))
        }
        break;

      default:
        break;
    }
  }
  return (
    <>
      <Modal scrollBehavior={'inside'} size={'md'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='none'
          backdropFilter='blur(10px) ' />
        <ModalContent border={'6px solid rgba(0, 128, 128, 0.2)'} >


          <ModalBody >

            {innerModal}

          </ModalBody>
          <ModalFooter columnGap={'10px'}>
            <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="teal" onClick={() => updateAccountInfo()}  >{t('change')}</Button>

            <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="yellow" onClick={onClose}>{t('close')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card bg={bg} {...rest}>
        <Box>
          <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
            {title}
          </Text>
          <Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <Text color={textColorPrimary} fontWeight='500' fontSize='sm'>
              {value}
            </Text>
            <Link onClick={onOpen} color='teal.500' href='#' fontSize='sm'> {action}</Link>
          </Flex>

        </Box>
      </Card>
    </>

  );
}
