/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import config from 'config';
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Image,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    createStandaloneToast,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Card from "components/card/Card.js";
// Assets
import { GrUpdate } from "react-icons/gr";
import logoBlack from "assets/img/layout/parstaksilogo.png";
import bgimage from "assets/img/auth/updatebg.jpg";
function Update() {
    const history = useHistory();
    useEffect(() => {
        if (!config.UpdateMode) {
            history.push('/auth/login');
        }
    }, [])
    return (
        <Box w={'100vw'} h={'100vh'} background={`url("${bgimage}")`} backgroundSize={'cover'} bgColor={'rgba(255,255,255,0.8)'} bgBlendMode={'color'} >
            <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'center'}>
                <Card bg='rgba(255,255,255,0.5)' width={{ base: "94%", md: "calc(50% - 0.5rem)" }} textAlign={'center'} border={'1px solid rgba(59,130,246,.8) '} boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'}>
                    <Icon h={14} w={14} as={GrUpdate} className="rotating-div" m={'auto'} mb={'10px'}/>
                    <Text mb={'10px'} as={'b'} fontSize={'4xl'} >Bakım Çalışması</Text>
                    <Text mb={'10px'} as={'p'} fontSize={{ base: "md", md: "xl" }} textAlign={'center'}>Size daha iyi hizmet verebilmek için sistemlerimizde güncellemeler yapıyoruz, en yakın zamanda tekrar aktif olacağız.<br></br> Beklediğiniz İçin Teşekkür Ederiz.</Text>
                    <Image objectFit={'cover'} src={logoBlack} w='200px' m={'auto'} />

                </Card>
            </Flex>


        </Box>
    );
}

export default Update;
