import React, { Component } from 'react';
import axios from 'axios';
import config from 'config';

class AdminService {
  constructor() {

  }

  async GetAdminHome() {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.Home}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data.Data;
    } else {
      return null;

    }
  }
  async GetWaitinfForApprovalUsers() {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.WaitingForApprovalUsers}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data.Data;
    } else {
      return null;

    }
  }
  async GetActivateUser(userID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.ActivateUser}?userId=${userID}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data;
    } else {
      return null;

    }
  }
  async GetLocationHistoryForCityAdmin(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/CityAdmin/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime,  "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetLocationHistory(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/Location/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime,  "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetLocationHistoryForCity(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/CityAdmin/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime,  "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetCityDeviceLocation(cityCode) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/TaximetersByCity?cityCode=${cityCode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetCityDeviceDailyInfo(deviceID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/CityAdmin/TaximeterDailyInfo?DeviceID=${deviceID}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data;
    } else {
      return null;

    }
  }
  async ProductionRecords(pageNum, searchText, pageSize , sort) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    // Giriş işlemi için API'ye istek gönder
    const response = await fetch(config.baseURL + config.endpoints.ProductionRecords, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'PageNumber': pageNum, 'SearchText': searchText, 'PageSize': pageSize, 'SortDefinition': sort})
      });
    const data = await response.json();

        return data;
 
}
}

export default AdminService;
