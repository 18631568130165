// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import { useHistory } from 'react-router-dom';
// FUNCTIONS

function SidebarContent(props) {
  const { routes, onCloseClick } = props;
  const history = useHistory();
  const handleLogout = () => {
		// Token ve diğer bilgileri localStorage'den temizle
		localStorage.removeItem('token');
		localStorage.removeItem('expireTime');
		localStorage.removeItem('UserData');
		// Diğer logout işlemlerini gerçekleştir (isteğe bağlı)

		// Kullanıcıyı login sayfasına yönlendir
		history.push('/auth/login');
	};
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} CloseClick={onCloseClick} />
        </Box>
      </Stack>

      <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
