import {
  Flex,
  Table,
  Progress,
  Grid,
  Button,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Input,
  Select,
  Divider,
  FormControl,
  Thead,
  Tr,
  FormLabel,
  InputGroup,
  useColorModeValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure,
  createStandaloneToast
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from 'moment';
import 'moment/locale/tr';
import UserService from "Services/UserService/UserService";
import { useTranslation } from 'react-i18next';
// Custom components
import Card from "components/card/Card";
import Menu from "./MainMenu";
import UserEditForm from "./DetailEditForm";
import TaxiAssignForm from "./TaximeterAssign";
import TaximeterAssignForStation from "./TaximeterAssignForStation";
import { CitiesLocations } from "variables/CityLocations";
import Roles from "variables/Roles";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdEdit, MdFormatListBulleted, MdOutlineInfo, MdOutlineLocalTaxi } from "react-icons/md";
import CityAdminService from "Services/CityAdminService/CityAdminService";
import StationService from "Services/StationService/StationService";
const handleClickToast = (position, status, message) => {
  const toast = createStandaloneToast();
  toast({
    description: message,
    status: status,
    duration: 3000,
    position: position,
    isClosable: true,
  });
};
const cityAdminService = new CityAdminService();
const userService = new UserService();
const stationService = new StationService();
export default function ColumnsTable(props) {
  const { columnsData } = props;
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState({});
  const [sizePage, setPagesizes] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchParam] = useState('');
  const { t, i18n } = useTranslation();
  const fetchDataAndSetState = async () => {

    var result = await userService.ListCityUsers(pageNumber, searchText, sizePage, {
      "SortBy": 'Name',
      "Descending": false
    });
    setTableData(result.Data);
    setUsers(result.Data.Users);

  }
  useEffect(() => {

    fetchDataAndSetState();

  }, [sizePage, pageNumber, searchText]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => users, [users]);
  function CustomButton({ type, userId, adress, deviceID, tripID }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [position, setMapPosition] = useState([40.762200, 29.940733]);
    const [locations, setLocations] = useState([]);
    const [userNewDevices, setUserNewDevices] = useState([]);
    const [userremoveDevices, setUserRemoveDevices] = useState([]);
    const [params, setParam] = useState({});
    const [model, setUserEdittedModel] = useState('');
    const [edit, setEdit] = useState(true);
    const [force, setforce] = useState(false);
    var selectedUser = users.filter(f => f.UserId == userId)[0]
    var icon;
    var colorTheme;
    var text;
    var innerModal;
    var assingModal;
    var detailAndEditUser = <UserEditForm selectedUser={selectedUser} edit={edit} edittedUserModel={setUserEdittedModel} />;
    if (selectedUser.Role == 32) {
      assingModal = <TaximeterAssignForStation selectedUser={selectedUser} edit={edit} onAddDevices={setUserNewDevices} onRemoveDevices={setUserRemoveDevices} />;
    }
    else {
      assingModal = <TaxiAssignForm selectedUser={selectedUser} edit={edit} onAddDevices={setUserNewDevices} onRemoveDevices={setUserRemoveDevices} onForce={setforce} />;
    }
    switch (type) {
      case 'detail':
        icon = MdFormatListBulleted;
        text = t('detail');
        colorTheme = 'twitter';
        innerModal = userId ? detailAndEditUser : '';
        break;
      case 'assign':
        icon = MdOutlineLocalTaxi;
        text = t('taxiaddremove');
        colorTheme = 'yellow';
        innerModal = userId ? assingModal : '';
        break;
    }
    console.log(userNewDevices)
    const handleClick = async () => {
      onOpen();
    };
    const handleAssign = async () => {
      var controltext= "";
      switch (type) {
        case 'detail':
          var result = await cityAdminService.EditUser(model);
          break;
        case 'assign':
          if (selectedUser.Role == 32) {
            var stationID = await stationService.StationsOfUser(selectedUser.UserId);

            if (userNewDevices.length > 0) {
              var result = await cityAdminService.AssignDevicesToStation(userNewDevices, stationID.Data[0].Id);
            }
            if (userremoveDevices.length > 0) {
              var result = await cityAdminService.UnAssignDevicesToStation(userremoveDevices, stationID.Data[0].Id);
            }
          }
          else {
            if (userNewDevices.length > 0) {
              var result = await cityAdminService.AssignDevicesToUser(userNewDevices, selectedUser.UserId, force);
            }
            if (userremoveDevices.length > 0) {
              var result = await cityAdminService.UnAssignDevicesToUser(userremoveDevices, selectedUser.UserId);
            }
          }


          break;

      }
      if (result.status == 400) {
        result.errors.map(o => {
          handleClickToast("top-right", "error", t(o))
        })
      }
      else if (result.Code == 100) {

        handleClickToast("top-right", "success", t(result.Code))
        onClose();
        fetchDataAndSetState();
      }
      else if (result.Code == 3) {
        result.ValidationErrors.map(o => {
          handleClickToast("top-right", "error", o)
        })
   
      }
      else {
        handleClickToast("top-right", "error", t(result.Code))
      }



    };
    return (

      <>
        { }


        <Button

          colorScheme={colorTheme}

          onClick={handleClick}
        >
          <Icon marginRight={'2px'} as={icon} />
          <Text as={'small'}>  {text}</Text>

        </Button>
        <Modal         scrollBehavior={'inside'} size={'4xl'} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg='none'
            backdropFilter='blur(10px) ' />
          <ModalContent border={'6px solid rgba(0, 128, 128, 0.2)'}>


            <ModalBody >

              {innerModal}

            </ModalBody>
            <ModalFooter columnGap={'10px'}>
              {!edit && <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="teal" onClick={() => handleAssign()} >{t('save')}</Button>}
              <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="linkedin" onClick={() => setEdit(!edit)}>{edit ? t('edit') : t('closeedit')}</Button>
              <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="yellow" onClick={onClose}>{t('close')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // İlk sayfa yüklemesi
    },
    useSortBy,
    usePagination
  );
  const changePageSize = (value) => {
    setPageSize(value)
    setPagesizes(value)
  }
  const changePageNumber = (value) => {
    switch (value) {
      case 'next':
        setPageNumber(pageNumber + 1)
        break;
      case 'prev':
        setPageNumber(pageNumber - 1)
        break;

    }
  }
  const handleSearch = (e) => {
    setSearchParam(e);
  };
  const [inputValue, setInputValue] = useState('');
  
  const handleInputChange = (event) => {
    let timeoutId;
    const value = event.target.value;
    setInputValue(value);
    clearTimeout(timeoutId); // Her input değişiminde varsa önceki setTimeout'u temizle
    timeoutId = setTimeout(handleSearch(value), 2000); // 2 saniye sonra handleTyping fonksiyonunu çalıştır
    return () => clearTimeout(timeoutId); // ComponentWillUnmount'da timeout'u temizle
  };


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  var pageNum = users.length == 0 ? 0 : pageNumber;
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
    >
      <Flex px='25px' justify='space-between' mb='10px' flexWrap={'wrap'} rowGap={'10px'}>
        <Flex align='center' columnGap={'2px'} w={{ base: '100%', md: '70%' }}>
          <Icon as={MdOutlineInfo} />
          <Text
            color={textColor}
            fontSize='1em'
            fontWeight='500'
            mb='0px'
            lineHeight='100%'>
            {t('usermanagementtext')}
          </Text>
        </Flex>

        <Menu w={{ base: '100%', md: '30%' }} />
        <Flex justifyContent={'flex-end'} w={{ base: '100%', md: '100%' }}>
          <FormControl w={{ base: '100%', md: '20%' }}>
            <Input id='first-name' placeholder={t('search')} onChange={handleInputChange} value={inputValue}/>
          </FormControl>
        </Flex>
      </Flex>

      <Flex className="table-container" css={{ /* Özel scrollbar stilini uygular */
        '&::-webkit-scrollbar': {
          width: '80%',
          height: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'lightgray',
          borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'teal',
        },
      }}>

        <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px' maxH={'10vh'} overflowY={'scroll'} >
          <Thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {t(column.render("Header"))}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </Flex>

                  </Th>
                ))}
              </tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}  >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "tablename") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "tablesurname") {

                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "tablestatus") {
                      data = (
                        <Flex align='center'>
                          <Icon
                            w='24px'
                            h='24px'
                            me='5px'
                            color={
                              cell.value === "Active"
                                ? "green.500"
                                : cell.value === "NotActivated"
                                  ? "red.500"
                                  : cell.value === "Error"
                                    ? "orange.500"
                                    : null
                            }
                            as={
                              cell.value === "Active"
                                ? MdCheckCircle
                                : cell.value === "NotActivated"
                                  ? MdCancel
                                  : cell.value === "Error"
                                    ? MdOutlineError
                                    : null
                            }
                          />
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value === "Active"
                              ? t('approved')
                              : cell.value === "NotActivated"
                                ? t('notconfirmed')
                                : cell.value === "Error"
                                  ? MdOutlineError
                                  : null}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "tablephone") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{cell.value}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "username") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{cell.value}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "tablerole") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{t(Roles[cell.value])}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "tablemanage") {
                      data = (
                        <Flex columnGap={'4px'}>
                          <CustomButton type={'assign'} userId={cell.value} />
                          <CustomButton type={'detail'} userId={cell.value} />
                        </Flex  >
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>


      </Flex>
      <Flex
        m={{ base: '20px 10px 0', md: '20px 20px 0' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        rowGap={'20px'}
      >
        <Flex w={{ base: '100%', md: 'unset' }} alignItems={'center'} columnGap={'20px'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Text w={{ base: 'calc(50% - 10px)', md: 'unset' }}>{t('totalrecord')}: {(tableData != null ? tableData.TotalDocumentCount : 0)}</Text>
          <div w={{ base: 'calc(50% - 10px)', md: 'unset' }}>
            <Select
              size="sm"
              value={pageSize}
              onChange={e => {
                changePageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Satır
                </option>
              ))}
            </Select>
          </div>
        </Flex>
        <Flex alignItems={'center'} w={{ base: '100%', md: 'unset' }} justifyContent={'center'}>
          <Button colorScheme="facebook" size="sm" onClick={() => changePageNumber('prev')} disabled={pageNumber == 1}>
            {t('prev')}
          </Button>
          <Text as={'span'} style={{ margin: '0 10px' }}>
            {pageNum} / {Math.ceil((tableData != null ? tableData.TotalDocumentCount : 0) / pageSize)}
          </Text>
          <Button colorScheme="facebook" size="sm" onClick={() => changePageNumber('next')} disabled={pageNum == Math.ceil((tableData != null ? tableData.TotalDocumentCount : 0) / pageSize)}>
            {t('next')}
          </Button>
        </Flex>
      </Flex>

    </Card>
  );
}
