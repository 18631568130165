import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  createStandaloneToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import { FilterBar } from "./components/FilterBar";
import { TariffReportItem } from "./components/TariffReportItem";
import { TotalTariffReports } from "./components/TotalTariffReports";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import moment from 'moment';
import { format } from 'date-fns';
const taxiDeviceService = new TaxiDeviceService();
export default function LocationReports() {
  const [filterValue, setFilterValue] = useState({});
  const [showData, setShowTrips] = useState(false);
  const [buttonstts, setButtonStatus] = useState(false);
  const [tripData, setTaxiTrips] = useState({});
  const [noData, setNoData] = useState(false);
  const toast = createStandaloneToast();

  moment.locale('tr');

  const fetchDataAndSetState = async (deviceID, dateRange) => {
    setShowTrips(false);
    setNoData(false)
    setButtonStatus(true)

    var result = await taxiDeviceService.GetTaxiTrips(deviceID, dateRange[0] ,dateRange[1]);



    setTaxiTrips(result);
    console.log(tripData)
    if (result != null) {
      setShowTrips(true);
    }
    else{
      setNoData(true)
    
    }
    setButtonStatus(false)
  }
  const handleClickToast = (position, status, message) => {
    toast({
      description: message,
      status: status,
      duration: 3000,
      position: position,
      isClosable: true,
    });
  };
  // var trips = tripData.map(o => o.Trips);
  // var totalDistance = 0;
  // var totalTime = 0;
  // var totalPrice = 0;
  // trips.forEach(item => {
  //   totalDistance += item[0].RentedTripDistance;
  //   totalPrice += item[0].RentedTripFare + item[0].RentedTripExtras;
  //   totalTime += item[0].EndOfRentedTrip - item[0].StartOfRentedTrip;
  // });
  const setFilterValueForRequest = async (value) => {
    setShowTrips(false);
    console.log(value)
    if (value.DeviceId != '' && value.DateRange[0] != null && value.DateRange[1] != null) {
      setFilterValue(value);
     await fetchDataAndSetState(value.DeviceId, value.DateRange);
    }
    else {

      handleClickToast("top", "warning", 'Lütfen Tüm Değerleri Doldurunuz!')
    }

  };
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
      <FilterBar buttonStatus={buttonstts} onFilterChange={setFilterValueForRequest} ></FilterBar>
      {showData && <TotalTariffReports
        rentCount={tripData.TripsCount}
        totalDistance={tripData.TotalDistanceInMeter}
        totalTime={tripData.TotalTimeInSec}
        totalPrice={tripData.TotalAmountInTL}
      ></TotalTariffReports>}
      {showData && <Flex flexDirection={'column'} p={' 0'} borderRadius={'10px'}  overflowY={'auto'} maxHeight={{sm:'80vh',  md:'50vh'}} boxShadow={' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px inset, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px'}>
        <TariffReportItem
          trips={tripData.Trips}
        ></TariffReportItem>
      </Flex>}
      {noData &&<Flex justifyContent={'center'} alignItems={'center'}  m={'1rem 0'} borderRadius={'10px'}  overflowY={'auto'} h={{sm:'80vh',  md:'50vh'}} >
    <Text>Belirtilen tarih aralığında bu araca ait kiralama bulunamadı.</Text>
      </Flex> }
    </Box>

  );
}