import React, { Component } from 'react';
import axios from 'axios';
import config from 'config';

class TaxiDeviceService {
  constructor() {

  }

  // API'den veri almak için bir işlev
  async GetUsersTaxiDeviceList() {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/ListMyDevices`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data;
    } else {
      return null;

    }
  }
  async GetTaxiTrips(deviceID, beginTime, endTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/ListMyTrips`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceID": deviceID, "StartTime": beginTime, "EndTime": endTime }),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data.Data;
    } else {
      return null;

    }
  }
  async GetTaxiTripsForCityAdmin(deviceID, beginTime, endTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/ListTrips`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceID": deviceID, "StartTime": beginTime, "EndTime": endTime }),
    });
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Data: " + data);
      return data.Data;
    } else {
      return null;

    }
  }
  async GetTripsLocations(deviceID, tripID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/Location/TripLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "deviceId": deviceID, "tripId": tripID }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetLocationHistoryForCityAdmin(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/Location/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime, "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetLocationHistory(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/Location/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime, "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetLocationHistoryForCity(deviceID, StartTime, EndTime) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/Location/TaxiLocations`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "DeviceId": deviceID, "StartTime": StartTime, "EndTime": EndTime, "OnlyValidLocations": true }),
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetCityDeviceLocation(cityCode) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/TaximetersByCity?cityCode=${cityCode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      console.log("Trip Location Data: " + data);
      return data.Data;
    } else {
      return [];

    }
  }
  async GetCityDeviceDailyInfo(deviceID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL}/api/TaxiInfo/TaximeterDailyInfo?DeviceID=${deviceID}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data;
    } else {
      return null;

    }
  }
  async GetUnAssignedDevices(cityCode) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.UnAssignedDevices}?cityCode=${cityCode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data;
    } else {
      return [];

    }
  }
  async GetStationUnAssignedDevices(cityCode) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.StationUnAssignedDevices}?cityCode=${cityCode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data;
    } else {
      return [];

    }
  }
  async GetListUserDevices(userID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.ListUserDevices}?userId=${userID}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data.Taximeters;
    } else {
      return [];

    }
  }
  async GetListStationDevices(stationID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.ListStationTaximeters}?stationId=${stationID}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const data = await response.json();
    if (response.ok && data.IsSuccess) {
      return data.Data.Taximeters;
    } else {
      return [];

    }
  }
  async GetTaximeterTestInfo(deviceID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    try {
      const response = await fetch(`${config.baseURL + config.endpoints.TaximeterTest}?deviceId=${deviceID}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response)
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return null;

      }
    } catch (error) {
      return null;
    }

  }
  async GetTaximeterInfoForTest(deviceID) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    try {
      const response = await fetch(`${config.baseURL + config.endpoints.TaximeterInfoForTest}?DeviceID=${deviceID}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response)
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return null;

      }
    } catch (error) {
      return null;
    }

  }
  async TestForSearchTrip(model) {
    const token = JSON.parse(localStorage.getItem('authToken')).Token;
    const response = await fetch(`${config.baseURL + config.endpoints.TestForSearchTrip}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: model
    });
    console.log(response)
    const data = await response.json();
    return data;
  }
}

export default TaxiDeviceService;
