// Chakra imports
import config from 'config';
import { Avatar, Box, Flex, Text, useColorModeValue, Spinner, Icon } from "@chakra-ui/react";
import { MdOutlineStar } from "react-icons/md";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import Roles from "variables/Roles";
import { useTranslation } from 'react-i18next';
import { countries } from "variables/Country";
import { CitiesLocations } from "variables/CityLocations";
export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { t, i18n } = useTranslation();
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const [userData, setUserData] = useState(null);
  const userEmail = localStorage.getItem('rememberedUsername')
  const apiUrl = `${config.baseURL}/api/user/myprofile`;
  const token = JSON.parse(localStorage.getItem('authToken')).Token;

  useEffect(() => {
    fetch(apiUrl, {
      method: 'POST', // GET, POST, PUT, DELETE, vb.
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Diğer isteğe bağlı başlıklar
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Global değişkene atama yapın

        setUserData(data.Data);
      })
      .catch(error => {
        console.error('Fetch error:', error.message);
      });
  }, []);
  if (!userData) {
    return (
      <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Flex>
      </Card>
    )
  }
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        name={userData.Name}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {userData.Name} {userData.Surname}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {userData.Email}
      </Text>
      <Text color={textColorPrimary} fontSize='md'  fontWeight='700' display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Icon as={MdOutlineStar} /> {t('role')}: {t(Roles[userData.Role])}
      </Text>
      <Flex w='100%' mx='auto' mt='26px' columnGap={'10px'} justifyContent={'center'}>
        <Flex mx='auto'  align='center' direction='column'>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
          {t('country')}
          </Text>
          <Text color={textColorPrimary} fontSize={{base: 'sm', md:'md'}} fontWeight='700'textTransform={'uppercase'}>
            {countries.filter(f => f.code == userData.Country)[0].name}
          </Text>

        </Flex>
        <Flex mx='auto'  align='center' direction='column'>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
          {t('city')}
          </Text>
          <Text color={textColorPrimary} fontSize={{base: 'sm', md:'md'}} fontWeight='700' textTransform={'uppercase'}>
            {CitiesLocations.filter(f => f.plaka == userData.CityCode)[0].CityName}
          </Text>

        </Flex>
        <Flex mx='auto' align='center' direction='column'>

          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
          {t('district')}
          </Text>
          <Text color={textColorPrimary} fontSize={{base: 'sm', md:'md'}} fontWeight='700' textTransform={'uppercase'}>
            {userData.District}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
