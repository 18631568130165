import {
  Avatar,
  Button,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Text,
  Input,
  Grid 
} from '@chakra-ui/react';
import {
  MdTimeline,
  MdDateRange,
  MdOutlineLocalTaxi,
  MdOutlineTaxiAlert,
  MdAttachMoney,
  MdManageSearch
} from "react-icons/md";
import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import { SelectPicker, Stack } from 'rsuite';
import 'react-datepicker/dist/react-datepicker.css';
const taxiDeviceService = new TaxiDeviceService();
export function FilterBar({ onFilterChange, buttonStatus } ) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [plate, setCarPlate] = useState('');
  const [loading, setButtonLoading] = useState(false);

  const [taxiPlates, setTaxiPlates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      var result = await taxiDeviceService.GetUsersTaxiDeviceList();
      var c = result.map(item => ({ label: item.Plate, value: item.DeviceId }));
      setTaxiPlates(c);
    
    }

    fetchDataAndSetState();
  }, []);
  useEffect(() => {
    setButtonLoading(false)
  }, [buttonStatus]);
  console.log(taxiPlates)
  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ background: "#fff", color: "#000", borderRadius: " 0.375rem" }}>
        <CalendarContainer style={{ borderRadius: " 0.375rem" }}>
          <div style={{ background: "#f0f0f0", padding: "8px", textAlign: "center", borderRadius: " 0.375rem 0.375rem 0 0" }}>
            Görüntülemek istediğiniz tarih aralığını seçiniz.
          </div>
          <div style={{ position: "relative", borderRadius: " 0 0 0.375rem 0.375rem " }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const handleFormValues = () => {
    // Değerleri ana bileşene iletmek için prop olarak geçirilen fonksiyonu çağırın
    setButtonLoading(true)
    if(!plate||!startDate  || !endDate){
      setButtonLoading(false)
    }
    onFilterChange({ 'DeviceId': plate, 'DateRange': [startDate, endDate] });
  };
  return (

    <Flex
      rowGap={'1rem'}
      columnGap={'1.2rem'}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="flex-end "
      flexDirection="row"
      bg={'white'}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      p="15px 30px"
      borderRadius="30px"
    >
      <FormControl>
        <FormLabel htmlFor='country'>Araç Plakası*</FormLabel>
        <Flex
          w={'100%'}
          alignItems={'center'}
          columnGap={'5px'}
          
        >
          <Icon as={MdOutlineLocalTaxi} width='2.5rem' height='2.5rem' color='white' background={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'} p={'8px'} borderRadius={'50%'} />
          <SelectPicker data={taxiPlates} style={{ width: '100%', fontSize:'1rem' }} size="lg" placeholder="Araç Seçiniz" onChange={setCarPlate} />
        </Flex>

      </FormControl>
      <FormControl
        w={'100%'}
      >
        <FormLabel htmlFor='dateRange'>Tarih Aralığı*</FormLabel>
        <Flex
          w={'100%'}
          alignItems={'center'}
          columnGap={'5px'}
          id='daterangeFlex'
        >
          <Icon as={MdDateRange} width='2.5rem' height='2.5rem' color='white' background={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'} p={'8px'} borderRadius={'50%'} />
     
              <Grid 
              templateColumns={{ base: '1fr',md:'2fr 1fr 2fr' }}
              gap={1}
              fontSize={'1rem'}
              border={'1px solid #e5e5ea'} 
              p={'7px 11px'} borderRadius={'6px'}
              justifyContent={'center'}
              alignItems={'center'}
              >
                    <DatePicker
              placeholderText="Başlangıç Tarihi"
              onChange={(date) => setStartDate(date)}
              selectsStart
              selectsRange={false}
              selected={startDate}
              withPortal
              timeIntervals={1}
              showTimeSelect
              
              timeFormat="HH:mm" 
              timeCaption="Saat"
              dateFormat="dd/MM/yyyy HH:mm "

            />
            <Text align={'center'}>-</Text>
            <DatePicker
            align='center'
              placeholderText="Bitiş Tarihi"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsStart
              selectsRange={false}
              timeIntervals={1}
              withPortal
              showTimeSelect
              timeFormat="HH:mm"
              timeCaption="Saat"
              dateFormat="dd/MM/yyyy HH:mm "
            />
              </Grid>
        
          
        </Flex>

      </FormControl>

      <Button isLoading={loading}    loadingText='Veriler Getiriliyor' onClick={handleFormValues} leftIcon={<Icon as={MdManageSearch} width='1.5rem' height='1.5rem' />} colorScheme='teal' variant='solid' w={{ base: '100%', md: '60%' }}>
        Listele
      </Button>
    </Flex>


  );
}