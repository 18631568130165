import React, { useEffect, useRef, useState } from 'react';
import 'assets/css/speedometer.css'
// Meter Component
const Meter = ({ config }) => {
    const needleRef = useRef();
    const valueRef = useRef();

    const value2angle = (value) => {
        let angle = ((value / (config.valueMax - config.valueMin)) * (config.angleMax - config.angleMin) + config.angleMin);
        return angle;
    };

    useEffect(() => {
        const steps = (config.valueMax - config.valueMin) / config.valueStep;
        const angleStep = (config.angleMax - config.angleMin) / steps;
        const $elm = needleRef.current.parentElement;

        const makeElement = (parent, className, innerHtml, style) => {
            let e = document.createElement('div');
            e.className = className;
            if (innerHtml) e.innerHTML = innerHtml;
            if (style) {
                for (let prop in style) {
                    e.style[prop] = style[prop];
                }
            }
            parent.appendChild(e);
            return e;
        };

        makeElement($elm, "label label-unit", config.valueUnit);

        let angle = 0;
        for (let n = 0; n < steps + 1; n++) {
            let value = config.valueMin + n * config.valueStep;
            angle = config.angleMin + n * angleStep;

            let redzoneClass = "";
            if (value > config.valueRed) {
                redzoneClass = " redzone";
            }

            makeElement($elm, `grad grad--${n}${redzoneClass}`, config.labelFormat(value), {
                left: `${50 - (50 - 10) * Math.sin(angle * (Math.PI / 180))}%`,
                top: `${50 + (50 - 10) * Math.cos(angle * (Math.PI / 180))}%`
            });

            makeElement($elm, `grad-tick grad-tick--${n}${redzoneClass}`, "", {
                left: `${50 - 50 * Math.sin(angle * (Math.PI / 180))}%`,
                top: `${50 + 50 * Math.cos(angle * (Math.PI / 180))}%`,
                transform: `translate3d(-50%, 0, 0) rotate(${angle + 180}deg)`
            });
        }

        needleRef.current.style.transform = `translate3d(-50%, 0, 0) rotate(${value2angle(config.value)}deg)`;
        valueRef.current.innerHTML = config.needleFormat(config.value);
    }, [config]);

    return (
        <div className="meter">
            <div ref={needleRef} className="needle"></div>
            <div className="needle-axle"></div>
            <div ref={valueRef} className="label label-value">
                <div>{config.labelFormat(config.value)}</div>
                <span>{config.labelUnit}</span>
            </div>
        </div>
    );
};

// Engine Simulator Component
const EngineSimulator = ({speeds}) => {
    const [speed, setSpeed] = useState(220);




    useEffect(() => {


        const interval = setInterval(() => {
            let newSpeed = speed;
            if (speed > 0) {
                newSpeed -= 20;
            }
            setSpeed(newSpeed);
        }, 100);

        return () => clearInterval(interval);
    }, [speed]);

    return (
        <div>
            <Meter
                config={{
                    value: speeds != null ? Math.round(speeds.Speed)  : speed,
                    valueMin: 0,
                    valueMax: 220,
                    valueStep: 20,
                    valueUnit: "<span>Speed</span><div>Km/h</div>",
                    angleMin: 30,
                    angleMax: 330,
                    labelUnit: "Km/h",
                    labelFormat: (v) => Math.round(v),
                    needleFormat: (v) => Math.round(v),
                }}
            />


        </div>
    );
};

export default EngineSimulator;
