import {
    Avatar,
    Button,
    Flex,
    Icon,
    Box,
    SimpleGrid,
    Container,
    Text,
    Spinner,
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import {
    MdTimeline,
    MdMoreTime,
    MdDateRange,
    MdOutlineLocalTaxi,
    MdOutlineRoute,
    MdMap,
    MdOutlineKey,
    MdTimer,
    MdSyncLock,
    MdOutlineLocationOn,
    MdLocationOn
} from "react-icons/md";
import moment from 'moment';
import 'moment/locale/tr';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { GiCarKey } from "react-icons/gi";
import { LuClock4, LuClock12, LuClock9 } from "react-icons/lu";
import { BiSolidCoinStack, BiCoinStack, BiDetail } from "react-icons/bi";
import { FaRoad } from "react-icons/fa6";
import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import ModalMap from './MapModal';
import 'react-datepicker/dist/react-datepicker.css';
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
function GetAddressData(lat, lon) {
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Adres bilgisini almak için uygun olan property'yi seçin

            const address = data.error ? '--------------------' : data.display_name;
            console.log(address)
            return address;
        })
        .catch(error => {
            console.error('There was a problem with the reverse geocoding operation:', error);
            return '-----';
            throw error;
        });


}
function CustomButton({ param, adress, deviceID,tripID }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    var logoUrl = `<img src="${logoWhite}"/>`
    const [position, setMapPosition] = useState([40.762200, 29.940733]);
    const [locations, setLocations] = useState([]);
    const [params, setParam] = useState({});
    const [addressName, setAddressName] = useState({});
 

    const handleClick = async () => {
        setParam(param);
        setAddressName(adress);
        onOpen();
        var result = await taxiDeviceService.GetTripsLocations(deviceID, tripID);
        setLocations(result) //Lokasyon Verileri Alınıyor.
        console.log(locations);
      };
    return (
        <>
            <Button
isLoading={adress != null ? false : true}
                colorScheme='teal'
                variant='solid'
                height={'60px'}
                w={{ base: '100%', md: '8rem' }}
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                textAlign={'center'}
                onClick={handleClick}
            >
                <Icon as={MdMap} width='1.5rem' height='1.5rem' />
                Haritada Gör
            </Button>
            <Modal size={'full'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='none'
                    backdropFilter='blur(10px) ' />
                <ModalContent backgroundColor={'rgba(0,0,0,0.2)'}>


                    <ModalBody>
                        <Flex width={'100%'} columnGap={'10px'} rowGap={'10px'} mt={'2rem'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                            <Flex width={{ base: "100%", xl: "70%" }} borderRadius="10px">
                                <ModalMap zoomLevel={13} centerCoordinates={position} logoUrl={logoUrl} locations={locations} timeSpan={params.RentedEndTimeUTC-params.RentedStartTimeUTC}></ModalMap>
                            </Flex>
                            <Flex
                                width={{ base: "100%", xl: "30%" }}
                                justifyContent={'start'}
                                bg={'white'}
                                p={{ sm: "15px", md: "15px 20px" }}
                                borderRadius="10px"
                                color={'teal'}
                                columnGap={'10px'}
                                flexDirection={'column'}

                            >
                                <Text as={'b'} fontSize={'1.2em'} >{params.RentedStartTime} Tarihli Kiralama Harita Görüntüsü</Text>
                                <Flex justifyContent={'space-between'} my={'10px'}>
                                    <Text ><b>Başlangıç Saati : </b> <span style={{color:"gray"}}>{moment.unix(params.RentedStartTimeUTC).format('HH : mm')}</span></Text>
                                    <Text ><b>Bitiş Saati : </b> <span style={{color:"gray"}}>{moment.unix(params.RentedEndTimeUTC).format('HH : mm')}</span></Text>
                                    <Text ><b>Süre : </b> <span style={{color:"gray"}}>{params.RentedTime}</span> </Text>
                                </Flex>

                                <Text ><b>Başlangıç Adresi : </b><span style={{color:"gray"}}>{addressName.RentedStartLocation }</span> </Text>
                                <Text ><b>Bitiş Adresi : </b><span style={{color:"gray"}}>{addressName.RentedEndLocation }</span> </Text>
                            </Flex>

                        </Flex>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Kapat</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
export function TariffReportItem(props) {
    const { trips } = props;


        return (
<Flex p={'2rem'} justifyContent={'start'} alignItems={'center'} flexWrap={'wrap'}>
    <Flex w={'100%'}>
       <Icon w={12} h={12} as={GiCarKey} color={trips.CurrentIgnition ? 'forestgreen' : 'crimson'}/>
<Text fontSize={'1.4rem'} fontWeight={'600'} mx={'10px'}>{trips.CurrentIgnition ? 'Kontak Açık' : 'Kontak Kapalı'}</Text> 
    </Flex>

<Text w={'100%'} fontSize={'1.4rem'} fontWeight={'600'} mx={'10px'}>Son Veri Zamanı: {moment.unix(trips.TimeRealUTC).format('DD.MM.YYYY - HH:mm:ss')}</Text>
</Flex>


        );
}