import React from 'react';
import { Input, FormControl, FormLabel } from '@chakra-ui/react';

const DateInputWithPlaceholder = ({placeholder}) => {
  return (
    <FormControl>
      <FormLabel htmlFor="date">Select Date</FormLabel>
      <Input
        id="date"
        type="date"
        placeholder={placeholder}// This won't show as placeholder, just for demonstration
        onFocus={(e) => (e.target.type = 'date')}
        onBlur={(e) => (e.target.type = 'text')}
      />
    </FormControl>
  );
};

export default DateInputWithPlaceholder;