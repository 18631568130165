import AdminService from "Services/AdminService/AdminService";
import SignalRService from "components/HubService/SignalRService";
import config from 'config';
import { CitiesLocations } from "variables/CityLocations";
import ReactDOMServer from 'react-dom/server';
import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Grid,
    Icon,
    Stat,
    Badge,
    Avatar,
    StatLabel,
    Button,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Modal,
    Divider,
    CircularProgress, CircularProgressLabel,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import TotalSpent from "./components/TotalSpent";
import moment from 'moment';
import 'moment/locale/tr';
import { MdSatelliteAlt } from "react-icons/md";
import { TbGps } from "react-icons/tb";
import Roles from "variables/Roles";
import { useTranslation } from 'react-i18next';
import { FaShop } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import Card from "components/card/Card.js";
import { RiTaxiWifiFill, RiBuildingFill } from "react-icons/ri";
import React, { useState, useEffect } from 'react';
const adminService = new AdminService();
const userData = JSON.parse(localStorage.getItem('UserData'));
export default function Homepage() {
    const [adminHome, setAdminHome] = useState(null);
    const [buttonText, setButtonText] = useState('Onayla');
    const [WaitingForApprovalUsers, setWaitingForApprovalUsers] = useState(null);
    const { t, i18n } = useTranslation();
    const handleFormValues = async () => {
        var result = await adminService.GetAdminHome();
        var WaitingForApprovalUsers = await adminService.GetWaitinfForApprovalUsers();
        setWaitingForApprovalUsers(WaitingForApprovalUsers);
        setAdminHome(result);
    };
    useEffect(() => {

        handleFormValues();
        // API'den cihaz konumlarını al
        // API'den cihaz konumlarını belirli aralıklarla al
        const intervalId = setInterval(() => {
            handleFormValues(); // API'den cihaz konumlarını al
        }, 10000); // Örneğin, her 10 saniyede bir veri al
        // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
        return () => clearInterval(intervalId);
    }, []);

    function compareDates(a, b) {
        // Önce tarihleri karşılaştır
        if (a.CreatedAt > b.CreatedAt) return -1;
        if (a.CreatedAt < b.CreatedAt) return 1;
        // Eğer tarihler eşitse, isimlere göre karşılaştır
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        return 0;
    }
    const currentDate = new Date();
    function CustomButton({ type, data }) {
        const { isOpen, onOpen, onClose } = useDisclosure();
        const { t, i18n } = useTranslation();
        var colorType;
        var buttonText = "";
        var modalHeader = "";
        var textForinner = "";
        const handleActivateUser = async (userID) => {
            var result = await adminService.GetActivateUser(userID);
            handleFormValues();
            onClose()
        }
        switch (type) {
            case 'approve':
                colorType = "linear-gradient(-135deg,  rgba(134, 140, 255, 0.8) 0%, rgba(67, 24, 255, 0.8) 100%)";
                buttonText = "Onayla";
                modalHeader = "Kullanıcıyı Onayla";
                textForinner = "onaylamak"
                break;
            case 'reject':
                colorType = "red"
                buttonText = "Reddet";
                modalHeader = "Kullanıcıyı Reddet";
                textForinner = "reddetmek"
                break;
        }

        const handleClick = async () => {
            onOpen();
        };
        return (

            <>
                { }


                <Button
                    bg={colorType}
                    transition={'all 0.2s ease'}
                    color={'white'}
                    onClick={handleClick}
                >

                    {buttonText}

                </Button>
                <Modal scrollBehavior={'inside'} size={'lg'} isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay bg='none'
                        backdropFilter='blur(10px) ' />
                    <ModalContent border={'6px solid rgba(0, 128, 128, 0.2)'}>


                        <ModalBody textAlign={'center'} rowGap={'10px'}>
                            <Text fontSize={'xl'} as={'b'} >{modalHeader}</Text>
                            <Divider my={'10px'} />
                            <Text fontSize={'md'}><b>{data.Name + " " + data.Surname}</b> adlı kullanıcıyı {textForinner} istediğinize emin misiniz?</Text>

                        </ModalBody>
                        <ModalFooter columnGap={'10px'}>

                            <Button fontSize={{ base: 'xs', md: 'md' }} colorScheme="teal" onClick={() => handleActivateUser(data.UserId)}>Onayla</Button>
                            <Button onClick={onClose} fontSize={{ base: 'xs', md: 'md' }} colorScheme="yellow" >{t('close')}</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    }
    if(userData.UserName == 'chinatest'){
return(
    <Flex
    flexWrap={'wrap'}
    rowGap={'1rem'}
    w={'100%'}
    height={{ base: "100%" }}
    marginTop={{ base: "80px", xl: "60px" }}
>
    {t('welcome')}
    </Flex>
)
    }
    else{
        return (
        <Flex
            flexWrap={'wrap'}
            rowGap={'1rem'}
            w={'100%'}
            height={{ base: "100%" }}
            marginTop={{ base: "80px", xl: "60px" }}
        >
            <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4} w={'100%'}>
                <Card bg={'linear-gradient(-135deg,  rgba(134, 140, 255, 0.8) 0%, rgba(67, 24, 255, 0.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'} >
                    <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                        <Text fontSize="lg" m={'0'}>Toplam Cihaz Sayısı</Text>
                        <Text as={'b'} fontSize="5xl" m={'0'}>{adminHome != null ? adminHome.DeviceCount : ""}</Text>
                    </Flex>
                    <Icon as={RiTaxiWifiFill} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                </Card>
                <Card bg={'linear-gradient(-135deg,  rgba(134, 140, 255, 0.8) 0%, rgba(67, 24, 255, 0.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'}>
                    <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                        <Text fontSize="lg" m={'0'}>Bağlı Cihaz Sayısı</Text>
                        <Text as={'b'} fontSize="5xl" m={'0'}>{adminHome != null ? adminHome.ConnectedDeviceCount : ""}</Text>
                    </Flex>
                    <Icon as={TbGps} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                </Card>
                <Card bg={'linear-gradient(-135deg,  rgba(134, 140, 255, 0.8) 0%, rgba(67, 24, 255, 0.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'}>
                    <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'} >
                        <Text fontSize="lg" m={'0'}>Aktif GPS Sayısı</Text>
                        <Text as={'b'} fontSize="5xl" m={'0'}>{adminHome != null ? adminHome.ActiveGPSCount : ""}</Text>
                    </Flex>
                    <Icon zIndex={'-2'} opacity={'0.1'} as={MdSatelliteAlt} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} />
                </Card>
                <Card bg={'linear-gradient(-135deg,  rgba(134, 140, 255, 0.8) 0%, rgba(67, 24, 255, 0.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'} >
                    <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                        <Text fontSize="lg" m={'0'}>Bayii Sayısı</Text>
                        <Text as={'b'} fontSize="5xl" m={'0'}>{adminHome != null ? adminHome.DealerCount : ""}</Text>
                    </Flex>
                    <Icon as={FaShop} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                </Card>
            </Grid>
            <Grid templateColumns={{ base: '1fr', md: '1.5fr 2.5fr' }} gap={4} w={'100%'}>
                <Card border={'2px solid rgba(134, 140, 255, 0.2) '}>
                    <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'}>
                        <Text fontSize="xl" m={'0'} >ParsPOS</Text>

                    </Flex>
                    <TotalSpent />
                </Card>

                <Grid gap={4}>
                    <Card h={'100%'} pos={'relative'} border={'2px solid rgba(134, 140, 255, 0.2) '}
                        backdropFilter={'blur(5px)'} color={'rgba(67, 24, 255, 0.8)'} >
                        <Grid templateColumns={{ base: '1fr', md: '1.5fr 3fr' }} gap={4} w={'100%'}>
                            <Card border={'1px solid rgba(134, 140, 255, 0.8) '} boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'}>
                                <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                                    <Text fontSize="lg" m={'0'}>Kullanıcı Sayısı</Text>
                                    <Text as={'b'} fontSize="5xl" m={'0'}>{adminHome != null ? adminHome.UserCount : ""}</Text>
                                </Flex>
                                <Icon as={FaUserFriends} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'rgba(134, 140, 255, 0.8) '} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                            </Card>
                            <Flex flexWrap={'wrap'} h={'100%'} pos={'relative'} columnGap={'1rem'}
                                rowGap={'1rem'}>
                                <Flex w={{ base: 'calc(50% - 0.5rem)', md: 'calc(25% - 0.8rem)' }} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pos={'relative'}>
                                    <CircularProgress value={100}
                                        color='rgba(67, 24, 255, 0.8)'
                                        size='70px'
                                        thickness='4px'
                                        boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'}
                                        borderRadius={'50%'}>
                                        <CircularProgressLabel>{adminHome != null ? adminHome.TaxiOwnerCount : ""}</CircularProgressLabel>
                                    </CircularProgress>
                                    <Text fontSize="md" m={'0'}>Bireysel</Text>
                                </Flex>
                                <Flex w={{ base: 'calc(50% - 0.5rem)', md: 'calc(25% - 0.8rem)' }} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pos={'relative'}>
                                    <CircularProgress value={100}
                                        color='rgba(67, 24, 255, 0.8)'
                                        size='70px'
                                        thickness='4px'
                                        boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'}
                                        borderRadius={'50%'}>
                                        <CircularProgressLabel>{adminHome != null ? adminHome.CityAdminCount : ""}</CircularProgressLabel>
                                    </CircularProgress>
                                    <Text fontSize="md" m={'0'}>Şehir Admin</Text>
                                </Flex>
                                <Flex w={{ base: 'calc(50% - 0.5rem)', md: 'calc(25% - 0.8rem)' }} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pos={'relative'}>
                                    <CircularProgress value={100}
                                        color='rgba(67, 24, 255, 0.8)'
                                        size='70px'
                                        thickness='4px'
                                        boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'}
                                        borderRadius={'50%'}>
                                        <CircularProgressLabel>{adminHome != null ? adminHome.StationCount : ""}</CircularProgressLabel>
                                    </CircularProgress>
                                    <Text fontSize="md" m={'0'}>Durak</Text>
                                </Flex>
                                <Flex w={{ base: 'calc(50% - 0.5rem)', md: 'calc(25% - 0.8rem)' }} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pos={'relative'}>
                                    <CircularProgress value={100}
                                        color='rgba(67, 24, 255, 0.8)'
                                        size='70px'
                                        thickness='4px'
                                        boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'}
                                        borderRadius={'50%'}>
                                        <CircularProgressLabel>{adminHome != null ? adminHome.AdminCount : ""}</CircularProgressLabel>
                                    </CircularProgress>
                                    <Text fontSize="md" m={'0'}>Admin</Text>
                                </Flex>
                            </Flex>


                        </Grid>

                    </Card>

                    <Card h={{ base: 'auto' }} border={'2px solid rgba(134, 140, 255, 0.2) '}>
                        <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'}>
                            <Text fontSize="xl" m={'0'} >Onay Bekleyen Kullanıcılar ({adminHome != null ? adminHome.WaitingForApprovalUserCount : ""})</Text>

                        </Flex>
                        <Flex flexDirection={'column'} h={'100%'} maxH={'400px'} overflowY={'scroll'} overflowX={'auto'} rowGap={'0.5rem'} mt={'4px'} w={'100%'}>
                            {WaitingForApprovalUsers != null ?
                                <>
                                    {WaitingForApprovalUsers.sort(compareDates).map(m => (

                                        <Grid templateColumns={{ base: '1fr', md: '2fr 2fr 1fr' }}
                                            gap={1}
                                            w={{ base: '100%', md: 'unset' }}
                                            border={'1px solid lightgray'} alignItems={'center'} p={'8px'} borderRadius={'0.825rem'}>
                                            <Flex alignItems={'center'}>
                                                <Avatar src='https://bit.ly/sage-adebayo' />
                                                <Box ml='3' w={'100%'}>
                                                    <Text fontWeight='bold'>
                                                        {m.Name + " " + m.Surname}
                                                        {m.CreatedAt < currentDate ?
                                                            <Badge ml='1' colorScheme='green'>
                                                                New
                                                            </Badge>
                                                            : ""
                                                        }
                                                    </Text>
                                                    <Text fontSize='sm'>  {t(Roles[m.Role])}</Text>
                                                </Box>
                                            </Flex>

                                            <Box ml='3' w={'100%'}>

                                                <Text fontSize='sm'>  {m.Phone}</Text>
                                                <Text fontSize='sm'>  {m.Email}</Text>
                                            </Box>
                                            <Grid gap={1} w={'100%'}>
                                                <CustomButton type="approve" data={m} />
                                                <CustomButton type="reject" data={m} />
                                            </Grid>

                                        </Grid>
                                    )


                                    )}
                                </>
                                : <Text>Onay Bekleyen Kullanıcı Bulunamadı</Text>}
                        </Flex>
                    </Card>
                </Grid>

            </Grid>


        </Flex>

    );   
    }
 
}
