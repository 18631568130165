import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import TaxiStats from "./components/TaxiStatusPanel";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import ReactDOMServer from 'react-dom/server';
import L, { MarkerCluster } from 'leaflet';
import moment from 'moment';
import 'moment/locale/tr';
import 'assets/css/MarkerCluster.css';
import Select from 'react-select';
import CustomSelect from './components/CustomSelect'
import SearchBar from 'components/mapSearchBar/MapSearchBar'
import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Icon,
    Spinner
} from "@chakra-ui/react";
import { FullscreenControl } from 'react-leaflet-fullscreen';
import MapSidebar from 'components/MapSidebar/MapSidebar';
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdReplay,
    MdFlag,
    MdPlayCircleOutline,
    MdChevronLeft,
    MdChevronRight
} from "react-icons/md";
import React, { useState, useEffect, useRef } from 'react';
import { CitiesLocations } from "variables/CityLocations";
const { BaseLayer } = LayersControl;
const taxiDeviceService = new TaxiDeviceService();
function GetAddressData(lat, lon) {
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Adres bilgisini almak için uygun olan property'yi seçin

            const address = data.error ? '--------------------' : data.display_name;
            console.log(address)
            return address;
        })
        .catch(error => {
            console.error('There was a problem with the reverse geocoding operation:', error);
            return '-----';
            throw error;
        });


};
function ChildComponent({ zoomLevel, centerCoordinates }) {
    const map = useMap(); // Access the map instance using useMap()
    useEffect(() => {
        console.log(zoomLevel, " ", centerCoordinates);
        // You can now use the map instance to perform operations
        // For example, to set the view to a specific location
        map.setView(centerCoordinates, zoomLevel);
    }, [centerCoordinates]);


    return null;
}
function FitBounds({ markers }) {
    const map = useMap();
    useEffect(() => {
        if (markers.length > 0) {
            const bounds = L.latLngBounds(markers.map(marker => marker.LastLocation != null ? [marker.LastLocation.Latitude, marker.LastLocation.Longitude] : []))
            map.fitBounds(bounds);
        }
    }, [map, markers]);
    return null;
}
export default function AdminHomepage() {
    const userData = JSON.parse(localStorage.getItem('UserData'));

    var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
    var logoUrl = `<img src="${logoWhite}"/>`
    const city = CitiesLocations[cityIndex];
    const [deviceLocations, setDeviceLocations] = useState([]); // Cihaz konumları: [{ deviceId, lat, lon }]
    const [brokenDevices, setBrokenDevices] = useState([]); // Cihaz konumları: [{ deviceId, lat, lon }]
    const [locationLock, setDeviceLocationLock] = useState(false);
    const [fullScreenStats, setFullScreenStats] = useState(false);
    const [mapZoom, setMapZoom] = useState(10); // Harita zoom seviyesi
    const [addressName, setAddressName] = useState(null);
    const [mapCenter, setMapCenter] = useState([city.lat, city.lon]);
    const [filterValue, setFilterValue] = useState('');
    const [markerCluster, setMarkerCluster] = useState(false);
    const [searchbarHideShow, setSearchBarHideShow] = useState(true);
    const [autoZoom, setAutoZoom] = useState(true);
    const [showPlates, setShowPlates] = useState(true);
    const [statsData, setHubData] = useState({
        ClosedModeCount: 0,
        FreeModeCount: 0,
        Taximeters: [],
        RentedModeCount: 0,
        TotalTaximeterCount: 0,
    });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedCar, setSelectedCar] = useState(null);
    const toggleSidebar = (deviceID) => {
        if (!sidebarOpen == true) {
            setSelectedCar(deviceID)
        }
        setSidebarOpen(!sidebarOpen);
    };
    const carIcon = (taxiPlate, status) => {
        var color = '';
        var textColor = '';
        if (status == "Free") {
            color = '#ffc107';
            var textColor = 'black';
        }
        else if (status == "Rented") {
            color = 'forestgreen';
            var textColor = 'white';
        }
        else if (status == "Closed") {
            color = 'Crimson';
            var textColor = 'white';
        }
        else {
            color = 'lightgray';
            var textColor = 'white';
        }
        return L.divIcon({
            html: ReactDOMServer.renderToString(
                <Flex textAlign={'center'} color={textColor} alignItems={'center'} opacity={'0.9'} >
                    <Icon bg={color} p={'5px'} borderRadius={'50% '} fontSize={'3em'} as={MdOutlineLocalTaxi} boxShadow={'0 4px 30px rgba(0, 0, 0, 0.2)'} />
                    {showPlates && <Text bg={color}
                        borderRadius={'0px 10px 10px 0px'}
                        p={'4px 6px'}
                        pos={'relative'}
                        left={'-6px'}>{taxiPlate.replace(" ", "").replace(/\s/g, "")}</Text>}
                </Flex>

            ),
            className: 'marker-icon marker-transition',
        });
    }
    const options = [];
    useEffect(() => {
        fetchDeviceLocations(); // API'den cihaz konumlarını al
        // API'den cihaz konumlarını belirli aralıklarla al
        const intervalId = setInterval(() => {
            fetchDeviceLocations(); // API'den cihaz konumlarını al
        }, 5000); // Örneğin, her 10 saniyede bir veri al
        // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
        return () => clearInterval(intervalId);
    }, [filterValue || locationLock]);

    const fetchDeviceLocations = async () => {
        var result = await taxiDeviceService.GetCityDeviceLocation(city.plaka);
        setHubData(result);
        const filteredLocations = result.Taximeters.filter(f => {
            if (f.LastLocation != null) {
                const lat = f.LastLocation.Latitude;
                const lon = f.LastLocation.Longitude;
                return lat >= 36 && lat <= 42 && lon >= 26 && lon <= 45;
            }

        });
        console.log(filterValue);
        if (filterValue != '') {
            setDeviceLocations(filteredLocations.filter(f => f.Status != null && f.Status.Status == filterValue));

        } else {
            setDeviceLocations(filteredLocations);

        }
        setBrokenDevices(result.Taximeters.filter(f => f.Status == null || f.LastLocation == null))
        if (locationLock) {
            var trackDevice = localStorage.getItem('selectedLocationDevice');
            if (trackDevice) {
                var device = filteredLocations.filter(f => f.DeviceId == trackDevice);
                setDeviceLocations(device);
                setMapCenter([device[0].LastLocation.Latitude, device[0].LastLocation.Longitude]);
            }

        }

    };

    const handleZoomChange = (e) => {
        setMapZoom(e.target._zoom); // Harita zoom seviyesini güncelle
    };
    const handleClick = async (lat, lon) => {
        setAddressName(null)
        var result = await GetAddressData(lat, lon);
        setAddressName(result) //Lokasyon Verileri Alınıyor.
        console.log(addressName);
    };
    const setFilterValueForRequest = async (value) => {
        setFilterValue(value.DeviceStatus);


    };
    const GoVehicle = async (value) => {
        if (value != "") {

            setMapCenter(value.location)
            setMapZoom(18)
        }

    }
    const handleToLock = async (value) => {
        setDeviceLocationLock(value);

    }
    const handleToMarkerCluster = async (value) => {
        setMarkerCluster(value);
        localStorage.setItem('markerClusterValue', value);
    }
    const handleToPlate = async (value) => {
        setShowPlates(value);

    }
    const handleToAutoZoom = async (value) => {
        setAutoZoom(value);
        localStorage.setItem('AutoZoom', value);
    }
    useEffect(() => {
        if (JSON.parse(localStorage.getItem('markerClusterValue')) == null) {
            localStorage.setItem('markerClusterValue', true);
        }
        if (JSON.parse(localStorage.getItem('AutoZoom')) == null) {
            localStorage.setItem('AutoZoom', true);
        }
        setMarkerCluster(JSON.parse(localStorage.getItem('markerClusterValue')));
        setAutoZoom(JSON.parse(localStorage.getItem('AutoZoom')));

    }, [1])
    const handleToFullscreenction = () => {
        setFullScreenStats(!fullScreenStats)

    }
    const handleViewportChange = (viewport) => {
        const { bounds } = viewport;
        const maxBounds = [[-90, -180], [90, 180]]; // Haritanın izin verilen maksimum sınırları

        // Eğer haritanın sınırları izin verilen maksimum sınırların dışındaysa
        if (
            bounds[0][0] < maxBounds[0][0] ||
            bounds[0][1] < maxBounds[0][1] ||
            bounds[1][0] > maxBounds[1][0] ||
            bounds[1][1] > maxBounds[1][1]
        ) {
            // Harita sınırlarını izin verilen maksimum sınırlara ayarlayın
            viewport.bounds = maxBounds;
        }
    };
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
            setIsFullscreen(false);
        } else {
            setIsFullscreen(true);
        }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return (
        <Flex
            flexDirection={'column'}
            height={{ base: "110vh", xl: "100vh" }}
        
        >
            {!fullScreenStats &&
            <Flex pos={'absolute'} zIndex={1} bottom={0} left={'12rem'}>
                <TaxiStats
                    brokens={brokenDevices}
                    data={statsData}
                    onFilterChange={setFilterValueForRequest}
                />
                </Flex>
            }  {/* <TaxiStatusHub /> */}

            <MapContainer onViewportChange={handleViewportChange} center={mapCenter} zoom={mapZoom} style={{ height: '100%', width: '100%', zIndex: '0' }} onZoomend={handleZoomChange} zoomControl={false} >
            <MapSidebar sidebarOpen={sidebarOpen} selectedCar={selectedCar} onClose={setSidebarOpen} />
                <ZoomControl position="bottomright" />
                <Flex top={'6rem'} width={'100%'} className={`leaflet-top leaflet-left mapcontrolbar ${searchbarHideShow ? 'open' : 'closed'}`} alignItems={'center'}  >

                    <Flex width={'400px'} className="leaflet-control leaflet-bar" bg={isFullscreen ? 'rgba(255,255,255,0.9)' : 'white'}>

                        <SearchBar data={deviceLocations}
                            onLock={(e) => handleToLock(e)}
                            onMarkerCluster={(e) => handleToMarkerCluster(e)}
                            onFilterChange={GoVehicle}
                            onShowPlates={(e) => handleToPlate(e)}
                            onAutoZoom={(e) => handleToAutoZoom(e)}></SearchBar>

                        <Flex
                            onClick={() => setSearchBarHideShow(!searchbarHideShow)}
                            position={'relative'}
                            borderLeft={'1px solid lightgray'}
                            color={'gray'}
                            bg={'rgba(255,255,255,0.4)'}
                            alignItems={'center'}>
                            <Icon w={6} h={6} as={!searchbarHideShow ? MdChevronRight : MdChevronLeft} />
                        </Flex>
                    </Flex>
                </Flex>

                <LayersControl position="topright">
                    <BaseLayer checked name="Varsayılan Harita">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Uydu Görünümü">
                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer checked name="Google Haritalar">
                        <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar (Uydu)">
                        <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                </LayersControl>
                {markerCluster && <MarkerClusterGroup>
                    {deviceLocations.map((location) => (
                        location != null && location.LastLocation != null ?
                            <Marker key={location.DeviceId} position={[location.LastLocation.Latitude, location.LastLocation.Longitude]} icon={carIcon(location.Plate, location.Status != null ? location.Status.Status : 'none')}
                                eventHandlers={{
                                    click: () => toggleSidebar(location.DeviceId),


                                }}
                            >

                            </Marker>
                            : ""
                    ))}
                </MarkerClusterGroup>}
                {!markerCluster && <>
                    {deviceLocations.map((location) => (
                        location != null && location.LastLocation != null ?
                            <Marker key={location.DeviceId} position={[location.LastLocation.Latitude, location.LastLocation.Longitude]} icon={carIcon(location.Plate, location.Status != null ? location.Status.Status : 'none')}
                                eventHandlers={{
                                    click: () => toggleSidebar(location.DeviceId),


                                }}
                            >

                            </Marker>
                            : ""
                    ))}
                </>}

                <FullscreenControl position="bottomleft"
                    title="Tam Ekran"
                    titleCancel="Tam Ekranı Kapat"
                    forceSeparateButton={false}
                    forcePseudoFullscreen={false}
                    fullscreenElement={false}
                    container={document.documentElement}
                    pseudoFullscreen={false}
                    allowFullscreen={true}
                    ariaLabel="Tam Ekran"
                    className="leaflet-fullscreen-icon"
                    onFullscreenchange={handleToFullscreenction}
                    eventHandlers={{
                        click: () => handleToFullscreenction,


                    }}
                />
                <ChildComponent zoomLevel={mapZoom} centerCoordinates={mapCenter} />
                {autoZoom && <FitBounds markers={deviceLocations} />}
          
            </MapContainer>

        </Flex>

    );
}
