import AdminService from "Services/AdminService/AdminService";
import SignalRService from "components/HubService/SignalRService";
import config from 'config';
import { CitiesLocations } from "variables/CityLocations";
import ReactDOMServer from 'react-dom/server';
import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Grid,
    Icon,
    Stat,
    Badge,
    Avatar,
    StatLabel,
    Button,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Modal,
    Divider,
    CircularProgress, CircularProgressLabel,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import moment from 'moment';
import 'moment/locale/tr';
import LastOperationItem from './components/TaxiStatusPanel'
import { MdSatelliteAlt, MdAccountBalanceWallet, MdCompassCalibration } from "react-icons/md";
import { TbGps } from "react-icons/tb";
import Roles from "variables/Roles";
import { useTranslation } from 'react-i18next';
import { FaShop } from "react-icons/fa6";
import { FaUserFriends, FaUsers } from "react-icons/fa";
import Card from "components/card/Card.js";
import { RiTaxiWifiFill, RiBuildingFill } from "react-icons/ri";
import React, { useState, useEffect } from 'react';
const adminService = new AdminService();

export default function Homepage() {
    const [adminHome, setAdminHome] = useState(null);
    const [buttonText, setButtonText] = useState('Onayla');
    const [WaitingForApprovalUsers, setWaitingForApprovalUsers] = useState(null);
    const { t, i18n } = useTranslation();


    return (
        <Flex
            flexWrap={'wrap'}
            rowGap={'1rem'}
            w={'100%'}
            height={{ base: "100%" }}
            marginTop={{ base: "80px", xl: "60px" }}
        >
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4} w={'100%'}>
                <Box>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4} w={'100%'}>
                        <Card bg={'linear-gradient(-135deg,  rgba(59,130,246,.5) 0%, rgba(59,130,246,.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'} >
                            <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                                <Text fontSize="lg" m={'0'}>{t('remaincredit')}</Text>
                                <Text as={'b'} fontSize="5xl" m={'0'}>100</Text>
                            </Flex>
                            <Icon as={MdAccountBalanceWallet} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                        </Card>
                        <Card bg={'linear-gradient(-135deg,  rgba(59,130,246,.5) 0%, rgba(59,130,246,.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'}>
                            <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                                <Text fontSize="lg" m={'0'}>{t('dealerusercount')}</Text>
                                <Text as={'b'} fontSize="5xl" m={'0'}>1</Text>
                            </Flex>
                            <Icon as={FaUsers} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                        </Card>
                        <Card bg={'linear-gradient(-135deg,  rgba(59,130,246,.5) 0%, rgba(59,130,246,.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'}>
                            <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'} >
                                <Text fontSize="lg" m={'0'}>{t('monthlytariffcount')}</Text>
                                <Text as={'b'} fontSize="5xl" m={'0'}>15</Text>
                            </Flex>
                            <Icon zIndex={'-2'} opacity={'0.1'} as={MdSatelliteAlt} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} />
                        </Card>
                        <Card bg={'linear-gradient(-135deg,  rgba(59,130,246,.5) 0%, rgba(59,130,246,.8) 100% )'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} color={'white'} >
                            <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                                <Text fontSize="lg" m={'0'}>{t('monthlycalibrationcount')}</Text>
                                <Text as={'b'} fontSize="5xl" m={'0'}>5 </Text>
                            </Flex>
                            <Icon as={FaShop} pos={'absolute'} right={'0px'} top={'0'} w={'8rem'} h={'8rem'} color={'white'} p={'10px'} zIndex={'-2'} opacity={'0.1'} />
                        </Card>
                    </Grid>
                    <Card mt='1rem'>

                    </Card>
                </Box>

                <Card >
                    <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'}>
                        <Text fontSize="xl" m={'0'} >{t('lastoperations')}</Text>
                    </Flex>
                    <LastOperationItem />

                </Card>
            </Grid>




        </Flex>

    );
}
