import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdLanguage } from "react-icons/md";
import { LanguageIcon } from "components/icons/iconsUI";
import { US, TR, AE,FR } from 'country-flag-icons/react/3x2'
export default function LanguageBar({ color, withText }) {
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const { t, i18n } = useTranslation();
    const changeLanguage = async lang => {
        await i18n.changeLanguage(lang)
    }
    return (
        <>
            <Menu>
                <MenuButton p="0px">
                    <Flex alignItems={'center'} columnGap={'4px'}>
                    <Image src={LanguageIcon} width='26px' height='26px'  />
                        {withText && <Text  color={color}>{t('selectlanguage')}</Text>}
                    </Flex>

                </MenuButton>
                <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none" w={'2rem'}>
                    <Flex flexDirection="column" p="10px">
                        <MenuItem onClick={() => changeLanguage('tr')} _hover={{ bg: 'twitter.500', color:'white' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                          <Flex w={'1.2rem'} m={'0 4px'}><TR title="United States" className="..."/></Flex>  <Text fontSize="sm">Türkçe</Text>
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage('en')} _hover={{ bg: 'twitter.500', color:'white' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                        <Flex w={'1.2rem'} m={'0 4px'}><US title="United States" className="..."/></Flex> <Text fontSize="sm"> English</Text>
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage('ar')} _hover={{ bg: 'twitter.500', color:'white' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                        <Flex w={'1.2rem'} m={'0 4px'}><AE title="United States" className="..."/></Flex> <Text fontSize="sm"> عربي</Text>
                        </MenuItem>
                        <MenuItem onClick={() => changeLanguage('fr')} _hover={{ bg: 'twitter.500', color:'white' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
                        <Flex w={'1.2rem'} m={'0 4px'}><FR title="United States" className="..."/></Flex> <Text fontSize="sm"> Français</Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </>
    )
}