// chakra imports
import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade, MdWarningAmber } from "react-icons/md";
import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
    Box,
    SimpleGrid,
    Heading,
    Divider,
    Button,
    Tooltip,
    Input,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Spinner
} from "@chakra-ui/react";
import moment from 'moment';
import { format } from 'date-fns';
import Calendar from "react-calendar/dist/umd/Calendar";
import { PiMicrosoftExcelLogoBold } from "react-icons/pi";
import 'moment/locale/tr';
import { LuPackageSearch } from "react-icons/lu";
import { useTranslation } from 'react-i18next';
import {
    MdChevronRight,
    MdChevronLeft,
    MdMap,
    MdOutlineFullscreen
} from "react-icons/md";
import { TbRouteOff } from "react-icons/tb";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
const ExportToExcel = ({ jsonData, fileName }) => {
    const exportToExcel = () => {
        // Verileri işle
        let processedData =[];
     jsonData.map((item) => (
            item.Trips.map((tripItem, index) => {
              processedData.push({
                    'C': index,
                    'Plaka': item.Plate,
                    'Yolculuk Sayısı': item.TripCount,
                    'Başlangıç Saati': moment(tripItem.StartOfRentedTrip).format('DD.MM.yyyy HH:mm'),
                    'Bitiş Saati': moment(tripItem.EndOfRentedTrip).format('DD.MM.yyyy HH:mm'),
                    // Burada istediğiniz verileri işle yabilir ve yeni alanlar ekleyebilirsiniz
                    // Örneğin:
                    // FullName: `${item.firstName} ${item.lastName}`,
                    // BirthYear: new Date().getFullYear() - item.age,
                });
            }
            )

        ));

        // Yeni bir çalışma kitabı oluştur
        const workBook = XLSX.utils.book_new();

        // İşlenmiş veriyi çalışma sayfasına dönüştür
        const workSheet = XLSX.utils.json_to_sheet(processedData);

        // Çalışma sayfasını çalışma kitabına ekle
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

        // Çalışma kitabını binary olarak yaz
        const excelBuffer = XLSX.write(workBook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Blob oluştur ve kaydet
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, `${fileName}.xlsx`);
    };

    return <Button onClick={exportToExcel} colorScheme="green" p={'0px'} size="md"><Icon w={5} h={5} as={PiMicrosoftExcelLogoBold} /> </Button>;
};
export default function ItemContent({ tripData, onOpens, showOnMap }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [StartTimeOpen, setStartTimeOpen] = useState(false);
    const [EndTimeOpen, setEndTimeOpen] = useState(false);
    const [PriceRange, setPriceRangeOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (onOpens == true) {
            setSidebarOpen(true)
        }

    }, [onOpens])
    const handleItemClick = (index, TripID, DeviceId) => {
        if (TripID === activeIndex) {
            showOnMap(null)
        }
        else {
            showOnMap({ "tripID": TripID, "deviceID": DeviceId })
        }

        setActiveIndex(TripID === activeIndex ? null : TripID);

    };

    return (
        <Flex className={`tripsSidebar ${sidebarOpen ? 'open' : ''}`} >
            <Flex display={onOpens ? 'flex' : 'none'} className="opencloseBtn">
                <Icon onClick={() => setSidebarOpen(!sidebarOpen)} h={8} w={8} as={sidebarOpen ? MdChevronRight : MdChevronLeft} />
            </Flex>
            <Flex className="innerTrips" overflowY={'auto'} h={'100%'} flexDirection={'column'} p={'4px'}>
                {onOpens ? <>
                    {tripData != null ?
                        <>
                        <Flex alignItems={'center'} mb={'10px'} justifyContent={'space-between'}>
                             <Text textAlign={'center'} as={'b'} fontSize={'md'} >{tripData.Count} Kayıt Bulundu!</Text>
                             <ExportToExcel jsonData={tripData.Results} fileName="custom_data" />
                        </Flex>
                           


                            <Accordion allowMultiple>
                                {tripData.Results.map((item, index) => {
                                    return <AccordionItem mb={'4px'}>
                                        <h2>
                                            <AccordionButton display={'flex'} justifyContent={'center'} borderBottomWidth={'2px'} border={'1px solid #1DA1F2'} borderRadius={'0.6rem'} color={'twitter.500'} columnGap={'5px'}>
                                                <Flex borderRadius={'50%'}
                                                    lineHeight={'20px'}
                                                    bg={'white'}
                                                    color={'twitter.500'}
                                                    border={'1px solid #1DA1F2'}
                                                    fontSize={'sm'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    w={'30px'} h={'30px'}>{index + 1}</Flex>
                                                <Flex textAlign={'left'} flexDirection={'column'} w={'80%'} pos={'relative'}>
                                                    <Text lineHeight={'20px'} m={0}>Araç Plakası: {item.Plate}</Text>
                                                    <Text lineHeight={'20px'} m={0}>Yolculuk Sayısı: {item.TripCount}</Text>
                                                </Flex>

                                                <AccordionIcon w={'10%'} />

                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel px={0} pb={4}>
                                            <Flex mb={'2px'} px={'10px'} justifyContent={'space-between'} alignItems={'end'} borderBottom={'1px solid rgba(0,0,0,0.2)'} >
                                                <Text>Sıra</Text>
                                                <Text>Tarih Aralığı</Text>
                                                <Text>Tutar</Text>
                                                <Text>Hrt. Çiz</Text>
                                            </Flex>
                                            {
                                                item.Trips.map((tripItem, index) => (
                                                    <Flex mx={'4px'} mb={'2px'} px={'10px'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid rgba(0,0,0,0.2)'} >
                                                        <Text>{index + 1}</Text>
                                                        <Text>{moment(tripItem.StartOfRentedTrip).format('DD.MM.yyyy HH:mm')} <br />{moment(tripItem.EndOfRentedTrip).format('DD.MM.yyyy HH:mm')}</Text>
                                                        <Text>{(tripItem.RentedTripFare / 1000).toFixed(2)}</Text>
                                                        <Button colorScheme={activeIndex === tripItem.TripID ? 'twitter' : 'teal'} onClick={() => handleItemClick(index, tripItem.TripID, item.DeviceId)}><Icon as={activeIndex === tripItem.TripID ? TbRouteOff : MdMap} /></Button>
                                                    </Flex>
                                                ))
                                            }
                                        </AccordionPanel>
                                    </AccordionItem>
                                })}
                            </Accordion>
                        </>
                        : <Text>Kayıt Bulunamadı</Text>}


                </> : <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>}
            </Flex>

        </Flex>
    );
}
