/* eslint-disable no-restricted-globals */
self.onmessage = async (e) => {
  const { baseURL, token, cityCode, filterValue, locationLock, trackDevice } = e.data;

if(e.data){
   try {
      const response = await fetch(`${baseURL}/api/TaxiInfo/TaximetersByCity?cityCode=${cityCode}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok && data.IsSuccess) {
        let taxiLocations;
        let mapCenter;
        const filteredLocations = data.Data.Taximeters.filter(f => {
          if (f.LastLocation != null) {
            const lat = f.LastLocation.Latitude;
            const lon = f.LastLocation.Longitude;
            return lat >= 36 && lat <= 42 && lon >= 26 && lon <= 45;
          }

        });
        console.log(filterValue);
        if (filterValue != '') {
          taxiLocations = filteredLocations.filter(f => f.Status != null && f.Status.Status == filterValue);

        } else {
          taxiLocations = filteredLocations;

        }

        if (locationLock) {

          if (trackDevice) {
            var device = filteredLocations.filter(f => f.DeviceId == trackDevice);
            taxiLocations = device;
            mapCenter = [device[0].LastLocation.Latitude, device[0].LastLocation.Longitude];
          }

        }
        var message = {statsData: data.Data, taxiLocationsData: taxiLocations, mapCenterData : mapCenter};
        console.log(message)
        self.postMessage(message);
      }

    } catch (error) {
      self.postMessage([]);
    }
}
   


};