// Chakra imports
import { Text, useColorModeValue, SimpleGrid, Flex, Icon, Grid } from "@chakra-ui/react";
// Assets
import { useTranslation } from 'react-i18next';
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "../card/Card";
import React, { useState } from "react";
import {
    MdBarChart,
    MdPerson,
    MdHome,
    MdLock,
    MdLocalTaxi,
    MdOutlineShoppingCart,
    MdCompassCalibration,
    MdSystemUpdateAlt,
    MdReceipt
} from "react-icons/md";

export default function LastOperationItem(props) {
    // Chakra Color Mode
    const { data, type, ...rest } = props;
    const { t, i18n } = useTranslation();
    const [deviceStatus, setDeviceStatus] = useState('');
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    let icon;
    let process;
    switch (type) {
        case 'calibration':
            icon = MdCompassCalibration;
            process = t('calibrationprocess')
            break;
        case 'tariff':
            icon = MdReceipt;
            process = t('tariffprocess')
            break;
        case 'update':
            icon = MdSystemUpdateAlt;
            process = t('updateprocess')
            break;
    }
    return (
        <Card border={'1px solid rgba(59,130,246,.8) '} boxShadow={'0 4px 15px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'} h='8rem' w='calc(100% - 8px)'>
            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                <Flex justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                    <Text fontSize="sm" m={'0'}>13.06.2024 10:06</Text>
                    <Text as={'b'} fontSize="xl" m={'0'}>{t('plate')}: 35T0255</Text>
                    <Text fontSize="sm" m={'0'}>{t('serialno')}: 547896321446</Text>
                </Flex>
                <Flex justifyContent={{ base: 'space-between', md: 'center' }} alignItems={'start'} flexDirection={{ base: 'row', md: 'column' }}>
                    <Text fontSize="sm" m={'0'}><b>{t('doingprocessuser')}:</b><br /> Necdet Bartu Şener</Text>
                    <Text fontSize="sm" m={'0'}><b>{t('doingprocess')}:</b><br /> {process}</Text>
                </Flex>
            </Grid>

            <Flex pos={'absolute'} right={'8px'} flexDirection={'column'} justifyContent={'center'} alignItems={'end'} textAlign={'right'}>
                <Icon as={icon} w={'4rem'} h={'4rem'} color={'rgba(59,130,246,.8) '} zIndex={'-2'} opacity={'0.1'} />

            </Flex>
        </Card>
    );
}
