import {
  Avatar,
  Button,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Text,
  Input,
  Grid
} from '@chakra-ui/react';
import {
  MdTimeline,
  MdDateRange,
  MdOutlineLocalTaxi,
  MdOutlineTaxiAlert,
  MdAttachMoney,
  MdManageSearch
} from "react-icons/md";
import moment from 'moment';
import CustomDatepicker from 'components/datepickerwithplaceholder/CustomDatepicker'
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import { SelectPicker, Stack } from 'rsuite';
import 'react-datepicker/dist/react-datepicker.css';
const taxiDeviceService = new TaxiDeviceService();
export function FilterBar({ onFilterChange, buttonStatus }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [plate, setCarPlate] = useState('');
  const [loading, setButtonLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const [taxiPlates, setTaxiPlates] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('day').format("yyyy-MM-DDTHH:mm"));
  const [endDate, setEndDate] = useState(moment(Date.now()).format("yyyy-MM-DDTHH:mm"));
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      var result = await taxiDeviceService.GetUsersTaxiDeviceList();
      if (result != null) {
        var c = result.Taximeters.map(item => ({ label: item.Plate, value: item.DeviceId }));
        setTaxiPlates(c);
      }

    }

    fetchDataAndSetState();
  }, []);
  useEffect(() => {
    setButtonLoading(buttonStatus)
  }, [buttonStatus]);
  console.log(taxiPlates)
  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ background: "#fff", color: "#000", borderRadius: " 0.375rem" }}>
        <CalendarContainer style={{ borderRadius: " 0.375rem" }}>
          <div style={{ background: "#f0f0f0", padding: "8px", textAlign: "center", borderRadius: " 0.375rem 0.375rem 0 0" }}>
            Görüntülemek istediğiniz tarih aralığını seçiniz.
          </div>
          <div style={{ position: "relative", borderRadius: " 0 0 0.375rem 0.375rem " }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const handleFormValues = () => {
    // Değerleri ana bileşene iletmek için prop olarak geçirilen fonksiyonu çağırın
    setButtonLoading(true)
    if (!plate || !startDate || !endDate) {
      setButtonLoading(false)
    }
    onFilterChange({ 'DeviceId': plate, 'DateRange': [startDate, endDate] });
  };

  return (

    <Flex
      rowGap={'1rem'}
      columnGap={'1.2rem'}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="flex-end "
      flexDirection="row"
      justifyContent={'space-between'}
      bg={'white'}
      flexWrap={{ base: 'wrap' }}
      p="15px 30px"
      borderRadius="30px"
    >
      <FormControl w={{ base: '100%', md: '20%' }}>
        <FormLabel htmlFor='country'>    {t('taxiplate')}*</FormLabel>
        <Flex
          w={'100%'}
          alignItems={'center'}
          columnGap={'5px'}

        >
          <Icon display={{ base: 'none', md: 'block' }} as={MdOutlineLocalTaxi} width='2rem' height='2rem' color='white' background={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'} p={'5px'} borderRadius={'50%'} />
          <SelectPicker data={taxiPlates} style={{ width: '100%', fontSize: '1rem', zIndex: '0' }} size="lg" placeholder={t('selecttaxi')} onChange={setCarPlate} />
        </Flex>

      </FormControl>
      <FormControl
        w={{ base: '100%', md: '40%' }}
      >
        <FormLabel htmlFor='dateRange'>    {t('daterange')}*</FormLabel>
        <Flex
          w={'100%'}
          alignItems={'center'}
          columnGap={'5px'}
          id='daterangeFlex'
        >
          <Icon display={{ base: 'none', md: 'block' }} as={MdDateRange} width='2rem' height='2rem' color='white' background={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'} p={'5px'} borderRadius={'50%'} />

          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            fontSize={'1rem'}
            border={'1px solid #e5e5ea'}
            borderRadius={'6px'}
            justifyContent={'center'}
            alignItems={'center'}
            w={'100%'}
          >

            <Input
              placeholder={t('starttime')}

              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              type="datetime-local"
              textAlign={'center'}
              border={0} />
            <Text align={'center'}>-</Text>
            <Input
              placeholder={t('endtime')}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              type="datetime-local"
              textAlign={'center'}
              border={0} />
          </Flex>


        </Flex>

      </FormControl>

      <Button isLoading={loading} loadingText='Veriler Getiriliyor' onClick={handleFormValues} leftIcon={<Icon as={MdManageSearch} width='1.5rem' height='1.5rem' />} colorScheme='teal' variant='solid' w={{ base: '100%', md: '20%' }}>
        {t('tolist')}
      </Button>
    </Flex>


  );
}