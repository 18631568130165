import {
  Box,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Grid,
  Icon,
  Stat,
  Badge,
  Avatar,
  StatLabel,
  Button,
  StatNumber,
  StatHelpText,
  FormLabel,
  FormControl,
  StatArrow,
  StatGroup,
  Modal,
  Divider,
  CircularProgress, CircularProgressLabel,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  createStandaloneToast
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/parstaksilogo.png";
import CalibrationService from 'Services/CalibrationService/CalibrationService'
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LastOperationItem from 'components/customcards/LastOperationItem'
import { FilterBar } from "./components/FilterBar";
import MapComponent from "./components/MapComponent";
// import MapContent from "./components/MapContent";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import moment from 'moment';
import { format } from 'date-fns';
import Card from "components/card/Card.js";
import { CitiesLocations } from "variables/CityLocations";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdLocalTaxi,
  MdOutlineShoppingCart,
  MdCompassCalibration,
  MdOutlineInfo,
  MdSettingsRemote
} from "react-icons/md";
const calibrationService = new CalibrationService();
export default function LocationReports() {
  const userData = JSON.parse(localStorage.getItem('UserData'));
  var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
  const city = CitiesLocations[cityIndex];
  var logoUrl = `<img src="${logoWhite}"/>`
  const [deviceID, setDeviceId] = useState('');
  const [showData, setShowTrips] = useState(false);
  const [noData, setNoData] = useState(false);
  const [clock, setClock] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = createStandaloneToast();
  const [position, setMapPosition] = useState(city);
  const [connectDevice, setConnectDevice] = useState(false);
  moment.locale('tr');
  const { t, i18n } = useTranslation();
  useEffect(() => {

    const setTimerFor = () => {
      const offset = new Date().getTimezoneOffset(); // Mevcut zaman dilimi ofseti
      const localDateTime = new Date(Date.now() - (offset * 60 * 1000)); // Ofseti hesaplayarak yerel tarihi bulma
      const date = localDateTime.toISOString().slice(0, 16); // ISO formatına çevirme
      setClock(date);
    }; // API'den cihaz konumlarını al
    // API'den cihaz konumlarını belirli aralıklarla al
    const intervalId = setInterval(() => {
      setTimerFor(); // API'den cihaz konumlarını al
    }, 1000); // Örneğin, her 10 saniyede bir veri al
    // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
    return () => clearInterval(intervalId);
  }, []);
  const handleClickToast = (position, status, message) => {
    toast({
      description: message,
      status: status,
      duration:status == 'error' ? 9000: 3000,
      position: position,
      isClosable: true,
    });
  };
  // var trips = tripData.map(o => o.Trips);
  // var totalDistance = 0;
  // var totalTime = 0;
  // var totalPrice = 0;
  // trips.forEach(item => {
  //   totalDistance += item[0].RentedTripDistance;
  //   totalPrice += item[0].RentedTripFare + item[0].RentedTripExtras;
  //   totalTime += item[0].EndOfRentedTrip - item[0].StartOfRentedTrip;
  // });
  const setDeviceConnectionRequest = async () => {
    setButtonLoading(true);
    setConnectDevice(false)
    var result = await calibrationService.GetCalibrationEcho(deviceID);
    if (result.IsSuccess) {
      if (result.Data.IsEchoReceived) {
        setConnectDevice(true)
        handleClickToast("top-right", "success", t(result.Data.Result))
      }
      else {

        handleClickToast("top-right", "error", `${t(result.Data.Result)} ${result.Data.LastConnectionTime != null ? t('lastconnection') + ': ' + moment(result.Data.LastConnectionTime).format('DD.MM.YYYY HH:mm') :""} `)
      }
    }
    else {
      handleClickToast("top-right", "error", t(result.Code))
    }
    setButtonLoading(false);
  };
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "60px" }}>
      <Flex flexWrap={'wrap'} columnGap={'1rem'} rowGap={'1rem'} w={'100%'} pos={'relative'}>
        <Card w={{base:'100%',md:'calc(60% - 0.5rem)'}}>
          <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'}>
            <Text fontSize="sm" m={'0'} ><Icon as={MdOutlineInfo} /> {t('calibrationpageinfo')}</Text>
          </Flex>
          <Flex mt={'10px'} alignItems={'center'} flexWrap={'wrap'} columnGap={'1rem'} rowGap={'1rem'}>
            <FormControl   width={{ base: "100%", md: "calc(50% - 0.5rem)" }}>
              <FormLabel
                display='flex'
                ms='4px'
                
                fontSize='sm'
                fontWeight='500'
                mb='8px'>{t('enterdeviceno')}
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
              
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder={t('deviceno')}
                mb='24px'
                fontWeight='500'
                size='lg'
                value={deviceID}
                onChange={(e) => setDeviceId(e.target.value)}
              />
            </FormControl>
            <Button   width={{ base: "100%", md: "calc(50% - 0.5rem)" }} onClick={() => setDeviceConnectionRequest()} colorScheme="teal" size="lg" isLoading={buttonLoading}>{t('connectdevice')}</Button>
          </Flex>
          {connectDevice && <Card border={'1px solid rgba(59,130,246,.8)'} boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'} backdropFilter={'blur(5px)'}>
            <Flex flexWrap={'wrap'} columnGap={'1rem'} rowGap={'1rem'} mt={'1rem'}>
              <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'} w={'100%'}>
                <Text fontSize="lg" m={'0'} >Kalibrasyon Parametreleri</Text>
              </Flex>
              <FormControl w={'calc(50% - 0.5rem)'}>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  mb='8px'>{t('plate')}
                </FormLabel>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder={t('plate')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'

                />
              </FormControl>
              <FormControl w={'calc(50% - 0.5rem)'}>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  mb='8px'>{t('dateofregistration')}
                </FormLabel>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='datetime-local'
                  value={clock}
                  placeholder={t('deviceno')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  readOnly={true}
                />
              </FormControl>
              <FormControl w={'calc(50% - 0.5rem)'}>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  mb='8px'>{t('kconstant')}
                </FormLabel>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='number'

                  placeholder={t('kconstant')}
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  max={9999}
                />
              </FormControl>
              <Flex w={'100%'} columnGap={'1rem'}>
                <Button colorScheme="facebook"><Icon as={MdSettingsRemote} />Ayarla</Button>
                <Button colorScheme="facebook">Oku</Button>
              </Flex>
            </Flex>
          </Card>}
        </Card>
        <Card position={{base:'relative',md:'absolute'}} h='35rem' w={{base:'100%',md:'calc(40% - 0.5rem)'}} right='0'>
        <Flex justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid lightgray'} pb={'4px'}>
            <Text as={'b'} fontSize="lg" m={'0'} >{t('lastoperations')}</Text>
          </Flex>
          <Grid mt={'10px'} overflowY={'auto'} h={'30rem'}>
           <LastOperationItem type='calibration' /> 
          </Grid>

        </Card>
      </Flex>
    </Box>

  );
}