// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "../components/Information";
import { useTranslation } from 'react-i18next';
// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const userData = JSON.parse(localStorage.getItem('UserData'));
  const { t, i18n } = useTranslation();
  return (
    <Card mb={{ base: "0px", "2xl": "0px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='xl'
        mt='10px'
        mb='4px'>
        {t('accountsettings')}
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        {t('generalsettinginfo')}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap='20px'>

        <Information
          boxShadow={cardShadow}
          title={t('username')}
          value={userData.UserName}

        />
        <Information
          boxShadow={cardShadow}
          title={t('email')}
          value={userData.Email}

        />
        <Information
          boxShadow={cardShadow}
          title={t('phone')}
          value={userData.Phone}

        />
        <Information
          boxShadow={cardShadow}
          title={t('password')}
          value='********'
          action={t('changepassword')}
          type='password'
        />
      </SimpleGrid>
    </Card>
  );
}
