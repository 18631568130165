// chakra imports
import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade, MdWarningAmber } from "react-icons/md";
import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    Heading,
    Divider,
    Button,
    Spinner
} from "@chakra-ui/react";
import moment from 'moment';
import 'moment/locale/tr';
import { GiCarKey } from "react-icons/gi";
import { useTranslation } from 'react-i18next';
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdOutlineClose,
    MdReplay,
    MdFlag,
    MdOutlineLocationOn,
    MdPlayCircleOutline
} from "react-icons/md";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
function GetAddressData(lat, lon) {
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Adres bilgisini almak için uygun olan property'yi seçin

            const address = data.error ? '--------------------' : data.display_name;
            console.log(address)
            return address;
        })
        .catch(error => {
            console.error('There was a problem with the reverse geocoding operation:', error);
            return '-----';
            throw error;
        });


}
function sayiToPara(sayi) {
    // Para birimi formatı için Türk Lirası (TL) sembolü ekleyelim
    const tlSymbol = '₺ ';

    // Sayıyı para birimine dönüştür
    const para = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(sayi);

    // Para birim sembolü ile birlikte döndürelim
    return para.replace('₺', tlSymbol);
}
export default function ItemContent({ sidebarOpen, selectedCar, onClose }) {
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const textColor = useColorModeValue("navy.700", "white");
    const [selectedCarInfo, setSelectedCar] = useState(null);
    const [instantAddress, setInstantAddress] = useState(null);
    const [ignitionInfo, setIgnitionInfo] = useState(null);
    const { t, i18n } = useTranslation();
    const fetchDataAndSetState = async () => {
        var result = await taxiDeviceService.GetCityDeviceDailyInfo(selectedCar);
        var ignitionResult = await taxiDeviceService.GetTaximeterTestInfo(selectedCar);
        var adress = await GetAddressData(result.LastLocation.Latitude, result.LastLocation.Longitude);
        setInstantAddress(adress);
        setSelectedCar(result);
        setIgnitionInfo(ignitionResult);
    }
    useEffect(() => {
        if (sidebarOpen && selectedCar != null) {
            fetchDataAndSetState(); // API'den cihaz konumlarını al
            // API'den cihaz konumlarını belirli aralıklarla al
            const intervalId = setInterval(() => {
                fetchDataAndSetState(); // API'den cihaz konumlarını al
            }, 5000); // Örneğin, her 10 saniyede bir veri al
            // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
            return () => clearInterval(intervalId);
        }

    }, [sidebarOpen]);
    const handleFormValues = () => {
        setInstantAddress(null);
        setSelectedCar(null);
        setIgnitionInfo(null);
        onClose(false);
    };
    return (
        <div className={`sidebar ${sidebarOpen ? 'open' : ''}`} >
            {/* Sidebar içeriği buraya gelecek */}
            {selectedCarInfo != null ?
                <>


                    <Flex m={'10px 0'} justifyContent={'space-between'} alignItems={'center'} w={'100%'} backgroundColor={'rgba(0, 128, 128, 0.8);'} borderRadius={'0.8rem'} padding={'5px 15px'}>
                        <Button w={'30px'} h={'30px'} className="close-button" onClick={handleFormValues}><Icon as={MdOutlineClose} /> </Button>
                        <Text color={'white'} fontSize={'md'} m={'0'} >
                            {selectedCarInfo.Plate}          </Text>
                        <Text color={'white'} fontSize={'md'} m={'0'} >
                            {selectedCarInfo.Status != null ? t(selectedCarInfo.Status.Status)  : t('nodataforstatus')}
                        </Text>
                    </Flex>

                    {selectedCarInfo.DailyFare != null ? <Flex backgroundColor={'rgba(0, 128, 128, 0.8);'} borderRadius={'0.8rem'} padding={'10px 15px'} w={'100%'} justifyContent={'space-between'}>
                        <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'} >
                            <Text fontSize={'sm'} margin={'0'}>{t('taxidailyearnings')}</Text>
                            <Text fontSize={{base: 'lg', md:'2xl'}} fontWeight={'600'} margin={'0'}>
                                {sayiToPara((selectedCarInfo.DailyFare / 1000).toFixed(2))}

                            </Text>

                        </Flex>

                    </Flex> : ""}

                    <Flex backgroundColor={'rgba(0, 128, 128, 0.8);'} borderRadius={'0.8rem'} padding={'10px 15px'} w={'100%'} justifyContent={'space-between'}>
                        <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'} >

                            {ignitionInfo != null ? <Flex w={'100%'} alignItems={'center'}>
                                <Flex borderRadius={'50%'} bg={'white'} w={5} h={5} justifyContent={'center'} alignItems={'center'}>
                                    <Flex borderRadius={'50%'} w={3} h={3} bg={ignitionInfo.CurrentIgnition ? 'forestgreen' : 'crimson'} />
                                </Flex>
                                <Text fontSize={'md'} fontWeight={'600'} mx={'10px'}>{ignitionInfo.CurrentIgnition ? 'Kontak Açık' : 'Kontak Kapalı'}</Text></Flex>
                                : <Text fontSize={'sm'} margin={'0'}>{t('noignitiondata')}</Text>}


                            <Text fontSize={'sm'} margin={'0'}>{t('instantspeed')}</Text>
                            <Text fontSize={{base: '5xl', md:'7xl'}} fontWeight={'600'} margin={'0'}>
                                {Math.round(selectedCarInfo.LastLocation.Speed > 1 ? selectedCarInfo.LastLocation.Speed : 0)}
                                <Text as={'span'} fontWeight={'500'} fontSize={'md'} margin={'0'}> Km/h</Text>
                            </Text>

                        </Flex>
                        <Flex flexDirection={'column'} w={{base: '50%', md:'40%'}}>
                            <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'}>
                                <Text margin={'0'}>{t('totalkm')} ({t('daily')})</Text>
                                <Text margin={'0'} fontSize={'md'} fontWeight={'600'}>
                                    {selectedCarInfo.DailyKm.toFixed(2)} Km
                                </Text>
                            </Flex>
                            <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'}>
                                <Text margin={'0'}>{t('rentedkm')} ({t('daily')})</Text>
                                <Text margin={'0'} fontSize={'md'} fontWeight={'600'}>
                                    {selectedCarInfo.DailyRentedKm.toFixed(2)} Km
                                </Text>
                            </Flex>
                            <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'}>
                                <Text margin={'0'}>{t('freekm')} ({t('daily')})</Text>
                                <Text margin={'0'} fontSize={'md'} fontWeight={'600'}>
                                    {(selectedCarInfo.DailyKm - selectedCarInfo.DailyRentedKm).toFixed(2)} Km
                                </Text>
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex backgroundColor={'rgba(0, 128, 128, 0.8);'} borderRadius={'0.8rem'} padding={'10px 15px'} w={'100%'} justifyContent={'space-between'}>
                        <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'} >

                            <Text fontSize={{base: 'sm', md:'md'}} fontWeight={'500'} margin={'0'}>
                                <Icon as={MdOutlineLocationOn} />
                                {instantAddress != null ? instantAddress : t('loading')}

                            </Text>

                        </Flex>

                    </Flex>
                    <Flex backgroundColor={'rgba(0, 128, 128, 0.8);'} borderRadius={'0.8rem'} padding={'10px 15px'} w={'100%'} justifyContent={'space-between'}>
                        <Flex flexDirection={'column'} color={'white'} textAlign={'left'} justifyContent={'space-between'} >

                            <Text fontSize={'xs'} fontWeight={'500'} margin={'0'}>

                                <b>{t('laslocationtime')}:</b> {moment(selectedCarInfo.LastLocation.LastReceivedTime).format('DD.MM.YYYY HH:mm')}
                                <br></br>
                                <b>{t('laststatustime')}:</b> {moment(selectedCarInfo.Status.Time).format('DD.MM.YYYY HH:mm')}
                                <br></br>
                                <b>Versiyon:</b> {selectedCarInfo.Version}
                            </Text>

                        </Flex>

                    </Flex>
                </>
                : <Flex justifyContent={'center'} alignItems={'center'} w={'100%'} h={'100%'}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>}

        </div>
    );
}
