import React from 'react';
import {
    MdBarChart,
    MdPerson,
    MdHome,
    MdLock,
    MdOutlineShoppingCart,
    MdOutlineShareLocation,
    MdOutlineLocalTaxi,
    MdOutlineTaxiAlert,
    MdOutlineKey,
    MdCompassCalibration,
    MdReceipt,
    MdMoreHoriz,
    MdApps
} from "react-icons/md";
import { HomeIcon, TaxiIcon, LocationIcon } from "components/icons/iconsUI";
import routes from 'routes.js';
import { SidebarResponsive } from 'components/mainsidebar/Sidebar';
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
    Box,
    SimpleGrid,
    Heading,
    Divider,
    Button,
    Tooltip,
    Input,
    useColorModeValue,
    Image,
    useDisclosure,
    Icon, Flex, Text,
    Spinner
} from "@chakra-ui/react";
const TabBar = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    let menuColor = useColorModeValue("black", "white");
    let activeIcon = useColorModeValue("brand.500", "white");
    let location = useLocation();
    const { t, i18n } = useTranslation();
    const userData = JSON.parse(localStorage.getItem('UserData'));
    var routeLayout = '';
    routes.map((route, index) => {
        if (((userData != null && userData.Role == route.Role) || route.Role == "All") && (userData.Permissions.includes(route.Permission) || route.Permission == "All")) {
            routeLayout = route.layout;
            return;
        }
    });
    const activeRoute = (routeName) => {
        return location.pathname.includes(routeName);
    };
    if (!isMobile) {
        return null;
    }



    return (
        <Flex
            pos={'fixed'}
            bg={'white'}
            bottom={'0'}
            height={'4rem'}
            w={'100%'}
            boxShadow={'0 4px 15px rgba(0, 0, 0, 0.3)'}
            justifyContent={'center'}
            alignItems={'center'}
            flexWrap={'wrap'}
            borderRadius={'0.8rem'}
        >
            <NavLink key={'1'} to={routeLayout + '/home'} className='tabBar' >
            <Flex w={'100%'} h={'6px'} bg={activeRoute('/home') ? activeIcon : 'transparent'} marginBottom={'6px'} ></Flex>
                <Image src={HomeIcon} width='30px' height='30px' />
                <Text color={activeRoute('/home') ? activeIcon : menuColor} fontSize={'10px'}>{t('homepage')}</Text>
            </NavLink>
            <NavLink key={'2'} to={routeLayout + '/taxi_reports'} className='tabBar'>
            <Flex w={'100%'} h={'6px'} bg={activeRoute('/taxi_reports') ? activeIcon : 'transparent'} marginBottom={'6px'} ></Flex>
                <Image src={TaxiIcon} width='30px' height='30px' />
                <Text color={activeRoute('/taxi_reports') ? activeIcon : menuColor} fontSize={'10px'}>{t('taxireports')}</Text>
            </NavLink>
            <NavLink key={'3'} to={routeLayout + '/location_reports'} className='tabBar'>
            <Flex w={'100%'} h={'6px'} bg={activeRoute('/location_reports') ? activeIcon :'transparent'} marginBottom={'6px'} ></Flex>
            <Image src={LocationIcon} width='30px' height='30px' />
                <Text color={activeRoute('/location_reports') ? activeIcon : menuColor} fontSize={'10px'}>{t('locationreports')}</Text>
            </NavLink>
            <Flex w={'25%'} color={menuColor} alignItems={'center'} flexDirection={'column'}>
                <SidebarResponsive routes={routes} />
                <Text fontSize={'10px'}>{t('more')}</Text>
            </Flex>
        </Flex>

    )



};

export default TabBar;
