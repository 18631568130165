// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Badge,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
// Assets
function secondsToDHMS(seconds) {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
{
      days:days,
      hours: hours,
      minutes :minutes,
      seconds: remainingSeconds}
  );
}

export default function Banner(props) {
  const { used, total, description, packages, title,icon, types } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const badgeBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  var usedTime = secondsToDHMS(used);
  var totalTime = secondsToDHMS(total);
  if(types == "Camera"){
      return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>

      <IconBox
        mx='auto'
        h='100px'
        w='100px'
        icon={
          <Icon as={icon} color={brandColor} h='46px' w='46px' />
        }
        bg={box}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
  {title}
      </Text>

           <Badge  bg={badgeBg} margin={'4px 10% '}  >  {packages}</Badge>
   
 
   
      <Text
        color={textColorSecondary}
        fontSize='sm'
        maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
        mx='auto'>
    {description}
      </Text>
      <Box w='100%' mt='auto'>
        <Flex w='100%' textAlign={'left'} mb='10px'>
          <Text color={textColorSecondary} fontSize='md' maxW='80%'>
         Toplam : {total} Saat<br/> Kullanılan : {used >= 60 ? used / 60 + 'Saat' : used + 'Dakika'}<br/> Kalan : {(total-used) / 60} Saat
          </Text>
          <Text color={textColorSecondary} fontSize='sm' maxW='40%'position={'absolute'} bottom={'34px'} right={'10px'}>
          %{Math.round((used / total) * 100)} Kullanıldı.
          </Text>
        </Flex>
        <Progress
          align='start'
          colorScheme='brandScheme'
          value={(used / total) * 100}
          w='100%'
        />
      </Box>
    </Card>
  );
  }
  else if(types == "SIM"){
    return (
      <Card mb={{ base: "0px", lg: "20px" }} align='center'>
  
        <IconBox
          mx='auto'
          h='100px'
          w='100px'
          icon={
            <Icon as={icon} color={brandColor} h='46px' w='46px' />
          }
          bg={box}
        />
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px'>
          {title}
        </Text>
  
        <Badge bg={badgeBg} margin={'4px 10% '}  >  {packages}</Badge>
  
  
  
        <Text
          color={textColorSecondary}
          fontSize='sm'
          maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
          mx='auto'>
          {description}
        </Text>
        <Box w='100%' mt='auto'>
          <Flex w='100%' textAlign={'left'} mb='10px'>
            <Text color={textColorSecondary} fontSize='md' maxW='80%'>
              Toplam : {totalTime.days > 0 ? totalTime.days + " Gün" : ""}<br />
              Kullanılan : {usedTime.days + " Gün" }<br />
              Kalan : {(totalTime.days - usedTime.days)} Gün
            </Text>
            <Text color={textColorSecondary} fontSize='sm' maxW='40%' position={'absolute'} bottom={'34px'} right={'10px'}>
              %{Math.round((usedTime.days / totalTime.days) * 100)} Kullanıldı.
            </Text>
          </Flex>
          <Progress
            align='start'
            colorScheme='brandScheme'
            value={(usedTime.days / totalTime.days) * 100}
            w='100%'
          />
        </Box>
      </Card>
    );
  }

}
