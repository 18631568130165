// chakra imports
import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade, MdWarningAmber } from "react-icons/md";
import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Divider,
  Button,
  Tooltip,
  Input,
  Spinner
} from "@chakra-ui/react";
import moment from 'moment';
import { format } from 'date-fns';
import Calendar from "react-calendar/dist/umd/Calendar";
import { LuClock12, LuClock3 } from "react-icons/lu";
import 'moment/locale/tr';
import { LuPackageSearch } from "react-icons/lu";
import { useTranslation } from 'react-i18next';
import {
  MdOutlineLocationOn,
  MdLocationPin,
  MdOutlineAttachMoney,
  MdOutlineFullscreen
} from "react-icons/md";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
const taxiDeviceService = new TaxiDeviceService();
function GetAddressData(lat, lon) {
  return fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Adres bilgisini almak için uygun olan property'yi seçin

      const address = data.error ? '--------------------' : data.display_name;
      console.log(address)
      return address;
    })
    .catch(error => {
      console.error('There was a problem with the reverse geocoding operation:', error);
      return '-----';
      throw error;
    });


}
export default function ItemContent({ onclickStartButton, onclickEndButton, startStatus, endStatus, startValue, endValue, startDate, endDate, minPrice, maxPrice, btnSearch, searching }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [StartTimeOpen, setStartTimeOpen] = useState(false);
  const [EndTimeOpen, setEndTimeOpen] = useState(false);
  const [PriceRange, setPriceRangeOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const handleCloseMini = (btnName) => {
    switch (btnName) {
      case 'startTime':
        setStartTimeOpen(false)
        break;
      case 'endTime':
        setEndTimeOpen(false)
        break;
      case 'priceRange':
        setPriceRangeOpen(false)
        break;
    }
  }

  const handleclick = () => {
    setStartTimeOpen(false);
    setEndTimeOpen(false);
    setPriceRangeOpen(false);
    setDataLoading(true);
    btnSearch();
  }
  const handleMiniControl = (btnName) => {
    switch (btnName) {
      case 'startTime':
        setStartTimeOpen(!StartTimeOpen)
        break;
      case 'endTime':
        setEndTimeOpen(!EndTimeOpen)
        break;
      case 'priceRange':
        setPriceRangeOpen(!PriceRange)
        break;
    }
  }

  return (
    <Flex className={`newssidebar ${sidebarOpen ? 'open' : ''}`} >
      <Tooltip label="Arama Barını Büyüt" isDisabled={sidebarOpen} placement='right-start'>
        <Flex

          columnGap={'10px'}
          alignItems={'center'}
          onClick={() => setSidebarOpen(true)} className="newsHeader">
          <Icon as={MdOutlineFullscreen} h={8} w={8} />
          <Text as={'b'} fontSize={'lg'}>Kayıp Eşya Takibi</Text>
        </Flex>
      </Tooltip>

      {/* Sidebar içeriği buraya gelecek */}
      <Tooltip label='Başlangıç Tarihi' isDisabled={sidebarOpen} placement='right-start'>
        <Flex className="newsbutton" columnGap={'10px'} alignItems={'center'}>
          <Icon onClick={() => handleMiniControl('startTime')} as={LuClock12} h={6} w={8} />
          <Flex className={`miniSelection ${StartTimeOpen ? 'open' : ''}`}>
            <Input onBlur={() => handleCloseMini('startTime')} onChange={(e) => startDate(moment(e.target.value).format("yyyy-MM-DDTHH:mm:ss.SSS"))} type="datetime-local" textAlign={'center'} border={0} />
          </Flex>

        </Flex>
      </Tooltip>
      <Tooltip label='Bitiş Tarihi' isDisabled={sidebarOpen} placement='right-start'>
        <Flex className="newsbutton" columnGap={'10px'} alignItems={'center'}>
          <Icon onClick={() => handleMiniControl('endTime')} as={LuClock3} h={6} w={8} />
          <Flex className={`miniSelection ${EndTimeOpen ? 'open' : ''}`}>
            <Input onBlur={() => handleCloseMini('endTime')} onChange={(e) => endDate(moment(e.target.value).format("yyyy-MM-DDTHH:mm:ss.SSS"))} type="datetime-local" textAlign={'center'} border={0} />
          </Flex>
        </Flex>
      </Tooltip>
      <Tooltip label={startStatus ? 'Başlangıç Çizim Kapat' : 'Başlangıç Noktası'} isDisabled={sidebarOpen} placement='right-start'>
        <Flex onClick={onclickStartButton} className="newsbutton" columnGap={'10px'} color={startValue != null ? 'teal' : ''} alignItems={'center'} >
          <Icon as={MdOutlineLocationOn} h={8} w={8} />
          <Text fontSize={'md'} >  {startStatus ? 'Başlangıç Çizim Kapat' : 'Başlangıç Noktası'}</Text>
        </Flex>
      </Tooltip>
      <Tooltip label={endStatus ? 'Bitiş Çizim Kapat' : 'Bitiş Noktası'} isDisabled={sidebarOpen} placement='right-start'>
        <Flex onClick={onclickEndButton} className="newsbutton" columnGap={'10px'} color={endValue != null ? 'rgba(255, 0, 0, 0.5)' : ''} alignItems={'center'} >
          <Icon as={MdLocationPin} h={8} w={8} />
          <Text fontSize={'md'} >   {endStatus ? 'Bitiş Çizim Kapat' : 'Bitiş Noktası'}</Text>
        </Flex>
      </Tooltip>
      <Tooltip label='Tutar Aralığı' isDisabled={sidebarOpen} placement='right-start'>
        <Flex className="newsbutton" columnGap={'10px'} alignItems={'center'}>
          <Icon onClick={() => handleMiniControl('priceRange')} as={MdOutlineAttachMoney} h={8} w={8} />
          <Flex className={`miniSelection ${PriceRange ? 'open' : ''}`} alignItems={'center'}>
            <Input onChange={(e) => minPrice(e.target.value)} textAlign={'center'} border={0} placeholder="Min" type="number" />
            <Text>-</Text>
            <Input onChange={(e) => maxPrice(e.target.value)} textAlign={'center'} border={0} placeholder="Max" type="number" />
          </Flex>
        </Flex>
      </Tooltip>
      <Flex onClick={handleclick} 
      className="newsSearchbutton" 
      columnGap={'10px'} 
      alignItems={'center'} 
      bg="teal" 
      color={'white'} 
      p={'10px'}> {searching? <> <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='teal.200'
        color='white'
        size='lg'
    /> <Text> Aranıyor</Text></>: <><Icon as={LuPackageSearch} h={8} w={8} /> <Text> Sorgula</Text></>}  </Flex>
      <Flex pos={'absolute'} bottom={'10px'} onClick={() => setSidebarOpen(false)} justifyContent={'end'} >
        <Text as={'b'}>Küçük Konuma Getir</Text>
      </Flex>
    </Flex>
  );
}
