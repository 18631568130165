import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import ReactDOMServer from 'react-dom/server';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import { Text, useColorModeValue, SimpleGrid, Button, Flex, Icon, Box, Spinner, transition } from "@chakra-ui/react";
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdReplay,
    MdFlag,
    MdPlayCircleOutline
} from "react-icons/md";
import moment from 'moment';
import 'moment/locale/tr';
import { FullscreenControl } from 'react-leaflet-fullscreen';
const { BaseLayer } = LayersControl;
function ChildComponent({ zoomLevel, centerCoordinates }) {
    const map = useMap(); // Access the map instance using useMap()
    console.log(zoomLevel, " ", centerCoordinates);
    // You can now use the map instance to perform operations
    // For example, to set the view to a specific location
    map.setView(centerCoordinates);

    return null;
}

function MapComponent({ zoomLevel, centerCoordinates, logoUrl, locations, timeSpan }) {
    const [transitionValue, setTransitionValue] = useState('all 1s ease;');
    const [mapZoom, setMapZoom] = useState(13); 
    const [route, setRoute] = useState([]);
    const [routes, setRoutes] = useState([]); // Rota: [[latitude, longitude, speed], ...]
    const [carPositionIndex, setCarPositionIndex] = useState(0); // Arabanın bulunduğu konumun indeksi
    const [animationEnabled, setAnimationEnabled] = useState(false); // Animasyon durumu
    const [carPosition, setCarPosition] = useState([0, 0]);
    const createTripMap = async () => {
        var locsCals = [];
        var locs = [];
        await locations.Locations.map(trip => {
            locs.push([trip.Latitude, trip.Longitude, trip.IsRented])
  
        }
        );
        for (let i = 0; i < locations.Locations.length-1; i++) {
            var prevLat =locations.Locations[i].Latitude;
            var prevLon =locations.Locations[i].Longitude;
            var nextLat =locations.Locations[i+1].Latitude;
            var nextLon =locations.Locations[i+1].Longitude;
            locsCals.push([[prevLat,prevLon],[nextLat,nextLon],locations.Locations[i].IsRented])
        }
        setRoute(locs);
        setRoutes(locsCals);
    };

    useEffect(() => {
     
        if (locations.Locations) {


        createTripMap();
           
        }
        // Array içindeki her bir öğe için reverse geocoding yap

    }, [locations]);
    var middleLoc =route.length > 0 ? route[Math.round(route.length / 2)]: centerCoordinates;
    useEffect(() => {
        let intervalId;
    
        // Animasyon başladığında interval'i oluştur
        if (animationEnabled) {
          intervalId = setInterval(() => {
            setCarPositionIndex((prevIndex) => {
              // Eğer rota sona erdiyse animasyonu durdur
              if (prevIndex === route.length - 1) {
                setAnimationEnabled(false);
            
                return prevIndex;
              }
              return prevIndex + 1;
            });
          }, 1000); // Hız bilgisine göre interval süresi
        }
    
        // Temizlik fonksiyonu: bileşen yeniden render edildiğinde interval'i temizle
        return () => clearInterval(intervalId);
      }, [animationEnabled, carPositionIndex, route]);

    const startAnimation = () => {
        setAnimationEnabled(true);
    };

    const stopAnimation = () => {
        setAnimationEnabled(false);
    };

    const resetAnimation = () => {
        setCarPositionIndex(0); // Başlangıç konumuna sıfırla
        setAnimationEnabled(false);
        setCarPosition([route[0][0], route[0][1]]);
    };
    const markerIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Box bg={'rgba(34,139,34, 0.3)'} borderRadius={'50%'} p={'10px'} color={'white'} ></Box>
        ),
        className: 'marker-icon',
    });
    const startIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdNotStarted} />
        ),
        className: 'marker-icon',
    });
    const endIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdFlag} />
        ),
        className: 'marker-icon',
    });
    const myStyle = {
        transition: 'all 1s ease'
      };
    const carIcon = L.divIcon({
        html: ReactDOMServer.renderToString(
            <Icon fontSize={'3em'} bg={'teal'} borderRadius={'50%'} p={'4px'} color={'white'} as={MdOutlineLocalTaxi} />
        ),
        className: 'marker-icon marker-transition',
     myStyle
    });
    const handleZoomChange = (e) => {
        setMapZoom(e.target._zoom); // Harita zoom seviyesini güncelle
    };
    const getColorForRoute = (route) => {
        for (let i = 0; i < route.length; i+1) {
        const thirdIndexValue = route[i][2]; // 3. index'teki değeri al
        // Renk için koşullar belirle, örneğin:
        if (thirdIndexValue) {
          return 'forestgreen';
        }  else {
          return 'orange';
        }
    }
      };
    return (
        <Box w={'100%'}>
            <Flex mb={'10px'} columnGap={'10px'}>
                <Button onClick={startAnimation} colorScheme='teal'><Icon as={MdPlayCircleOutline} /> Başlat</Button>
                <Button onClick={stopAnimation} colorScheme='yellow'><Icon as={MdOutlinePauseCircle} /> Durdur</Button>
                <Button onClick={resetAnimation} colorScheme='facebook'><Icon as={MdReplay} /> Başa Sar</Button>
            </Flex>
            <MapContainer center={centerCoordinates} zoom={mapZoom} style={{ height: '70vh', width: '100%', zIndex: '0', borderRadius: "10px" }} zoomControl={false} onZoomend={handleZoomChange}>
                <LayersControl position="topright">
                    <BaseLayer checked name="Varsayılan Harita">
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Uydu Görünümü">
                        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution={logoUrl} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar">
                        <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                    <BaseLayer name="Google Haritalar (Uydu)">
                        <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" attribution={logoUrl} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                    </BaseLayer>
                </LayersControl>
                <ChildComponent  centerCoordinates={animationEnabled ? [...route[carPositionIndex]] :middleLoc} />
                {route.length > 0 ? (<>
                    {locations.Locations.map((location, index) => (
                    <Marker key={index} position={[location.Latitude, location.Longitude]} icon={
                        index == 0 ? startIcon
                            : index == locations.Locations.length - 1 ? endIcon
                                : markerIcon
                    }>
              <Popup >
                <Flex flexDirection={'column'}>
                  <Text as={'h5'} margin={'4px 0'}><b>Cihaz Seri No: </b> {location.DeviceID}</Text>
                  <Text as={'h5'} m={'4px 0'}><b>Tarih Saat: </b> {moment(location.TimeReal).format('DD.MM.YYYY - HH:mm')}</Text>
                  <Text as={'h5'}><b>Araç Hızı: </b> {Math.round(location.Speed * 1.60934)} Km/h</Text>


                </Flex>

              </Popup>
                    </Marker>
                ))}
                {
                    
                    routes.map((item, index) => (
                        <Polyline key={index} positions={item.slice(0, 2)}  color={item[2] ? 'orange': 'forestgreen'} />
                    )
                    )
                }
           
    
                <Marker icon={carIcon} position={[...route[carPositionIndex]]}   >
         
        </Marker></>): ''}
        <FullscreenControl position="bottomleft" /> 
            </MapContainer>

        </Box>


    );

}

export default MapComponent;
