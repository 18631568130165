import {
  Flex,
  Table,
  Progress,
  Grid,
  Button,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Divider, 
  Input,
  Select,
  FormControl,
  Thead,
  Tr,
  useColorModeValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from 'moment';
import 'moment/locale/tr';
import UserService from "Services/UserService/UserService";

// Custom components
import Card from "components/card/Card";
import Menu from "./MainMenu";
import { CitiesLocations } from "variables/CityLocations";
import Roles from "variables/Roles";
// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdEdit, MdFormatListBulleted, MdOutlineInfo, MdOutlineLocalTaxi } from "react-icons/md";
function CustomButton({ type, param, adress, deviceID, tripID }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [position, setMapPosition] = useState([40.762200, 29.940733]);
  const [locations, setLocations] = useState([]);
  const [params, setParam] = useState({});
  const [addressName, setAddressName] = useState({});
  var icon;
  var colorTheme;
  var text;
  var innerModal;
  switch (type) {
    case 'detail':
      icon = MdFormatListBulleted;
      text = 'Detay';
      colorTheme = 'twitter';
      innerModal = <Flex>Detay</Flex>
      break;
    case 'edit':
      icon = MdEdit;
      text = 'Düzenle';
      colorTheme = 'teal';
      innerModal = <Flex>Düzenle</Flex>
      break;
    case 'assign':
      icon = MdOutlineLocalTaxi;
      text = 'Taksi Ekle/Çıkar';
      colorTheme = 'yellow';
      innerModal = <Flex columnGap={'20px'}>
        <Card>
          <Text as={'b'} fontSize={'md'}>Atanmamış Taksimetreler</Text>
          <Divider my={'5px'} />
        </Card>
        <Card>
          <Text as={'b'} fontSize={'md'}>Hesaba Atanmış Taksimetreler</Text>
          <Divider my={'5px'} />
        </Card>
      </Flex>
      break;
  }

  const handleClick = async () => {
    onOpen();
  };
  return (

    <>
      { }


      <Button

        colorScheme={colorTheme}

        onClick={handleClick}
      >
        <Icon marginRight={'2px'} as={icon} />
        <Text as={'small'}>  {text}</Text>

      </Button>
      <Modal size={'4xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg='none'
          backdropFilter='blur(10px) ' />
        <ModalContent backgroundColor={'rgba(0,0,0,0.1)'}>


          <ModalBody>

            {innerModal}

          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Kapat</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
const userService = new UserService();
export default function ColumnsTable(props) {
  const { columnsData } = props;
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState({});
  const [sizePage, setPagesizes] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchParam] = useState('');
  useEffect(() => {
    const fetchDataAndSetState = async () => {

      var result = await userService.ListAllUsers(pageNumber, searchText, sizePage, {
        "SortBy": 'Name',
        "Descending": false
      });
      setTableData(result.Data);
      setUsers(result.Data.Users);
    }
    fetchDataAndSetState();
  }, [sizePage, pageNumber, searchText]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => users, [users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // İlk sayfa yüklemesi
    },
    useSortBy,
    usePagination
  );
  const changePageSize = (value) => {
    setPageSize(value)
    setPagesizes(value)
  }
  const changePageNumber = (value) => {
    switch (value) {
      case 'next':
        setPageNumber(pageNumber + 1)
        break;
      case 'prev':
        setPageNumber(pageNumber - 1)
        break;

    }
  }
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
    >
      <Flex px='25px' justify='space-between' mb='20px' >
        <Flex align='center' columnGap={'2px'}>
          <Icon as={MdOutlineInfo} />
          <Text
            color={textColor}
            fontSize='1em'
            fontWeight='500'
            mb='0px'
            lineHeight='100%'>
            Kullanıcı ekleme/düzenleme ve detay görüntüleme işlemlerinizi buradan gerçekleştirebilirsiniz.
          </Text>
        </Flex>

        <Menu />
      </Flex>
      <Flex px='25px' justifyContent={'flex-end'}>
        <FormControl w={'20%'}>
          <Input id='first-name' placeholder='Ara' onInput={handleSearch} />
        </FormControl>
      </Flex>
      <Flex className="table-container" css={{ /* Özel scrollbar stilini uygular */
        '&::-webkit-scrollbar': {
          width: '80%',
          height: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'lightgray',
          borderRadius: '2px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'teal',
        },
      }}>

        <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px' maxH={'10vh'} overflowY={'scroll'} >
          <Thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </Flex>

                  </Th>
                ))}
              </tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}  >
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "AD") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "SOYAD") {

                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "DURUM") {
                      data = (
                        <Flex align='center'>
                          <Icon
                            w='24px'
                            h='24px'
                            me='5px'
                            color={
                              cell.value === "Active"
                                ? "green.500"
                                : cell.value === "NotActivated"
                                  ? "red.500"
                                  : cell.value === "Error"
                                    ? "orange.500"
                                    : null
                            }
                            as={
                              cell.value === "Active"
                                ? MdCheckCircle
                                : cell.value === "NotActivated"
                                  ? MdCancel
                                  : cell.value === "Error"
                                    ? MdOutlineError
                                    : null
                            }
                          />
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value === "Active"
                              ? 'Onaylandı'
                              : cell.value === "NotActivated"
                                ? 'Onaylanmadı'
                                : cell.value === "Error"
                                  ? MdOutlineError
                                  : null}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "TELEFON") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{cell.value}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "EPOSTA") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{cell.value}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "ROLÜ") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>{Roles[cell.value]}</Text>
                        </Flex>
                      );
                    }
                    else if (cell.column.Header === "YÖNET") {
                      data = (
                        <Flex columnGap={'4px'}>
                          <CustomButton type={'assign'} />
                          <CustomButton type={'edit'} />
                          <CustomButton type={'detail'} />
                        </Flex  >
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>


      </Flex>
      <Flex
        m={'20px 10px 0'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Flex alignItems={'center'} columnGap={'20px'}>
          <Text>Toplam Kayıt: {(tableData != null ? tableData.TotalDocumentCount : 0)}</Text>
          <div>
            <Select
              size="sm"
              value={pageSize}
              onChange={e => {
                changePageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Satır
                </option>
              ))}
            </Select>
          </div>
        </Flex>
        <Flex alignItems={'center'} >
          <Button colorScheme="facebook" size="sm" onClick={() => changePageNumber('prev')} disabled={pageNumber == 1}>
            Önceki
          </Button>
          <Text as={'span'} style={{ margin: '0 10px' }}>
            {pageNumber} / {Math.ceil((tableData != null ? tableData.TotalDocumentCount : 0) / pageSize)}
          </Text>
          <Button colorScheme="facebook" size="sm" onClick={() => changePageNumber('next')} disabled={pageNumber == Math.ceil((tableData != null ? tableData.TotalDocumentCount : 0) / pageSize)}>
            Sonraki
          </Button>
        </Flex>
      </Flex>

    </Card>
  );
}
