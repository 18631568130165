import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMap, Polyline, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'assets/css/Map.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import logoWhite from "assets/img/layout/parstaksilogo.png";
import TaxiStats from "views/admin/adminHome/components/TaxiStatusPanel";
import TaxiDeviceService from "Services/TaxiDeviceService/TaxiDeviceService";
import ReactDOMServer from 'react-dom/server';
import L, { MarkerCluster } from 'leaflet';
import moment from 'moment';
import 'moment/locale/tr';
import 'assets/css/MarkerCluster.css';
import { FullscreenControl } from 'react-leaflet-fullscreen';

import {
    Box,
    Flex,
    SimpleGrid,
    Heading,
    Text,
    Icon,
    Spinner
} from "@chakra-ui/react";
import {
    MdOutlinePauseCircle,
    MdNotStarted,
    MdOutlineLocalTaxi,
    MdReplay,
    MdOutlineZoomOutMap,
    MdFlag,
    MdPlayCircleOutline
} from "react-icons/md";
import React, { useState, useEffect, useRef } from 'react';
import { CitiesLocations } from "variables/CityLocations";
const { BaseLayer } = LayersControl;
const taxiDeviceService = new TaxiDeviceService();
function ChildComponent({ zoomLevel, centerCoordinates }) {
    const map = useMap(); // Access the map instance using useMap()
    console.log(zoomLevel, " ", centerCoordinates);
    // You can now use the map instance to perform operations
    // For example, to set the view to a specific location
    map.setView(centerCoordinates, zoomLevel);

    return null;
}

export default function AdminHomepage() {
    const userData = JSON.parse(localStorage.getItem('UserData'));

    var cityIndex = parseInt((userData.CityCode != null ? userData.CityCode : 41) - 1)
    var logoUrl = `<img src="${logoWhite}"/>`
    const city = CitiesLocations[cityIndex];
    const [centerPoint, setCenterPoint] = useState([city.lat, city.lon]);
    const FollowMouse = () => {
        const [position, setPosition] = useState([51.505, -0.09]);

        useMapEvent('mousemove', (e) => {
            setCenterPoint([e.latlng.lat, e.latlng.lng]);
        })
        return '';
    };
    const izmirBounds = L.latLngBounds(
        L.latLng(38.2796, 26.2638), // Southwest corner
        L.latLng(38.6191, 27.3993)  // Northeast corner
    );
    return (
        <Box zIndex={0} overflow={'hidden'}>
            <Flex
                pos={'absolute'}
                w={'50vw'}
                h={'50vh'}
                zIndex={1}
                top={'calc(50% - 25vh)'}
                left={'calc(50% - 25vw)'}
                backdropFilter={'blur(10px)'}
                bg={'rgba(255,255,255,0.4)'}
                pt={'4vh'}

                borderRadius={'0.825rem'}
                boxShadow={'0 4px 5px rgba(0, 0, 0, 0.1)'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <Text w={'100%'} textAlign={'center'} fontWeight={'600'} fontSize={'2xl'}>{city.CityName} Haber Merkezine Hoşgeldiniz</Text>
                <Flex  p={'2rem'} justifyContent={'space-between'} flexWrap={'wrap'} w={'100%'}>
      
                    <Flex w={'calc(30%)'} color={'white'}justifyContent={'space-between'} alignItems={'center'} bg={'teal'} flexDirection={'column'} p={'2rem'} borderRadius={'0.825rem'}>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'lg'}>Kayıp Eşya Bildirimleri</Text>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'7xl'}>0</Text>
                    </Flex>
                    <Flex w={'calc(30% )'} color={'white'} justifyContent={'space-between'} alignItems={'center'} bg={'crimson'} flexDirection={'column'} p={'2rem'} borderRadius={'0.825rem'}>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'lg'}>Acil Butonu Bildirimleri</Text>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'7xl'}>0</Text>
                    </Flex>
                    <Flex w={'calc(30% )'} color={'white'} justifyContent={'space-between'} alignItems={'center'} bg={'facebook.500'} flexDirection={'column'} p={'2rem'} borderRadius={'0.825rem'}>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'lg'}>Diğer Bildirimler</Text>
                        <Text textAlign={'center'} fontWeight={'600'} fontSize={'7xl'}>0</Text>
                    </Flex>
                </Flex>


            </Flex>
            <MapContainer
                center={centerPoint}
                zoom={12}
                style={{ height: '100vh', width: '100%', zIndex: 0, opacity: '0.2' }}
                maxBounds={izmirBounds}
                maxBoundsViscosity={1.0}
                scrollWheelZoom={false}

            >


                <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />



                <ChildComponent centerCoordinates={centerPoint} />
                <FollowMouse />
            </MapContainer>

        </Box>

    );
}
