export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "AD",
    accessor: "Name",
  },
  {
    Header: "SOYAD",
    accessor: "Surname",
  },
  {
    Header: "DURUM",
    accessor: "UserStatus",
  },
  {
    Header: "TELEFON",
    accessor: "Phone",
  },
  {
    Header: "EPOSTA",
    accessor: "Email",
  },
  {
    Header: "ROLÜ",
    accessor: "Role",
  },
  {
    Header: "YÖNET",
    accessor: "Country",
  },
];
