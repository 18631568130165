/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Grid, Spinner } from "@chakra-ui/react";

// Custom components
import Banner from "./components/Banner";
import General from "./components/General";
import Notifications from "./components/Notifications";
import Projects from "./components/Projects";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import Dropzone from "./components/Dropzone";
import {
  MdSimCard,
  MdOutlineCameraAlt,
  MdStore
} from "react-icons/md";
// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect, useState } from "react";

export default function Overview() {
  const userData = JSON.parse(localStorage.getItem('UserData'));
  var descript = userData.CityCode == '35' ? 'Taksimetre için hat aboneliğinizin detaylarını buradan görüntüleyebilirsiniz.' : 'Kamera ve taksimetre için hat aboneliğinizin detaylarını buradan görüntüleyebilirsiniz.';
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.40fr 2fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          banner={banner}
        />

        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={604800}
          total={31556926}
          icon={MdStore}
          title='Yıllık Hat Aboneliği'
          packages='STANDART PAKET'
          types = 'SIM'
          description={descript}
        />
        {/* <Upload
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "1 / 3 / 2 / 4",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        /> */}
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "1fr"
        }}
        templateRows={{
          base: "1fr"
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <General
          pe='20px'
        />
      </Grid>
    </Box>
  );
}
